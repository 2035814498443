import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './UserSettingsMenu.module.css';
import { LiaProductHunt } from "react-icons/lia";
import {PiBellRinging, PiCompassBold} from "react-icons/pi";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi2";
import { PiStarLight } from "react-icons/pi";
import { PiBugBeetleLight } from "react-icons/pi";
import { RxExclamationTriangle } from "react-icons/rx";
import { CiLogout } from "react-icons/ci";
import { HiChevronRight } from "react-icons/hi2";
import { useTheme } from "../ThemeContext/ThemeContext";
import ThemeToggle from "../ThemeToggle/ThemeToggle";
import {SiHomeassistantcommunitystore} from "react-icons/si";
import { RiShoppingCartLine } from "react-icons/ri";
import {useNavVisibility} from "../NavVisibilityContext/NavVisibilityContext";
import {GiOpenBook} from "react-icons/gi";


const UserSettingsMenu = ({ user, includeHello, includeLogout, handleShowHeader, handleShowSettingsHeader, handleLogout, setShowTour }) => {
    const { isDarkMode, toggleTheme } = useTheme();
    const { setIsNavVisible } = useNavVisibility();

    const navigate = useNavigate();

    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(true);
        setIsNavVisible(false);
    }, [handleShowHeader, handleShowSettingsHeader]);

    const handleTutorialClick = (e) => {
        e.preventDefault();
        setShowTour(true);
        navigate('/');
    };

    const menuItems = {
        mainSection: [
            { link: "/product-preferences", icon: LiaProductHunt, text: "Product Preferences", includeArrow: true },
            { link: "/dispensary-preferences", icon: SiHomeassistantcommunitystore, text: "Your Dispensaries", includeArrow: true },
            { link: "/brand-preferences", icon: RiShoppingCartLine, text: "Brand Preferences", includeArrow: true },
            { link: "/notifications", icon: PiBellRinging, text: "Notifications", includeArrow: true },

            { link: "/get-help", icon: HiOutlineQuestionMarkCircle, text: "Get Help", includeArrow: true },
            /*{ link: "/review", icon: PiStarLight, text: "Review On App Store & Google Play", includeArrow: true },*/
            { link: "/report-bug", icon: PiBugBeetleLight, text: "Report App Bug", includeArrow: true },
            { link: "/report-deal-issue", icon: RxExclamationTriangle, text: "Report Deal Issue", includeArrow: true },
            {
                link: "#",
                icon: GiOpenBook,
                text: "Tutorial",
                includeArrow: true,
                onClick: handleTutorialClick
            },
        ],
        bottomLinks: [
            { link: "/terms", text: "Terms Of Use", includeArrow: false },
            { link: "/privacy", text: "Privacy Policy", includeArrow: false },
            { link: "/delete-account", text: "Delete Account", includeArrow: false, customClass: 'deleteAccount' },
        ],
    };

    const renderMenuItem = ({ link, icon: Icon, text, includeArrow, customClass, onClick }) => (
        <li key={text} className={`${styles.menuItem} ${customClass ? styles[customClass]:''}`}>
            <Link
                to={link}
                className={styles.menuLink}
                onClick={onClick}
            >
                <div className={styles.iconAndName}>
                    {Icon && <Icon className={styles.menuIcon} />}
                    {text}
                </div>
                {includeArrow && <span className={styles.arrow}><HiChevronRight /></span>}
            </Link>
        </li>
    );

    return (
        <nav className={styles.userSettingsMenu}>
            <ul className={styles.menuList}>

                    <div className={styles.userSectionHelloWrap}>
                        <div className={styles.userSectionHello}>
                            <h2 className={styles.sectionHeader}>Hello, {user.name}</h2>
                        </div>
                    </div>



                <div className={styles.section}>
                    {menuItems.mainSection.map(renderMenuItem)}
                </div>

                <div className={styles.bottomLinks}>
                    {menuItems.bottomLinks.map(renderMenuItem)}
                </div>

                <div className={styles.toggleContainer}>
                    <ThemeToggle isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
                </div>

                {includeLogout && (
                    <div className={styles.section}>
                        <div className={styles.logoutWrap}>
                            <Link className={`${styles.menuLink} ${styles.logout}`}>
                                <div className={`${styles.iconAndName} ${styles.logoutText}`} onClick={handleLogout}>
                                    <CiLogout className={`${styles.menuIcon} ${styles.logoutIcon}`}/>
                                    Logout
                                </div>
                            </Link>
                        </div>
                    </div>
                )}

            </ul>
        </nav>
    );
};

export default UserSettingsMenu;
