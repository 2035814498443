export function base64ToBuffer(base64url) {
    // Convert base64url to base64
    const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');
    // Pad with '=' if needed
    const padded = base64.padEnd(base64.length + (4 - (base64.length % 4)) % 4, '=');
    // Convert to binary string
    const binary = atob(padded);
    // Convert to ArrayBuffer
    const buffer = new ArrayBuffer(binary.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < binary.length; i++) {
        view[i] = binary.charCodeAt(i);
    }
    return buffer;
}

// Convert an ArrayBuffer to a base64url string
export function bufferToBase64(buffer) {
    // Convert ArrayBuffer to binary string
    const binary = String.fromCharCode(...new Uint8Array(buffer));
    // Convert to base64
    const base64 = btoa(binary);
    // Convert to base64url
    return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}
