import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import useApi from '../../utils/useApi';
import styles from './UserSelectFavoriteBrands.module.css';
import logo from '../../assets/logo2.svg';
import {useTheme} from "../ThemeContext/ThemeContext";
import {useNavVisibility} from "../NavVisibilityContext/NavVisibilityContext";
import {ArrowLeft} from "lucide-react";
import {useBrands} from '../BrandContext/BrandContext';

const UserSelectFavoriteBrands = ({
                                      handleShowSettingsHeader,
                                      handleShowHeader,
                                      geoposition,
                                      onComplete,
                                      user
                                  }) => {
    const {
        brands,
        isLoading,
        error,
        loadBrands,
        updateBrandSelection,
        getSelectedBrandIds
    } = useBrands();
    const { isDarkMode } = useTheme();
    const logoSrc = isDarkMode ? logo : logo;
    const { setIsNavVisible } = useNavVisibility();
    const navigate = useNavigate();
    const api = useApi();

    useEffect(() => {
        if(user){
            handleShowHeader(false);
            handleShowSettingsHeader(true);
        } else {
            handleShowHeader(false);
            handleShowSettingsHeader(false);
        }

        setIsNavVisible(false);

        if (brands.length === 0 && !isLoading) {
            loadBrands(api, geoposition);
        }
    }, []);

    const handleBrandSelect = (brandId) => {
        const brand = brands.find(b => b.id === brandId);
        updateBrandSelection(brandId, !brand.selected);
    };

    const handleNext = async () => {
        try {
            const selectedBrandIds = getSelectedBrandIds();
            await api.saveFavoriteBrands(selectedBrandIds);
            onComplete();
            navigate('/');
        }
        catch (error) {
            console.error('Error saving selected brands:', error);
        }
    };

    const handleSkip = () => {
        onComplete();
        navigate('/');
    }

    const savePreferences = async () => {
        try {
            const selectedBrandIds = getSelectedBrandIds();
            await api.saveFavoriteBrands(selectedBrandIds);
            navigate('/user-settings');
            loadBrands(api, geoposition);
        }
        catch (error) {
            console.error('Error saving selected brands:', error);
        }
    };

    if (error) {
        return (
            <div className={styles.container}>
                <div className={styles.error}>
                    Error loading brands. Please try again later.
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            {!user && (
                <div className={styles.logoContainer}>
                    <img className={styles.logo} src={logoSrc} alt="logo"/>
                </div>
            )}
            <div className={styles.titleContainer}>
                {!user && (
                    <button
                        className={styles.backButton}
                        onClick={() => navigate('/select-products')}
                        aria-label="Go back to select product types"
                    >
                        <ArrowLeft size={24}/>
                    </button>
                )}

                <h3 className={styles.title}>
                    {isLoading ? (
                        <span>Loading brands near you...</span>
                    ) : (
                         <span>We found <span className={styles.brandColor}>{brands.length} brands</span> near you!</span>
                     )}
                </h3>
            </div>

            <p className={styles.subtitle}>Select your favorite brands to add to your Favorites.</p>

            {isLoading ? (
                <div className={styles.loading}>Loading brands...</div>
            ) : (
                 <div className={styles.dispensaryGrid}>
                     {brands.map((brand) => (
                         <button
                             key={brand.id}
                             className={`${styles.dispensaryButton} ${brand.selected ? styles.selected : ''}`}
                             onClick={() => handleBrandSelect(brand.id)}
                         >
                             <div className={styles.logoImage} style={{ backgroundImage: `url(${brand.logo})` }}>
                                 <img src={brand.logo} alt={brand.name} style={{ display: 'none' }}/>
                             </div>
                             <span>{brand.name}</span>
                         </button>
                     ))}
                 </div>
             )}

            <div className={`${styles.buttonContainer} ${styles.visible}`}>
                {!user && (
                    <>
                        <button className={styles.skipButton} onClick={handleSkip}>Skip & start deal shopping</button>
                        <button
                            className={styles.nextButton}
                            onClick={handleNext}
                            disabled={isLoading}
                        >
                            Start shopping
                        </button>
                    </>
                )}
                {user && (
                    <button
                        className={styles.nextButton}
                        onClick={savePreferences}
                        disabled={isLoading}
                    >
                        Save
                    </button>
                )}
            </div>
        </div>
    );
};

export default UserSelectFavoriteBrands;
