import React, {useState, useEffect, useCallback, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import styles from "../App/App.module.css";
import MapComponent from "../MapComponent/MapComponent";
import NavbarFilter from "../NavbarFilter/NavbarFilter";
import useApi from "../../utils/useApi";
import ProductListVirtual from "../ProductListVirtual/ProductListVirtual";

const BusinessResults = ({ businessId, handleShowHeader, handleShowSettingsHeader, changeGeoPosition, showTour, setShowTour, menuType }) => {
    const [products, setProducts] = useState([]);
    const [noDeals, setNoDeals] = useState(false);
    const [filterCounts, setFilterCounts] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [category, setCategory] = useState(2);
    const [categoryName, setCategoryName] = useState('Flower');
    const [priceFrom, setPriceFrom] = useState(null);
    const [priceTo, setPriceTo] = useState(null);
    const [weight, setWeight] = useState(null);
    const [weightUnit, setWeightUnit] = useState(null);
    const [cbdFrom, setCbdFrom] = useState(null);
    const [cbdTo, setCbdTo] = useState(null);
    const [thcFrom, setThcFrom] = useState(null);
    const [thcTo, setThcTo] = useState(null);
    const [strainType, setStrainType] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [noResults, setNoResults] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const MemoizedMapComponent = React.memo(MapComponent);
    const searchBarRef = useRef(null);
    const { getBusinessProducts, getBusinessFilterCounts } = useApi();
    const navbarFilterRef = useRef(null);
    const [isNavbarFilterPinned, setIsNavbarFilterPinned] = useState(false);
    const [navbarFilterOriginalTop, setNavbarFilterOriginalTop] = useState(0);

    useEffect(() => {
        const setInitialPosition = () => {
            if (navbarFilterRef.current) {
                const rect = navbarFilterRef.current.getBoundingClientRect();
                setNavbarFilterOriginalTop(rect.top + window.pageYOffset);
            }
        };

        setInitialPosition();
        window.addEventListener('load', setInitialPosition);
        window.addEventListener('resize', setInitialPosition);

        return () => {
            window.removeEventListener('load', setInitialPosition);
            window.removeEventListener('resize', setInitialPosition);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (navbarFilterRef.current) {
                const scrollY = window.pageYOffset;
                const unpinOffset = 50; // Adjust this value to change when unpinning occurs

                if (scrollY > navbarFilterOriginalTop) {
                    setIsNavbarFilterPinned(true);
                } else if (scrollY < navbarFilterOriginalTop - unpinOffset) {
                    setIsNavbarFilterPinned(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [navbarFilterOriginalTop]);

    const handleSearchResults = useCallback((results) => {
        setIsSearching(false);
        if (results.data.length <= 0 && !results.pagination) {
            setHasMore(false);
            return;
        }

        if (results.noResults) {
            setNoDeals(results.noResults);
        } else {
            setNoDeals(results.noResults);
        }

        if (results.pagination.current_page === 1) {
            window.scrollTo(0, 0);
        }

        setProducts(prevProducts => (page === 1 ? results.data : [...prevProducts, ...results.data]));
        setHasMore(results.pagination.current_page < results.pagination.last_page);
        setPage(prevPage => prevPage + 1);

        if (results.pagination.total === 0) {
            setNoResults(true);
        } else {
            setNoResults(false);
        }
    }, [page]);

    const resetSearch = useCallback(() => {
        setProducts([]);
        setPage(1);
        setHasMore(true);
        setNoResults(null);
    }, []);

    const performSearch = useCallback((currentPage, currentHasMore) => {
        let searchPage = currentPage ?? page;
        let searchHasMore = currentHasMore ?? hasMore;
        console.log('Outside Perform Search');
        console.log(hasMore);
        console.log(isSearching);

        if (searchHasMore && !isSearching) {
            console.log('In Perform Search');

            let query = {
                businessId: businessId,
                menuQuery: menuType,
                businessSearch: true,
                category: category,
                term: searchTerm,
                page: searchPage,
                price_from: priceFrom,
                price_to: priceTo,
                weight: weight,
                cbd_from: cbdFrom,
                cbd_to: cbdTo,
                thc_from: thcFrom,
                thc_to: thcTo,
                strain_type: strainType,
            }

            setIsSearching(true);
            return getBusinessProducts(query)
                .then(handleSearchResults)
                .catch(error => {
                    console.error("Search error:", error);
                    setIsSearching(false);
                });
        }
        return Promise.resolve();
    }, [searchTerm, category, page, hasMore, isSearching, getBusinessProducts, handleSearchResults, priceFrom, priceTo, weight, cbdFrom, cbdTo, thcFrom, thcTo, strainType]);

    useEffect(() => {
        console.log('Geoposition Changed');
        console.log('Geoposition Changed 2');
        resetSearch();
        performSearch();
    }, [businessId, category, searchTerm, priceFrom, priceTo, weight, cbdFrom, cbdTo, thcFrom, thcTo, strainType]);

    const fetchFilterCounts = useCallback(() => {

        let query = {
            filterCounts: true,
            businessId: businessId,
            menuQuery: menuType,
            businessSearch: true,
            category: category,
            term: searchTerm.length > 0 ? searchTerm : null,
            price_from: priceFrom,
            price_to: priceTo,
            weight: weight,
            cbd_from: cbdFrom,
            cbd_to: cbdTo,
            thc_from: thcFrom,
            thc_to: thcTo,
            strain_type: strainType,
        }

        getBusinessFilterCounts(query)
            .then(counts => {
                setFilterCounts(counts);
            })
            .catch(error => {
                console.error("Error fetching filter counts:", error);
            });

    }, [businessId, searchTerm, category, strainType, weight, priceFrom, priceTo, getBusinessFilterCounts]);

    useEffect(() => {
        fetchFilterCounts();
    }, [fetchFilterCounts]);

    const handleStrainTypeChange = (newStrainType) => {
        setStrainType(newStrainType);
        resetSearch();
    };

    const handleCBDChange = (from, to) => {
        setCbdFrom(from);
        setCbdTo(to);
        resetSearch();
    };

    const handleTHCChange = (from, to) => {
        setThcFrom(from);
        setThcTo(to);
        resetSearch();
    };

    const handleSearchTermChange = useCallback((term) => {
        setSearchTerm(term);
        resetSearch();
    }, []);

    const handleCategoryChange = (newCategory) => {
        console.log("CHANGING CATEGORY");
        console.log(newCategory);
        setCategory(newCategory.id);
        setCategoryName(newCategory.name);
        resetSearch();

        if (category == newCategory.id) {
            console.log("Performing Search");
            performSearch(1, true);
        }

    };

    const handlePriceChange = (from, to) => {
        setPriceFrom(from);
        setPriceTo(to);
        resetSearch();
    };

    const handleWeightChange = (newWeight, newWeightUnit) => {
        setWeight(newWeight);
        setWeightUnit(newWeightUnit);
        resetSearch();
    };

    const handleFullFilterVisible = useCallback((isVisible) => {
        handleShowHeader(isVisible); // When filter is visible, hide header and vice versa

        if(!isVisible) {
            document.body.style.overflowY = "hidden";
            document.getElementById('MainApp').style.overflowY = "hidden";
            document.getElementById('root').style.overflowY = "hidden";

        } else {
            document.body.style.overflowY = "auto";
            document.getElementById('MainApp').style.overflowY = "auto";
            document.getElementById('root').style.overflowY = "auto";
        }

    }, [handleShowHeader]);

    const bottomPadding = { paddingBottom: "400px" };

    return (
        <div className={styles.App}>
            <div
                ref={navbarFilterRef}
                className={`${styles.navbarFilterContainer} ${menuType === 'deals' ? styles.greenNavbarFilterContainer:''} ${isNavbarFilterPinned ? styles.pinned : ''}`}
                style={isNavbarFilterPinned ? { position: 'fixed', top: 0, left: 0, right: 0 } : null}
            >
                <div id="ScrollPosition"></div>
                <NavbarFilter
                    menuType={menuType}
                    businessId={businessId}
                    onTermChange={handleSearchTermChange}
                    onCategoryChange={handleCategoryChange}
                    onPriceChange={handlePriceChange}
                    onWeightChange={handleWeightChange}
                    onCBDChange={handleCBDChange}
                    onTHCChange={handleTHCChange}
                    onStrainTypeChange={handleStrainTypeChange}
                    filterCounts={filterCounts}
                    pinned={isNavbarFilterPinned}
                    showTour={showTour}
                    setShowTour={setShowTour}
                    onFullFilterVisible={handleFullFilterVisible}
                />
            </div>
            {isNavbarFilterPinned && <div style={{ height: '54px' }}/>} {/* Placeholder to prevent content jump */}
            {(noResults && !isSearching) && (<div className={styles.noResults}>No results found</div>)}

            <div className={`${styles.businessProductListWrap} product-list ${isNavbarFilterPinned ? styles.productListWrapPinned : ''}`}>
                <ProductListVirtual
                    category={category}
                    categoryName={categoryName}
                    businessId={businessId}
                    page={page}
                    products={products}
                    customStyle={bottomPadding}
                    loadMore={performSearch}
                    hasMore={hasMore}
                    isLoading={isSearching}
                    noDeals={noDeals}
                    showTour={showTour}
                    setShowTour={setShowTour}
                    priceFrom={priceFrom}
                    priceTo={priceTo}
                    selectedWeight={weight}
                    selectedWeightUnit={weightUnit}
                />
            </div>
        </div>
    );
}

export default BusinessResults;
