import forge from 'node-forge';
import constants from './constants';

const JIRA = constants.JIRA;

function deriveKey(salt, password) {
    let key = '';
    let md5Result = '';

    while (key.length < 32) {
        const md = forge.md.md5.create();
        if (md5Result.length > 0) {
            md.update(md5Result);
        }
        md.update(password);
        md.update(forge.util.hexToBytes(salt));
        md5Result = md.digest().data;
        key += md5Result;
    }

    return key.substring(0, 32);
}

async function decryptResponse(encryptedData) {
    try {
        // Parse the encrypted data
        const parsedData = typeof encryptedData === 'string' ?
                           JSON.parse(encryptedData) : encryptedData;

        if (!parsedData.ct || !parsedData.iv || !parsedData.s) {
            return encryptedData;
        }

        // Derive key using OpenSSL compatible method
        const key = deriveKey(parsedData.s, JIRA);

        // Create decipher
        const decipher = forge.cipher.createDecipher('AES-CBC', key);

        // Set IV
        decipher.start({iv: forge.util.hexToBytes(parsedData.iv)});

        // Decode base64 and decrypt
        const encrypted = forge.util.decode64(parsedData.ct);
        decipher.update(forge.util.createBuffer(encrypted));

        const success = decipher.finish();

        if (!success) {
            throw new Error('Failed to decrypt');
        }

        // Get decrypted data and parse JSON
        const decrypted = decipher.output.toString();

        return JSON.parse(decrypted);

    } catch (error) {
        console.error('Decryption error:', error);
        throw error;
    }
}

export function createCryptoInterceptor(fetchData) {
    return async (url, options) => {
        const response = await fetchData(url, options);
        try {
            if (typeof response === 'string' || (response && response.ct)) {
                return await decryptResponse(response);
            }
            return response;
        } catch (error) {
            console.error('Interceptor error:', error);
            throw error;
        }
    };
}

export function testDecrypt(encryptedData) {
    return decryptResponse(encryptedData);
}
