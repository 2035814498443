import React from 'react';
import { ChevronUp } from 'lucide-react';
import styles from './PullIndicator.module.css';
import useIsPhone from "../../hooks/useIsPhone";

const PullIndicator = ({ isExpanded, onClick }) => {
    const [isPhone, isIos] = useIsPhone();
    const [showPullText, setShowPullText] = React.useState(true);
    setTimeout(function () {
        setShowPullText(false);
    }, 1500);
    return (
        <div
            className={`${styles.pullIndicator} ${!showPullText && !isExpanded ? styles.pullIndicatorNoText:''}`}
            onClick={onClick}
        >
            {isExpanded ? (
                <ChevronUp
                    className={styles.chevronIcon}
                    strokeWidth={2.5}
                />
            ) : (
                 <>
                     <div className={styles.pullBar} />
                     <div className={showPullText ? styles.pullText:styles.pullTextFadeAway}>
                         {isPhone ? (
                             isIos ? (
                                 <span>Tap to expand map</span>
                             ): (
                                 <span>Pull to expand map</span>
                             )
                         ) : (
                              <span>Click to expand map</span>
                         )}
                     </div>
                 </>
             )}
        </div>
    );
};

export default PullIndicator;
