import React, {useState, useCallback, useRef, useEffect, forwardRef, useImperativeHandle, useMemo} from "react";
import styles from "./SearchBar.module.css";
import useApi from "../../utils/useApi";
import {BiMenuAltRight} from "react-icons/bi";
import {FaRegHeart, FaHeart} from "react-icons/fa";
import geolocation from "../../utils/geolocation";
import LocationArrow from "./LocationArrow";
import NearYou from "./NearYouIcon";


import {TbMapPinDollar} from "react-icons/tb";

import {FaCompass, FaRegCompass} from "react-icons/fa6";
import {MdHistory, MdOutlineHistory} from "react-icons/md";
import HandshakeIcon from "./HandshakeIcon";

const SearchBar = forwardRef(({ onSearchResults, geoposition, children, onSearchTermChange, onSortByChange, onLocationChange }, ref) => {
    const { isLoading, error, searchGeoProducts } = useApi();
    const [category, setCategory] = useState(2);
    const [sortBy, setSortBy] = useState("near_you");
    const [forYouTab, setForYouTab] = useState("for_you");
    const [location, setLocation] = useState("");
    const [term, setTerm] = useState("");
    const [useCurrentLocation, setUseCurrentLocation] = useState(false);
    const [page, setPage] = useState(1);  // Add page state
    const searchTimeoutRef = useRef(null);
    const initialRenderRef = useRef(true);
    const prevGeopositionRef = useRef();

    const sortByOptions = useMemo(() => ({
        "Near You": {
            key: "near_you",
            icon: NearYou,
            size: 30
        },
        "Steals": {
            key: "best_deal",
            icon: TbMapPinDollar
        },
        "For You": {
            key: "for_you",
            icon: HandshakeIcon,
            activeIcon: HandshakeIcon
        },
    }), []);

    const sortByForYou = useMemo(() => ({
        "For You": {
            key: "for_you",
            icon: HandshakeIcon,
            activeIcon: HandshakeIcon
        },
        "Favorites": {
            key: "favorites",
            icon: FaRegHeart,
            activeIcon: FaHeart
        },
        "History": {
            key: "recent",
            icon: MdOutlineHistory,
            activeIcon: MdHistory,
        },
    }), []);

    const sortByForYouDesktop = useMemo(() => ({
        "Favorites": {
            key: "favorites",
            icon: FaRegHeart,
            activeIcon: FaHeart
        },
        "History": {
            key: "recent",
            icon: MdOutlineHistory,
            activeIcon: MdHistory,
        },
    }), []);

    const performSearch = useCallback(async (useCurrentLocationOverride = false, currentSortBy = sortBy) => {
        console.log("Performing search");
        console.log("Current location:", location);
        console.log("Current geoposition:", geoposition);
        console.log("Sort by:", currentSortBy);
        console.log("Page:", page);  // Log the current page
        try {
            const geo = useCurrentLocationOverride || useCurrentLocation ? geoposition : geolocation.getStoredLocation();
            //const results = await searchGeoProducts(term, geo, category, currentSortBy, page);

            //onSearchResults(results);

            localStorage.setItem('lastSearchedTerm', term);
            if (!useCurrentLocation && !useCurrentLocationOverride) {
                localStorage.setItem('lastSearchedLocation', location);
            }
        }
        catch (error) {
            console.error("Error searching:", error);
        }
    }, [term, location, onSearchResults, useCurrentLocation, geoposition]);  // Add page to dependencies

    useImperativeHandle(ref, () => ({
        performSearch: () => performSearch(false, sortBy)
    }));

    useEffect(() => {
        if (!initialRenderRef.current &&
            (prevGeopositionRef.current?.city !== geoposition?.city ||
             prevGeopositionRef.current?.state_abbr !== geoposition?.state_abbr)) {
            console.log("Geoposition changed, performing new search");
            setLocation(`${geoposition.city}, ${geoposition.state_abbr}`);
            setPage(1);  // Reset page to 1 when geoposition changes
            performSearch(true, sortBy);
        }
        prevGeopositionRef.current = geoposition;
    }, [geoposition, sortBy, performSearch]);

    const debouncedSearch = useCallback(() => {
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            setPage(1);  // Reset page to 1 when performing a new search
            performSearch(false, sortBy);
        }, 1000);
    }, [performSearch, sortBy]);

    const handleLocationChange = useCallback((ev) => {
        setLocation(ev.target.value);
        setUseCurrentLocation(false);
        debouncedSearch();
    }, [debouncedSearch]);

    const handleTermChange = useCallback((ev) => {
        setTerm(ev.target.value);
        onSearchTermChange(ev.target.value);
        debouncedSearch();
    }, [debouncedSearch, onSearchTermChange]);

    const handleSortByChange = useCallback((newSortBy, e) => {
        console.log(newSortBy);
        const newSortByKey = sortByOptions[newSortBy].key;
        setSortBy(newSortByKey);
        onSortByChange(newSortByKey);
        setPage(1);

        // Reset forYouTab to "for_you" when selecting "For You" main tab
        if (newSortByKey === "for_you") {
            setForYouTab("for_you");
        }

        performSearch(false, newSortByKey);
    }, [sortByOptions, performSearch, onSortByChange]);

    const handleForYouTabChange = useCallback((newTab) => {
        console.log('Handling Button Click');
        setForYouTab(newTab);
        setSortBy(newTab);
        onSortByChange(newTab);
        setPage(1);
        performSearch(false, newTab);
    }, [performSearch, onSortByChange]);

    const handleUseCurrentLocation = useCallback(() => {
        onLocationChange(true);
        setUseCurrentLocation(true);
        setLocation(`${geoposition.city}, ${geoposition.state_abbr}`);
        setPage(1);  // Reset page to 1 when using current location
        performSearch(true, sortBy);
    }, [geoposition, performSearch, sortBy]);

    const renderSortByOptions = useMemo(() => {
        const windowWidth = window.innerWidth;
        return Object.keys(sortByOptions).map((sortByOption) => {
            const option = sortByOptions[sortByOption];
            let isActive = sortBy === option.key;

            if ((sortBy === 'for_you' || sortBy === 'favorites' || sortBy === 'recent') && windowWidth < 778) {
                isActive = option.key === 'for_you';
            }

            const Icon = isActive && option.activeIcon ? option.activeIcon : option.icon;

            return (
                <li
                    className={`${isActive ? styles.active : ''} ${option.key}`}
                    onClick={(e) => {
                        console.log('clicked', e);
                        e.preventDefault();
                        e.stopPropagation();
                        handleSortByChange(sortByOption);
                    }}
                    onTouchEnd={() => handleSortByChange(sortByOption)}

                    key={option.key}
                    disabled={isActive}
                >
                    <Icon/>
                    {sortByOption}
                </li>
            );
        });
    }, [sortByOptions, sortBy, handleSortByChange]);

    const renderForYouTabs = useMemo(() => {
        if (sortBy == "for_you" || sortBy == "favorites" || sortBy == 'recent') {
            return (
                <div className={`${styles.SearchBarSortOptions} ${styles.ForYouSearchBarOptions} ${styles.ForYouMobile}`}>
                    <ul>
                        {Object.keys(sortByForYou).map((tabOption) => {
                            const option = sortByForYou[tabOption];
                            const isActive = forYouTab === option.key;
                            const Icon = isActive && option.activeIcon ? option.activeIcon : option.icon;

                            return (
                                <button
                                    className={`${isActive ? styles.active : ''} ${option.key} ${styles.forYouButtons}`}
                                    onClick={(e) => {
                                        console.log('clicked', e);
                                        console.log(option);
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleForYouTabChange(option.key);
                                    }}
                                    key={option.key}
                                    disabled={isActive}
                                >
                                    {option.size && (
                                        <Icon size={option.size} />
                                    )}
                                    {!option.size && (
                                        <Icon/>
                                    )}
                                    {tabOption}
                                </button>
                            );
                        })}
                    </ul>
                </div>
            );
        }

        return null;
    }, [sortBy, forYouTab, sortByForYou, handleForYouTabChange]);

    const renderForYouTabsDesktop = useMemo(() => {

        return (
            <div className={`${styles.SearchBarSortOptions} ${styles.ForYouSearchBarOptions} ${styles.ForYouDesktop}`}>
                <ul>
                    {Object.keys(sortByForYouDesktop).map((tabOption) => {
                        const option = sortByForYouDesktop[tabOption];
                        const isActive = sortBy === option.key;
                        const Icon = isActive && option.activeIcon ? option.activeIcon : option.icon;

                        return (
                            <button
                                className={`${isActive ? styles.active : ''} ${option.key} ${styles.forYouButtons}`}
                                onClick={() => handleForYouTabChange(option.key)}
                                key={option.key}
                                disabled={isActive}
                            >
                                <Icon/>
                                {tabOption}
                            </button>
                        );
                    })}
                </ul>
            </div>
        );

        return null;
    }, [sortBy, forYouTab, sortByForYou, handleForYouTabChange]);

    return (
        <div className={styles.SearchBar}>
            <div className={styles.SearchBarSortOptions}>
                <div className={styles.SortOptionWrapper}>
                    <ul>{renderSortByOptions}</ul>
                    {renderForYouTabsDesktop}
                </div>

            </div>
            <div className={styles.MapAndFilters}>
                <div className={styles.MapContainer}>
                    {children}
                    <button
                        className={styles.UseCurrentLocationButton}
                        onClick={handleUseCurrentLocation}
                    >
                        <LocationArrow size={24} color={'white'}/>
                    </button>
                </div>
                {renderForYouTabs}
            </div>

            {error && <p>Error: {error}</p>}
        </div>
    );
});

export default React.memo(SearchBar);
