import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {
    X,
    MapPin,
    Clock,
    Mail,
    Globe,
    Instagram,
    Shield,
    Store,
    CreditCard,
    BadgeCheck,
    Shield as SecurityIcon,
    ChevronDown,
    Banknote,
    CircleDollarSign,
    Medal
} from 'lucide-react';



import styles from './BusinessBottomSheet.module.css';
import {PiWheelchair} from "react-icons/pi";
import {SiHomeassistantcommunitystore} from "react-icons/si";
import {HiDevicePhoneMobile} from "react-icons/hi2";
import {BsBank2} from "react-icons/bs";
import useSafariNavBarState from "../../hooks/useSafariNavBarState";

const BusinessBottomSheet = ({ business, isOpen, onClose, showHours }) => {
    const [isIOSSafari, setIsIOSSafari] = useState(false);
    const [customStyle, setCustomStyle] = useState({});
    const isNavExpanded = useSafariNavBarState();
    const [isClosing, setIsClosing] = useState(false);
    const [showFullSchedule, setShowFullSchedule] = useState(showHours ? true : false);

    useEffect(() => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setIsIOSSafari(isIOS && isSafari);

        if(isIOS && isSafari){
            setCustomStyle(
                {
                    maxHeight: `calc(100vh - ${isNavExpanded ? '96px' : '96px'})`
                }
            );
        }
    }, []);

    useEffect(() => {
        if (showHours) {
            setShowFullSchedule(true);
        }
    }, [showHours]);

    const handleClose = () => {
        setIsClosing(true);
        document.body.style.overflowY = 'auto';

        setTimeout(() => {
            onClose();
            setIsClosing(false);
            setShowFullSchedule(false);
        }, 300);
    };

    const formatSchedule = (schedule) => {
        if (!schedule) return null;

        try {
            const parsedSchedule = typeof schedule === 'string' ? JSON.parse(schedule) : schedule;
            const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            const today = days[new Date().getDay()];
            const todaySchedule = parsedSchedule[today];

            if (!todaySchedule) return null;

            return `${todaySchedule.open} - ${todaySchedule.close}`;
        } catch (e) {
            console.error('Error parsing schedule:', e);
            return null;
        }
    };

    const getFullSchedule = (schedule) => {
        if (!schedule) return [];

        try {
            const parsedSchedule = typeof schedule === 'string' ? JSON.parse(schedule) : schedule;
            const daysOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

            return daysOrder.map(day => ({
                day,
                schedule: parsedSchedule[day.toLowerCase()],
            }));
        } catch (e) {
            console.error('Error parsing full schedule:', e);
            return [];
        }
    };

    const getBusinessTypes = (business) => {
        const types = [];

        try {
            const statuses = typeof business.currentStatuses === 'string' ? JSON.parse(business.currentStatuses):business.currentStatuses;
            ;
            const flags = typeof business.flags === 'string' ? JSON.parse(business.flags) : business.flags;
            //console.log(flags);
            if (flags?.includes('storefront')) {
                types.push('Storefront');
            }

            if(statuses.includes('pickup')){
                types.push('Pickup');
            }
        } catch (e) {
            if (business.retailType === 'dispensary') {
                types.push('Storefront');
            }
        }

        if (business.pickupEnabled) {
            types.push('Pickup');
        }

        if (business.deliveryEnabled || (business.flags && business.flags.includes('delivery'))) {
            types.push('Delivery');
        }

        if(business.preorderEnabled){
            types.push('Preorder');
        }

        return types.join(' | ') || 'Storefront';
    };

    const getStoreStatus = (business) => {
        if (!business.schedule) return false;

        try {
            const schedule = typeof business.schedule === 'string'
                             ? JSON.parse(business.schedule)
                             : business.schedule;

            const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            const today = days[new Date().getDay()];

            return schedule[today]?.isOpen || false;
        } catch (e) {
            console.error('Error parsing store status:', e);
            return false;
        }
    };

    const parseFlags = (input) => {
        try {
            if (Array.isArray(input)) {
                return input;
            }

            if (typeof input === 'string') {
                let parsed = input;
                // Handle double-stringified JSON by parsing until we get a non-string
                while (typeof parsed === 'string') {
                    parsed = JSON.parse(parsed);
                }

                if (Array.isArray(parsed)) {
                    return parsed;
                }
                if (typeof parsed === 'object' && parsed !== null) {
                    return Object.values(parsed);
                }
            }

            if (typeof input === 'object' && input !== null) {
                return Object.values(input);
            }

            return [];
        } catch {
            return [];
        }
    };

    const getAmenities = (business) => {
        const amenities = [];
        try {
            const flags = parseFlags(business.flags);

            console.log(flags);
            // Existing amenities
            if (flags?.includes('ada')) {
                amenities.push({
                                   icon: <PiWheelchair className={styles.amenityIconSvg} />,
                                   label: 'Accessible'
                               });
            }

            amenities.push({
                               icon: <div className={styles.ageIcon}>21+</div>,
                               label: 'Age minimum'
                           });

            if (flags?.includes('debit_cards') || flags?.includes('credit_cards') || flags?.includes('acceptsCreditCards') || flags?.includes('acceptsDebit')) {
                amenities.push({
                                   icon: <CreditCard className={styles.amenityIconSvg} />,
                                   label: 'Card Payment'
                               });
            }

            if (flags?.includes('licensed')) {
                amenities.push({
                                   icon: <SecurityIcon className={styles.amenityIconSvg} />,
                                   label: 'Security'
                               });
            }

            if (business.organizationId) {
                amenities.push({
                                   icon: <BadgeCheck className={styles.amenityIconSvg} />,
                                   label: 'Brand Verified'
                               });
            }

            // Extra amenities
            if (flags?.includes('atm')) {
                amenities.push({
                                   icon: <BsBank2 className={styles.amenityIconSvg} />,
                                   label: 'ATM Available'
                               });
            }

            if (flags?.includes('cash')) {
                amenities.push({
                                   icon: <Banknote className={styles.amenityIconSvg} />,
                                   label: 'Cash Accepted'
                               });
            }

            if (flags?.includes('debit_cards')) {
                amenities.push({
                                   icon: <CircleDollarSign className={styles.amenityIconSvg} />,
                                   label: 'Debit Cards'
                               });
            }

            if (flags?.includes('veteran_discount')) {
                amenities.push({
                                   icon: <Medal className={styles.amenityIconSvg} />,
                                   label: 'Veteran Discount'
                               });
            }

        } catch (e) {
            console.error('Error parsing amenities:', e);
        }

        return amenities;
    };

    const renderBusinessDetails = () => {
        if (!business) return null;

        const schedule = formatSchedule(business.schedule);
        const fullSchedule = getFullSchedule(business.schedule);
        const businessTypes = getBusinessTypes(business);
        const isStoreOpen = getStoreStatus(business);
        const amenities = getAmenities(business);

        return (
            <div className={styles.businessContent}>
                <div className={styles.detailsSection}>
                    <div className={styles.detailItem}>
                        <SiHomeassistantcommunitystore className={styles.icon} />
                        <span>{business.name}</span>
                    </div>
                    <div className={styles.detailItem}>
                        <Store className={styles.icon} />
                        <span>{businessTypes}</span>
                    </div>

                    <div className={styles.detailItem}>
                        <Clock className={styles.icon} />
                        <div className={`${styles.scheduleWrapper} ${showFullSchedule ? styles.expanded : ''}`}>
                            <div
                                className={styles.currentSchedule}
                                onClick={() => setShowFullSchedule(!showFullSchedule)}
                            >
                                <span className={isStoreOpen ? styles.openLabel : styles.closedLabel}>
                                    {isStoreOpen ? 'Open' : 'Closed'}
                                </span>
                                {schedule && <span>Today {schedule}</span>}
                                <ChevronDown
                                    className={`${styles.chevron} ${showFullSchedule ? styles.rotated : ''}`}
                                    size={20}
                                />
                            </div>
                            {showFullSchedule && (
                                <div className={styles.fullSchedule}>
                                    {fullSchedule.map(({ day, schedule }) => (
                                        <div key={day} className={styles.scheduleRow}>
                                            <span className={styles.scheduleDay}>{day}</span>
                                            <span className={styles.scheduleTime}>
                                                {schedule?.open} - {schedule?.close}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={styles.detailItem}>
                        <MapPin className={styles.icon} />
                        <a
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                `${business.name} ${business.address1}`
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.link}
                        >
                            {business.address1}, {business.city}, {business.state} {business.zip_code}
                        </a>
                    </div>

                    <div className={styles.detailItem}>
                        <HiDevicePhoneMobile className={styles.icon} />
                        <a href={`tel:${business.phone_number}`} className={styles.link}>
                            {business.phone_number}
                        </a>
                    </div>

                    <div className={styles.detailItem}>
                        <Store className={styles.icon} />
                        <span>
                            {business.medical ? 'Medical' : ''}
                            {business.medical && business.recreational ? ' & ' : ''}
                            {business.recreational ? 'Recreational' : ''}
                        </span>
                    </div>

                    {business.email && (
                        <div className={styles.detailItem}>
                            <Mail className={styles.icon} />
                            <a href={`mailto:${business.email}`} className={styles.link}>
                                Email
                            </a>
                        </div>
                    )}

                    {business.website && (
                        <div className={styles.detailItem}>
                            <Globe className={styles.icon} />
                            <a href={business.website} target="_blank" rel="noopener noreferrer" className={styles.link}>
                                Website
                            </a>
                        </div>
                    )}

                    {business.instagram && (
                        <div className={styles.detailItem}>
                            <Instagram className={styles.icon} />
                            <a
                                href={`https://instagram.com/${business.instagram}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.link}
                            >
                                Instagram
                            </a>
                        </div>
                    )}

                    {business.blackOwned > 0 && (
                        <div className={styles.detailItem}>
                            <Shield className={styles.icon} />
                            <span>Supports the Black community</span>
                        </div>
                    )}
                </div>

                {amenities.length > 0 && (
                    <div className={styles.amenitiesSection}>
                        <h2 className={styles.sectionTitle}>Amenities</h2>
                        <div className={styles.amenitiesGrid}>
                            {amenities.map((amenity, index) => (
                                <div key={index} className={styles.amenityItem}>
                                    <div className={styles.amenityIcon}>
                                        {amenity.icon}
                                    </div>
                                    <span className={styles.amenityLabel}>{amenity.label}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    if (!isOpen && !isClosing) return null;

    return ReactDOM.createPortal(
        <div
            className={`${styles.bottomSheetOverlay} ${isClosing ? styles.closing : ''}`}
            onClick={handleClose}
        >

            <div
                className={`${styles.bottomSheetContent} ${isOpen ? styles.open : ''} ${isClosing ? styles.closing : ''}`}
                style={customStyle}
                onClick={(e) => e.stopPropagation()}
            >
                <button className={styles.bottomSheetCloseButton} onClick={handleClose}>
                    <X size={24}/>
                </button>
                <div className={styles.bottomSheetHeader}>
                    <h1>Store details</h1>
                    <img src={business.logoImage} className={styles.businessLogo}/>
                </div>
                <div className={styles.bottomSheetScrollContent}>
                    {renderBusinessDetails()}
                </div>
            </div>
        </div>,
        document.body
    );
};

export default BusinessBottomSheet;
