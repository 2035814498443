import React, {useState, useEffect} from 'react';
import styles from './BackgroundMedia.module.css';

const BackgroundMedia = ({ geoposition, customStyle }) => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [videoSrc, setVideoSrc] = useState('');
    const [bgSrc, setBgSrc] = useState('');

    // Move media imports outside of component to prevent re-creation on each render
    const mediaAssets = {
        video: {
            california: require("../../../assets/video/california-optimized.mp4"),
            la: require("../../../assets/video/la-optimized.mp4"),
            sanFran: require("../../../assets/video/san-francisco-optimized.mp4"),
            sanDiego: require("../../../assets/video/san-diego-optimized.mp4"),
            arizona: require("../../../assets/video/smoke-phoenix-optimized.mp4"),
            seattle: require("../../../assets/video/seattle-optimized.mp4"),
            boston: require("../../../assets/video/boston-optimized.mp4"),
            portland: require("../../../assets/video/portland-optimized.mp4"),
            lasVegas: require("../../../assets/video/las-vegas-optimized.mp4"),
        },
        background: {
            california: require("../../../assets/login/california.jpg"),
            la: require("../../../assets/login/la.jpg"),
            sanFran: require("../../../assets/login/san-francisco.jpg"),
            sanDiego: require("../../../assets/login/san-diego.jpg"),
            arizona: require("../../../assets/login/phoenix.jpg"),
            seattle: require("../../../assets/login/seattle.jpg"),
            boston: require("../../../assets/login/boston.jpg"),
            portland: require("../../../assets/login/portland.jpg"),
            lasVegas: require("../../../assets/login/las-vegas.jpg"),
        }
    };

    useEffect(() => {
        // Reset video loaded state when sources change
        setIsVideoLoaded(false);

        if (!geoposition?.state) {
            // Set default media
            setBgSrc(mediaAssets.background.la);
            setVideoSrc(mediaAssets.video.la);
            return;
        }

        // Create a function to update media sources
        const updateMediaSources = () => {
            switch (geoposition.state) {
                case 'Arizona':
                    setBgSrc(mediaAssets.background.arizona);
                    setVideoSrc(mediaAssets.video.arizona);
                    break;
                case 'Nevada':
                    setBgSrc(mediaAssets.background.lasVegas);
                    setVideoSrc(mediaAssets.video.lasVegas);
                    break;
                case 'Washington':
                    setBgSrc(mediaAssets.background.seattle);
                    setVideoSrc(mediaAssets.video.seattle);
                    break;
                case 'Oregon':
                    setBgSrc(mediaAssets.background.portland);
                    setVideoSrc(mediaAssets.video.portland);
                    break;
                case 'California':
                    switch (geoposition.city) {
                        case 'San Francisco':
                            setBgSrc(mediaAssets.background.sanFran);
                            setVideoSrc(mediaAssets.video.sanFran);
                            break;
                        case 'Los Angeles':
                            setBgSrc(mediaAssets.background.la);
                            setVideoSrc(mediaAssets.video.la);
                            break;
                        case 'San Diego':
                            setBgSrc(mediaAssets.background.sanDiego);
                            setVideoSrc(mediaAssets.video.sanDiego);
                            break;
                        default:
                            setBgSrc(mediaAssets.background.california);
                            setVideoSrc(mediaAssets.video.california);
                    }
                    break;
                default:
                    setBgSrc(mediaAssets.background.california);
                    setVideoSrc(mediaAssets.video.california);
            }
        };

        updateMediaSources();
    }, [geoposition?.state, geoposition?.city]); // Only depend on the specific properties we use

    // Force video element recreation when source changes
    const videoKey = `video-${videoSrc}`;

    return (
        <>
            <div
                className={styles.backgroundImage}
                style={{ backgroundImage: `url(${bgSrc})` }}
            />
            <div className={styles.absBackground} style={customStyle}/>
            <div className={styles.videoContainer}>
                {videoSrc && (
                    <video
                        key={videoKey}
                        autoPlay
                        muted
                        loop
                        playsInline
                        className={`${styles.backgroundVideo} ${isVideoLoaded ? styles.videoLoaded : ''}`}
                        onLoadedData={() => setIsVideoLoaded(true)}
                    >
                        <source src={videoSrc} type="video/mp4"/>
                    </video>
                )}
            </div>
        </>
    );
};

export default BackgroundMedia;
