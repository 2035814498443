import React, {useState, useEffect, useRef} from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import {PiMapPinAreaBold} from "react-icons/pi";
import {Check, ChevronDown, Plus} from "lucide-react";
import styles from './LocationHistoryListbox.module.css';

const MAX_HISTORY = 5;

const LocationHistoryListbox = ({
                                    geoposition,
                                    locationLoading,
                                    onLocationSelect,
                                    onAddNewLocation
                                }) => {
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const buttonRef = useRef(null);

    useEffect(() => {
        const savedLocations = JSON.parse(localStorage.getItem('locationHistory') || '[]');
        setLocations(savedLocations);

        if (geoposition?.city) {
            setSelectedLocation({
                                    city: geoposition.city,
                                    state_abbr: geoposition.state_abbr,
                                    latitude: geoposition.latitude,
                                    longitude: geoposition.longitude
                                });
        }
    }, []);

    useEffect(() => {
        if (geoposition?.city && !locationLoading) {
            const newLocation = {
                city: geoposition.city,
                state: geoposition.state,
                state_abbr: geoposition.state_abbr,
                latitude: geoposition.latitude,
                longitude: geoposition.longitude
            };

            setSelectedLocation(newLocation);

            setLocations(prevLocations => {
                const filteredLocations = prevLocations.filter(
                    loc => !(loc.city === newLocation.city && loc.state_abbr === newLocation.state_abbr)
                );
                const updatedLocations = [newLocation, ...filteredLocations].slice(0, MAX_HISTORY);
                localStorage.setItem('locationHistory', JSON.stringify(updatedLocations));
                return updatedLocations;
            });
        }
    }, [geoposition, locationLoading]);

    const handleLocationChange = (location) => {
        if (location === 'add-new') {
            onAddNewLocation();
            setMenuOpen(false);
            return;
        }

        setSelectedLocation(location);
        onLocationSelect(location);
        setMenuOpen(false);
    };

    const handleRemoveLocation = (locationToRemove) => {
        // Filter out the location we want to remove,
        // then update both localStorage and state.
        setLocations(prevLocations => {
            const updatedLocations = prevLocations.filter(
                loc => !(loc.city === locationToRemove.city && loc.state_abbr === locationToRemove.state_abbr)
            );
            localStorage.setItem('locationHistory', JSON.stringify(updatedLocations));
            return updatedLocations;
        });
    };

    return (
        <div className={styles.locationHistoryContainer}>
            <NavigationMenu.Root value={menuOpen} onValueChange={setMenuOpen}>
                <NavigationMenu.List>
                    <NavigationMenu.Item>
                        <NavigationMenu.Trigger className={styles.listboxButton} ref={buttonRef}>
                            <div className={styles.buttonContent}>
                                <PiMapPinAreaBold size={18}/>
                                <span className={styles.city}>
                                    {locationLoading
                                     ? 'Updating...'
                                     : selectedLocation
                                       ? `${selectedLocation.city}, ${selectedLocation.state_abbr}`
                                       : 'Select location'
                                    }
                                </span>
                                <ChevronDown className={styles.chevronIcon} aria-hidden="true"/>
                            </div>
                        </NavigationMenu.Trigger>

                        <NavigationMenu.Content className={styles.listboxOptions}>
                            {locations.map((location, idx) => (
                                <button
                                    key={`${location.city}-${location.state_abbr}-${idx}`}
                                    className={`${styles.listboxOption} ${
                                        selectedLocation?.city === location.city &&
                                        selectedLocation?.state_abbr === location.state_abbr ? styles.active : ''
                                    }`}
                                    onClick={() => handleLocationChange(location)}
                                >
                                    <div className={styles.optionContent}>
                                        <div className={styles.locationTextWrap}>
                                            {selectedLocation?.city === location.city &&
                                             selectedLocation?.state_abbr === location.state_abbr && (
                                                 <span className={`${styles.checkIcon} ${styles.checkIconActive}`}>
                                                <Check className={styles.icon} aria-hidden="true"/>
                                            </span>
                                             )}
                                            <span className={`${styles.locationText} ${
                                                    selectedLocation?.city === location.city &&
                                                    selectedLocation?.state_abbr === location.state_abbr ? styles.selected : ''
                                                }`}>
                                                {`${location.city}, ${location.state_abbr}`}
                                            </span>
                                        </div>

                                        <span
                                            className={styles.removeLocationButton}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveLocation(location);
                                            }}
                                        >
                                          X
                                        </span>
                                    </div>
                                </button>
                            ))}
                            <button
                                className={`${styles.listboxOption} ${styles.addNewOption}`}
                                onClick={() => handleLocationChange('add-new')}
                            >
                                <div className={styles.addNewContent}>
                                    <Plus className={styles.plusIcon}/>
                                    <span className={styles.addNewText}>
                                        Add new location
                                    </span>
                                </div>
                            </button>
                        </NavigationMenu.Content>
                    </NavigationMenu.Item>
                </NavigationMenu.List>

                <NavigationMenu.Viewport/>
            </NavigationMenu.Root>
        </div>
    );
};

export default LocationHistoryListbox;
