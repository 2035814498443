import React, { useState, useEffect, useCallback } from 'react';
import useApi from '../../../utils/useApi';
import styles from './AssignUserToDispensary.module.css';
import debounce from 'lodash/debounce';
import { useParams } from "react-router-dom";
import {BsFillPersonVcardFill} from "react-icons/bs";

const AssignUserToDispensary = ({ handleShowHeader, handleShowSettingsHeader }) => {
    const { id } = useParams();
    const [users, setUsers] = useState([]);
    const [dispensaries, setDispensaries] = useState([]);
    const [roles, setRoles] = useState([]);
    const [userSearch, setUserSearch] = useState('');
    const [dispensarySearch, setDispensarySearch] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDispensary, setSelectedDispensary] = useState(null);
    const [selectedRole, setSelectedRole] = useState('');
    const [locationsLoading, setLocationsLoading] = useState(false);
    const [usersLoading, setUsersLoading] = useState(false);
    const [connectedUsers, setConnectedUsers] = useState([]);
    const { searchUsers, searchDispensaries, getRoles, assignUserToDispensary, isLoading, error, getDispensaryUsers } = useApi();


    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(true);
        fetchRoles();
        searchMyLocations();
    }, []);

    const searchMyLocations = async () => {
        let results;
        if (id) {
            setLocationsLoading(true);
            results = await searchDispensaries(false, false, id);
            setSelectedDispensary(results[0]);
            setLocationsLoading(false);
            fetchConnectedUsers(results[0].id);
        } else {
            setLocationsLoading(true);
            results = await searchDispensaries();
            setLocationsLoading(false);
        }
        setDispensaries(results);
    }

    const fetchConnectedUsers = async (dispensaryId) => {
        try {
            const users = await getDispensaryUsers(dispensaryId);
            setConnectedUsers(users);
        } catch (error) {
            console.error('Error fetching connected users:', error);
        }
    }

    const fetchRoles = async () => {
        const fetchedRoles = await getRoles();
        setRoles(fetchedRoles);
    };

    const debouncedSearchUsers = useCallback(
        debounce(async (query) => {
            if (query.length > 2) {
                setUsersLoading(true);
                const results = await searchUsers(query);
                setUsers(results);
                setUsersLoading(false);
            }
        }, 500),
        [searchUsers]
    );

    const debouncedSearchDispensaries = useCallback(
        debounce(async (query) => {
            if (query.length > 2) {
                setLocationsLoading(true);
                const results = await searchDispensaries(query);
                setDispensaries(results);
                setLocationsLoading(false);
            }
        }, 500),
        [searchDispensaries]
    );

    useEffect(() => {
        debouncedSearchUsers(userSearch);
    }, [userSearch, debouncedSearchUsers]);

    useEffect(() => {
        debouncedSearchDispensaries(dispensarySearch);
    }, [dispensarySearch, debouncedSearchDispensaries]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedUser || !selectedDispensary || !selectedRole) {
            alert('Please select a user, dispensary, and role before submitting.');
            return;
        }
        try {
            await assignUserToDispensary(selectedUser.id, selectedDispensary.id, selectedRole);
            alert('User assigned successfully!');
            setSelectedUser(null);
            setSelectedRole('');
            setUserSearch('');
            fetchConnectedUsers(selectedDispensary.id);
        } catch (error) {
            console.error('Error assigning user:', error);
            alert('Failed to assign user. Please try again.');
        }
    };

    const handleDispensarySelect = (dispensary) => {
        setSelectedDispensary(dispensary);
        fetchConnectedUsers(dispensary.id);
    };

    const getUserRolesForDispensary = (user, dispensaryId) => {
        return user.roles;
    };

    const formatRoles = (userRoles) => {
        if (!userRoles || userRoles.length === 0) return 'No roles assigned';
        return userRoles.map(role => role.name).join(' | ');
    };

    if (error) return <div>Error: {error}</div>;

    return (
        <div className={styles.container}>
            <h2>Assign User to Dispensary</h2>

            <h3>{selectedDispensary ? selectedDispensary.name : ''}</h3>
            <div className={styles.searchSection}>
                <input
                    type="text"
                    placeholder="Search Users"
                    value={userSearch}
                    onChange={(e) => setUserSearch(e.target.value)}
                />
                {!usersLoading && <div className={styles.resultsList}>
                    {users.map(user => (
                        <div
                            key={user.id}
                            className={`${styles.resultItem} ${selectedUser === user ? styles.selected : ''}`}
                            onClick={() => setSelectedUser(user)}
                        >
                            {user.name} - {user.email}
                        </div>
                    ))}
                </div>}

                {usersLoading && <div>Loading...</div>}
            </div>

            <div className={styles.searchSection}>
                {!id && <input
                    type="text"
                    placeholder="Search Dispensaries"
                    value={dispensarySearch}
                    onChange={(e) => setDispensarySearch(e.target.value)}
                />}

                {(!locationsLoading && !id) && <div className={styles.resultsList}>
                    {dispensaries.map(dispensary => (
                        <div
                            key={dispensary.id}
                            id={"dispensary-" + dispensary.id}
                            className={`${styles.resultItem} ${selectedDispensary === dispensary ? styles.selected : ''}`}
                            onClick={() => setSelectedDispensary(dispensary)}
                        >
                            {dispensary.name}
                        </div>
                    ))}
                </div>}

                {locationsLoading && <div>Loading...</div>}
            </div>

            <div className={styles.selectionInfo}>
                <p>Selected User: {selectedUser ? selectedUser.name : 'None'}</p>
            </div>

            <select
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                className={styles.roleSelect}
            >
                <option value="">Select Role</option>
                {roles.map(role => (
                    <option key={role.id} value={role.id}>{role.name}</option>
                ))}
            </select>

            <button
                onClick={handleSubmit}
                className={styles.submitButton}
                disabled={!selectedUser || !selectedDispensary || !selectedRole}
            >
                Assign User to Dispensary
            </button>

            {selectedDispensary && (
                <div className={styles.connectedUsers}>
                    <h3>Users Connected to {selectedDispensary.name}</h3>
                    {connectedUsers.length > 0 ? (
                        <ul className={styles.userList}>
                            {connectedUsers.map(user => {
                                const userRoles = getUserRolesForDispensary(user, selectedDispensary.id);
                                return (
                                    <li key={user.id} className={styles.userItem}>
                                        <div className={styles.userImageWrap}>
                                            {user.profile_photo && (
                                                <img src={user.profile_photo} alt={user.name + ' Profile Photo'}/>
                                            )}
                                            {!user.profile_photo && (
                                                <div className={styles.profilePhotoPlaceholder}>
                                                    <BsFillPersonVcardFill size={36}/>
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles.userName}>{user.name}</div>
                                        <div className={styles.userEmail}>{user.email}</div>
                                        <div className={styles.userRoles}>
                                            {formatRoles(userRoles)}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                         <p>No users connected to this dispensary.</p>
                     )}
                </div>
            )}
        </div>
    );
};

export default AssignUserToDispensary;
