import React, {useEffect, useState, useCallback, useMemo} from 'react';
import styles from './DealPostForm.module.css';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import './iosDatePicker.css';

import ProfileIconSquare from "../ProfileIconSquare/ProfileIconSquare";
import { LuArrowUpToLine } from "react-icons/lu";
import { MdOutlineCameraAlt } from "react-icons/md";
import { TbClockHour4 } from "react-icons/tb";
import { RiTimerFlashLine } from "react-icons/ri";
import { PiCalendarPlusBold } from "react-icons/pi";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { BsBookmark } from "react-icons/bs";
import { FaBookOpen } from "react-icons/fa6";
import { HiMiniArrowUturnLeft } from "react-icons/hi2";
import { PiImageFill } from "react-icons/pi";

import useApi from "../../utils/useApi";

const DealPostForm = ({ handleShowHeader, handleShowSettingsHeader }) => {
    const {id, business_id} = useParams();

    const navigate = useNavigate();
    const [isLocationsLoading, setIsLocationsLoading] = useState(true);
    const [locationsError, setLocationsError] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const { isLoading, error, getCategories, searchGeo, submitDeal, deleteDeal, deleteDealProduct, getLocationDeals, getDeal, updateDeal, getBusiness, searchProducts } = useApi();
    const [searchLoading, setSearchLoading] = useState(false);
    const [view, setView] = useState('post');
    const [locationDeals, setLocationDeals] = useState([]);
    const [formData, setFormData] = useState({
                                                 dispensary_id: '',
                                                 category_id: '',
                                                 menu_url: '',
                                                 link: '',
                                                 finePrint: '',
                                                 title: '',
                                                 image: null,
                                                 endsAt: dayjs(),
                                                 startsAt: dayjs(),
                                                 urgency: false,
                                                 discountAmount: '',
                                                 discountType: 'percent_off',
                                                 products: [],
                                                product_ids: []
                                             });

    const [categories, setCategories] = useState([]);
    const [locations, setLocations] = useState([]);
    const [isEndsPickerOpen, setIsEndsPickerOpen] = useState(false);
    const [isStartsPickerOpen, setIsStartsPickerOpen] = useState(false);
    const api = useApi();

    const memoizedApi = useMemo(() => ({
        searchDispensaries: api.searchDispensaries
    }), []);

    const getDiscountAmountLabel = () => {
        switch(formData.discountType) {
            case 'fixed_price':
                return 'Sale Price';
            default:
                return 'Discount Amount';
        }
    };

    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(true)

        const fetchCategories = async () => {
            try {
                const categoriesData = await getCategories();
                setCategories(categoriesData);
            } catch (error) {
                console.error("Failed to fetch categories:", error);
            }
        };

        const fetchLocations = async () => {
            try {
                let mine = true;
                setIsLocationsLoading(true);
                const data = await memoizedApi.searchDispensaries(false, mine);
                setLocations(data);
            } catch (err) {
                setLocationsError(err.message);
            } finally {
                setIsLocationsLoading(false);
            }
        };

        const fetchDeal = async () => {
            if (id) {
                try {
                    const dealData = await getDeal(id);
                    setFormData({
                                    ...dealData,
                                    endsAt: dayjs(dealData.endsAt),
                                    startsAt: dayjs(dealData.startsAt),
                                    urgency: dealData.urgency || false
                                });

                    setSearchResults(Array.isArray(dealData.products) ? dealData.products : []);

                } catch (error) {
                    console.error("Failed to fetch deal:", error);
                }
            }
        };

        const fetchBusiness = async () => {
            if (business_id) {
                try {
                    const businessData = await getBusiness(business_id);
                    setFormData(prevData => ({
                        ...prevData,
                        dispensary_id: businessData.id,
                    }));
                    // Optionally, you can also set other business-related fields here
                } catch (error) {
                    console.error("Failed to fetch business:", error);
                }
            }
        };

        fetchCategories();
        fetchLocations();
        fetchDeal();
        fetchBusiness();

    }, [handleShowHeader, getCategories, searchGeo, id, business_id, getDeal, getBusiness, memoizedApi]);

    const logFormValues = useCallback(() => {
        console.log('Current Form Values:', formData);
    }, [formData]);

    useEffect(() => {
        logFormValues();
    }, [formData, logFormValues]);

    const updateFormData = (key, value) => {
        setFormData(prevData => ({
            ...prevData,
            [key]: value
        }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        updateFormData(name, value);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            updateFormData('image', file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCameraCapture = (event) => {
        const file = event.target.files[0];
        if (file) {
            updateFormData('image', file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async () => {
        console.log(formData);
        try {
            let response;
            if (id) {
                response = await updateDeal(id, formData);

                navigate(`/location-settings/${formData.dispensary_id}/deal-manager`);

            } else {
                response = await submitDeal(formData);

                navigate(`/location-settings/${formData.dispensary_id}/deal-manager`);
            }
            console.log(response);
            // Handle successful submission here
        } catch (error) {
            console.error("Error submitting deal:", error);
            // Handle error here
        }
    }

    const handleDateTimeChange = (date, key) => {
        updateFormData(key, date);
    };

    const toggleUrgency = () => {
        updateFormData('urgency', !formData.urgency);
    };

    const saveDraft = () => {
        console.log('Saving draft:', formData);
        // Implement draft saving logic here
    };

    const fetchLocationDeals = async () => {
        try {
            const deals = await getLocationDeals(formData.dispensary_id);
            setLocationDeals(deals);
        } catch (error) {
            console.error("Failed to fetch location deals:", error);
        }
    };

    const handleViewChange = (newView) => {
        setView(newView);
        if (newView === 'history') {
            fetchLocationDeals();
        }
    };

    const handleProductSearch = async (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        console.log("Search term:", term);

        if (term.length > 1) {
            try {
                setSearchLoading(true);
                console.log("Calling searchProducts");
                const results = await searchProducts(formData.dispensary_id, term, formData.product_ids);
                console.log("Search results:", results);
                setSearchResults(Array.isArray(results) ? results : []);
                setSearchLoading(false);
            } catch (error) {
                console.error("Error searching products:", error);
                setSearchResults([]);
            }
        } else {
            setSearchResults([]);
        }
    };

    useEffect(() => {
        console.log("Current searchResults:", searchResults);
    }, [searchResults]);

    const addProduct = (product) => {
        setFormData(prevData => ({
            ...prevData,
            products: [...(prevData.products || []), product],
            product_ids: [...(prevData.product_ids || []), product.id]
        }));
        setSearchTerm('');
        setSearchResults([]);
    };

// Also update the removeProduct function to maintain consistency:
    const removeProduct = async (event, productId) => {
        event.preventDefault(); // Prevent the default form submission

        if (id) {
            try {
                await deleteDealProduct(id, productId);
                // If the API call is successful, update the local state
                setFormData(prevData => ({
                    ...prevData,
                    products: (prevData.products || []).filter(p => p.id !== productId),
                    product_ids: (prevData.product_ids || []).filter(id => id !== productId)
                }));
            } catch (error) {
                console.error("Failed to delete product from deal:", error);
                // Optionally, show an error message to the user
            }
        } else {
            // If there's no deal id (new deal), just update the local state
            setFormData(prevData => ({
                ...prevData,
                products: (prevData.products || []).filter(p => p.id !== productId),
                product_ids: (prevData.product_ids || []).filter(id => id !== productId)
            }));
        }
    };

    const handleDeleteDeal = async () => {
        if (id) {
            try {
                await deleteDeal(id);
                // Navigate back to the deals list for this business
                if (formData.dispensary_id) {
                    console.log(formData.dispensary_id);
                    console.log("Should navigate back to deals");
                    navigate(`/location-settings/${formData.dispensary_id}/deal-manager`);
                } else {
                    // Fallback navigation if business_id is not available
                    navigate('/location-admin');
                }
            } catch (error) {
                console.error("Failed to delete deal:", error);
                // Optionally, show an error message to the user
                alert("Failed to delete deal. Please try again.");
            }
        }
    };


    const renderProductGrid = () => {
        if (!formData.products || formData.products.length === 0) {
            return <p>No products selected</p>;
        }

        return (
            <div className={styles.productList}>
                {formData.products.map(product => (
                    <div key={product.id} className={styles.productItem}>
                        <img
                            src={product.formatted_thumbnail_url || '/placeholder-image.jpg'}
                            alt={product.name}
                            className={styles.productThumbnail}
                        />
                        <div className={styles.productInfo}>
                            <p className={styles.productName}>{product.name}</p>
                            {product.brand && <p className={styles.productBrand}>{product.brand.name}</p>}
                        </div>
                        <button onClick={(e) => removeProduct(e, product.id)} className={styles.removeButton}>
                            ×
                        </button>
                    </div>
                ))}
            </div>
        );
    };



    const renderSearchResults = () => {
        console.log("Rendering search results. searchResults:", searchResults); // Debugging log

        if(searchTerm.length <= 0){
            return "";
        }

        if (!searchResults || searchResults.length === 0) {
            return <p>No results found</p>;
        }

        return (
            <ul className={styles.searchResults}>
                {searchResults.map(product => (
                    <li key={product.id} onClick={() => addProduct(product)} className={product.onMenu ? styles.onMenu:null}>
                        {product.brand ? product.brand.name + " -":''} {product.name}
                    </li>
                ))}
            </ul>
        );
    };

    const renderDealsList = () => (
        <div className={styles.dealsList}>
            {locationDeals.map((deal) => (
                <div key={deal.id} className={styles.dealItem}>
                    <img src={deal.image_url} width="200px" alt={deal.caption} className={styles.dealImage} />
                    <div className={styles.dealInfo}>
                        <h3>{deal.caption}</h3>
                        {deal.deal_terms && <p>{deal.deal_terms}</p>}
                        <div className={styles.dealDates}>
                            <span>Starts: {dayjs(deal.starts).format('MMM D, YYYY HH:mm')}</span>
                            <span>Ends: {dayjs(deal.ends).format('MMM D, YYYY HH:mm')}</span>
                        </div>
                        {deal.menu_url && (
                            <a href={deal.menu_url} target="_blank" rel="noopener noreferrer" className={styles.dealLink}>
                                View Menu
                            </a>
                        )}
                        {deal.deal_url && (
                            <a href={deal.deal_url} target="_blank" rel="noopener noreferrer" className={styles.dealLink}>
                                View Deal
                            </a>
                        )}
                        {deal.urgent && <span className={styles.urgentBadge}>Urgent</span>}
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={styles.container}>
                <nav className={styles.nav}>
                    <button
                        className={view === 'post' ? styles.activeNavButton : styles.navButton}
                        onClick={() => handleViewChange('post')}
                    >
                        <FaBookOpen size={24}/> {id ? 'Edit' : 'Post'}
                    </button>
                    <button
                        className={view === 'saved' ? styles.activeNavButton : styles.navButton}
                        onClick={() => handleViewChange('saved')}
                    >
                        <BsBookmark size={24}/> Saved
                    </button>
                    <button
                        className={view === 'history' ? styles.activeNavButton : styles.navButton}
                        onClick={() => handleViewChange('history')}
                    >
                        <HiMiniArrowUturnLeft size={24}/> History
                    </button>
                </nav>

                {view === 'post' && (

                    <form className={styles.form}>
                        <div className={styles.formRow}>
                            <div className={styles.formGroup}>
                                <label>Select Location</label>
                                <select
                                    name="dispensary_id"
                                    value={formData.dispensary_id}
                                    onChange={handleInputChange}
                                    disabled={!!business_id} // Disable if business_id is provided
                                >
                                    <option value="">Select a location</option>
                                    {locations.map((location) => (
                                        <option key={location.id} value={location.id}>
                                            {location.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className={styles.formGroup}>
                                <label>Select Category</label>
                                <select name="category_id" value={formData.category_id} onChange={handleInputChange}>
                                    {categories.map((category, index) => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>


                        <div className={styles.formGroup}>
                            <label>Add Deal Terms</label>
                            <textarea name="finePrint" value={formData.finePrint} onChange={handleInputChange} placeholder="Add your deal terms here..."/>
                        </div>

                        <div className={styles.formGroup}>
                            <label>Deal Caption</label>
                            <small>Max 100 Characters*</small>
                            <textarea name="title" value={formData.title} onChange={handleInputChange} placeholder="Enter deal type, brand, product, THC %, and pricing."/>
                        </div>
                        <div className={styles.formGroup}>
                            <label>Add Menu URL</label>
                            <input type="url" name="menuUrl" value={formData.menuUrl} onChange={handleInputChange}/>
                        </div>

                        <div className={styles.formGroup}>
                            <label>Add Deal URL</label>
                            <input type="url" name="link" value={formData.link} onChange={handleInputChange}/>
                        </div>

                        <div className={styles.formRow}>
                            <div className={styles.formGroup}>
                                <label>{getDiscountAmountLabel()}</label>
                                <input
                                    type="number"
                                    name="discountAmount"
                                    value={formData.discountAmount}
                                    onChange={handleInputChange}
                                    placeholder={formData.discountType === 'fixed_price' ? "Enter sale price" : "Enter discount amount"}
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label>Discount Type</label>
                                <select
                                    name="discountType"
                                    value={formData.discountType}
                                    onChange={handleInputChange}
                                >
                                    <option value="percent_off">Percent Off</option>
                                    <option value="dollar_off">Dollar Off</option>
                                    <option value="fixed_price">Fixed Price</option>
                                </select>
                            </div>
                        </div>


                        <div className={styles.formGroup}>
                            <label>Add Products To Deal</label>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleProductSearch}
                                placeholder="Search for products..."
                            />
                            {searchLoading && (<div>Loading Products...</div>)}
                            {renderSearchResults()}
                        </div>

                        {formData.products && formData.products.length > 0 && (
                            <div className={styles.formGroup}>
                                <label>Selected Products</label>
                                {renderProductGrid()}
                            </div>
                        )}

                        <div
                            className={styles.selectedImage}
                            style={{
                                backgroundImage: imagePreview ? `url(${imagePreview})` : formData.imageUrl ? `url(${formData.imageUrl})` : 'none',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                width: '100%',
                                marginBottom: '0px'
                            }}
                            onClick={() => document.getElementById('fileInput').click()}
                        >
                            {!imagePreview && !formData.imageUrl && <PiImageFill />}
                        </div>


                        <div className={styles.actionButtons}>
                            <ProfileIconSquare
                                label={"Upload"}
                                customLabelClass={styles.actionButtonText}
                                onClick={() => document.getElementById('fileInput').click()}
                            >
                                <LuArrowUpToLine size={24}/>
                            </ProfileIconSquare>
                            <input
                                id="fileInput"
                                type="file"
                                onChange={handleFileUpload}
                                style={{ display: 'none' }}
                                accept="image/*"
                            />
                            <ProfileIconSquare
                                label={"Take Photo"}
                                customLabelClass={styles.actionButtonText}
                                onClick={() => document.getElementById('cameraInput').click()}
                            >
                                <MdOutlineCameraAlt size={24}/>
                            </ProfileIconSquare>
                            <input
                                id="cameraInput"
                                type="file"
                                onChange={handleCameraCapture}
                                style={{ display: 'none' }}
                                accept="image/*"
                                capture="environment"
                            />
                            <div className={styles.dateTimePickerContainer}>
                                <ProfileIconSquare
                                    label={"Schedule"}
                                    customLabelClass={styles.actionButtonText}
                                    onClick={() => setIsStartsPickerOpen(true)}
                                >
                                    <PiCalendarPlusBold size={24}/>
                                </ProfileIconSquare>
                                <MobileDateTimePicker
                                    value={formData.starts}
                                    onChange={(date) => handleDateTimeChange(date, 'starts')}
                                    onClose={() => setIsStartsPickerOpen(false)}
                                    open={isStartsPickerOpen}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            fullWidth
                                            onClick={() => setIsStartsPickerOpen(true)}
                                        />
                                    )}
                                    label="Deal Starts"
                                />
                            </div>
                            <div className={styles.dateTimePickerContainer}>
                                <ProfileIconSquare
                                    label={"Expires"}
                                    customLabelClass={styles.actionButtonText}
                                    onClick={() => setIsEndsPickerOpen(true)}
                                >
                                    <TbClockHour4 size={24}/>
                                </ProfileIconSquare>
                                <MobileDateTimePicker
                                    value={formData.ends}
                                    onChange={(date) => handleDateTimeChange(date, 'ends')}
                                    onClose={() => setIsEndsPickerOpen(false)}
                                    open={isEndsPickerOpen}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            fullWidth
                                            onClick={() => setIsEndsPickerOpen(true)}
                                        />
                                    )}
                                    label="Deal Ends"
                                />
                            </div>

                            <ProfileIconSquare
                                label={"Deal Urgency"}
                                customBackground={formData.urgency ? "#775496" : ''}
                                customLabelClass={formData.urgency ? styles.actionButtonTextActive : styles.actionButtonText}
                                onClick={toggleUrgency}
                                style={{ backgroundColor: formData.urgency ? '#775496' : 'inherit' }}
                            >
                                <RiTimerFlashLine size={24}/>
                            </ProfileIconSquare>


                            <ProfileIconSquare
                                label={"Save Draft"}
                                customLabelClass={styles.actionButtonText}
                                onClick={saveDraft}
                            >
                                <HiOutlinePencilAlt size={24}/>
                            </ProfileIconSquare>
                        </div>
                    </form>
                )}

                {view === 'history' && renderDealsList()}

                {view === 'post' && (
                    <footer className={styles.footer}>
                        <button className={styles.saveButton} onClick={saveDraft}>
                            <BsBookmark size={24}/> Save for Later
                        </button>
                        <button className={styles.postButton} onClick={() => handleSubmit()}>
                            <FaBookOpen size={24}/> {id ? 'Update' : 'Post Now'}
                        </button>
                        <div className={styles.deleteRow}>
                            {id && ( // Only show delete button if editing an existing deal
                                <button className={styles.deleteButton} onClick={handleDeleteDeal}>
                                    Delete Deal
                                </button>
                            )}
                        </div>

                    </footer>
                )}
            </div>
        </LocalizationProvider>
    );
};

export default DealPostForm;
