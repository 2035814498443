import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './MapComponent.module.css';
import FlowerMapMarker from "../../assets/map-markers/flower-marker.svg";
import './map.css';
import constants from "../../utils/constants";
import { useTheme } from '../ThemeContext/ThemeContext';
import {useNavigate} from "react-router-dom";


const BusinessPopup = ({ business, isDarkMode, onBusinessClick }) => {
    const handleClick = (e) => {
        e.preventDefault();
        if (onBusinessClick) {
            onBusinessClick(business);
        } else {
            window.location.href = `/business/${business.id}`;
        }
    };

    return (
        <div className={`map-box-wrap ${isDarkMode ? 'dark' : ''}`}>
            <button onClick={handleClick} className="w-full text-left">
                <div className="map-logo-wrap">
                    <img src={business.logoImage} alt={business.name} />
                </div>
                <div className="map-info-wrap">
                    <h3>{business.name}</h3>
                    <p>{business.address1}</p>
                    <div className="flex items-center">
                        <StarRating rating={business.starRating} maxRating={5} size={15} />
                        <span className="ml-2">{business.starRating}/5</span>
                    </div>
                </div>
            </button>
        </div>
    );
};

const StarRating = ({ rating, maxRating = 5, size = 15 }) => {
    const fillColor = '#98fb98';
    const backgroundColor = '#E5E7EB';

    return (
        <div className="flex">
            {[...Array(maxRating)].map((_, index) => {
                const fillPercentage = Math.max(0, Math.min(100, (rating - index) * 100));
                return (
                    <svg
                        key={index}
                        width={size}
                        height={size}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <defs>
                            <linearGradient id={`starGradient-${index}-${fillPercentage}`}>
                                <stop offset={`${fillPercentage}%`} stopColor={fillColor} />
                                <stop offset={`${fillPercentage}%`} stopColor={backgroundColor} />
                            </linearGradient>
                        </defs>
                        <path
                            d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                            fill={`url(#starGradient-${index}-${fillPercentage})`}
                        />
                    </svg>
                );
            })}
        </div>
    );
};

const MapComponent = React.forwardRef(({ businesses, mapMarker, children, customClass, geoposition, customZoom, customHeight, onLoad, onBusinessClick }, ref) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const markersRef = useRef([]);
    const rootsRef = useRef([]);
    const popupsRef = useRef([]);
    const userLocationRef = useRef(null);
    const { isDarkMode } = useTheme();
    const [mapLoaded, setMapLoaded] = useState(false);

    useEffect(() => {
        // When the map is initialized
        if (map) {
            if (onLoad) {
                console.log('MAPBOX LOADED');
                console.log(map);
                onLoad(map);
            }
        }
    }, [map, onLoad]);

    // Initialize MapBox
    useEffect(() => {
        if (map.current) return;

        mapboxgl.accessToken = constants.MAPBOX_ACCESS_TOKEN;

        const mapInstance = new mapboxgl.Map({
                                                 container: mapContainer.current,
                                                 style: isDarkMode ? 'mapbox://styles/mapbox/dark-v11' : 'mapbox://styles/mapbox/light-v11',
                                                 center: geoposition ? [geoposition.longitude, geoposition.latitude] : [0, 0],
                                                 zoom: geoposition ? 15 : 2
                                             });

        mapInstance.on('load', () => {
            setMapLoaded(true);
        });

        // Disable map rotation
        mapInstance.dragRotate.disable();
        mapInstance.touchZoomRotate.disableRotation();

        map.current = mapInstance;


        return () => {
            markersRef.current.forEach(marker => marker.remove());
            if (userLocationRef.current) {
                userLocationRef.current.remove();
            }
            map.current.remove();
        };
    }, [isDarkMode, geoposition]);

    useEffect(() => {
        if (!map.current || !mapLoaded) {
            console.log('Map not ready for zoom update');
            return;
        }


        const doZoom = () => {
            try {
                if (businesses?.length > 0) {

                    const bounds = new mapboxgl.LngLatBounds();

                    businesses.forEach(business => {
                        bounds.extend([
                                          business.lon ?? business.longitude,
                                          business.lat ?? business.latitude
                                      ]);
                    });

                    if (geoposition) {
                        bounds.extend([geoposition.longitude, geoposition.latitude]);
                    }

                    if (!bounds.isEmpty()) {

                        map.current.fitBounds(bounds, {
                            padding: { top: 36, bottom: 36, left: 36, right: 36 },
                            maxZoom: customZoom,
                            duration: 1000
                        });
                    }
                } else if (geoposition) {

                    map.current.flyTo({
                                          center: [geoposition.longitude, geoposition.latitude],
                                          zoom: customZoom,
                                          duration: 1000
                                      });
                } else {

                    map.current.easeTo({
                                           zoom: customZoom,
                                           duration: 1000
                                       });
                }
            } catch (error) {
                console.error('Error during zoom:', error);
            }
        };

        // Execute zoom after a short delay to ensure map is ready
        setTimeout(doZoom, 100);

        // Log final zoom after animation completes
        setTimeout(() => {
            if (map.current) {

            }
        }, 1200);

    }, [customZoom, mapLoaded, businesses, geoposition]);

    // Handle user location
    useEffect(() => {
        if (!map.current || !geoposition) return;
        if(onLoad){
            onLoad(map.current);
        }
        // Wait for both map style and source to be loaded
        const initializeUserLocation = () => {
            if (userLocationRef.current) {
                userLocationRef.current.remove();
            }

            const el = document.createElement('div');
            el.className = 'user-location-marker';

            const dot = document.createElement('div');
            dot.className = 'user-location-dot';
            el.appendChild(dot);

            const ring = document.createElement('div');
            ring.className = 'user-location-ring';
            el.appendChild(ring);

            // Create the marker with offset
            userLocationRef.current = new mapboxgl.Marker({
                                                              element: el,
                                                              anchor: 'center'  // This ensures the marker is centered on the location
                                                          })
                .setLngLat([geoposition.longitude, geoposition.latitude])


            userLocationRef.current = new mapboxgl.Marker(el)
                .setLngLat([geoposition.longitude, geoposition.latitude])
                .setPopup(
                    new mapboxgl.Popup({ offset: 25 })
                        .setHTML('<strong>📍 Your Location</strong>')
                )
                .addTo(map.current);

            if (geoposition.accuracy) {
                const accuracyCircleId = 'user-location-accuracy';

                // Remove existing accuracy circle if it exists
                if (map.current.getSource(accuracyCircleId)) {
                    map.current.removeLayer(accuracyCircleId);
                    map.current.removeSource(accuracyCircleId);
                }

                // Only add the accuracy circle if the map is fully loaded
                if (map.current.isStyleLoaded()) {
                    map.current.addSource(accuracyCircleId, {
                        type: 'geojson',
                        data: {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [geoposition.longitude, geoposition.latitude]
                            }
                        }
                    });

                    map.current.addLayer({
                                             id: accuracyCircleId,
                                             type: 'circle',
                                             source: accuracyCircleId,
                                             paint: {
                                                 'circle-radius': geoposition.accuracy,
                                                 'circle-color': '#FF4444',
                                                 'circle-opacity': 0.1,
                                                 'circle-stroke-width': 2,
                                                 'circle-stroke-color': '#FF4444'
                                             }
                                         });
                }
            }
        };

        // If the map style is already loaded, initialize immediately
        if (map.current.isStyleLoaded()) {
            initializeUserLocation();
        } else {
            // Otherwise wait for the style to load
            map.current.once('style.load', initializeUserLocation);
        }
    }, [geoposition, mapLoaded]);

    // Handle businesses
    useEffect(() => {
        if (!mapLoaded || !map.current || !businesses.length) return;

        // Clear existing markers and popups
        markersRef.current.forEach(marker => marker.remove());
        markersRef.current = [];

        const bounds = new mapboxgl.LngLatBounds();

        businesses.forEach(business => {
            const lngLat = [
                business.lon ?? business.longitude,
                business.lat ?? business.latitude
            ];

            // Create marker element
            const el = document.createElement('div');
            el.className = 'custom-marker';

            const markerUrl = mapMarker || FlowerMapMarker;
            el.style.backgroundImage = `url(${markerUrl})`;
            el.style.width = '35px';
            el.style.height = '41px';
            el.style.backgroundSize = 'contain';
            el.style.backgroundRepeat = 'no-repeat';
            el.style.backgroundPosition = 'center';

            // Create popup content with React
            const popupNode = document.createElement('div');
            const root = createRoot(popupNode);
            rootsRef.current.push(root);
            root.render(
                <BusinessPopup
                    business={business}
                    isDarkMode={isDarkMode}
                    onBusinessClick={onBusinessClick}
                />
            );

            // Create popup
            const popup = new mapboxgl.Popup({
                                                 offset: [0, -20],
                                                 closeButton: false,
                                                 maxWidth: '300px',
                                                 className: isDarkMode ? 'dark' : ''
                                             }).setDOMContent(popupNode);

            // Create and add marker
            const marker = new mapboxgl.Marker({
                                                   element: el,
                                                   anchor: 'bottom'
                                               })
                .setLngLat(lngLat)
                .setPopup(popup)
                .addTo(map.current);

            markersRef.current.push(marker);
            bounds.extend(lngLat);
        });

        // Add user location to bounds if available
        if (geoposition) {
            bounds.extend([geoposition.longitude, geoposition.latitude]);
        }

        // Fit bounds with padding if we have any points
        if (!bounds.isEmpty()) {
            map.current.fitBounds(bounds, {
                padding: { top: 36, bottom: 36, left: 36, right: 36 },
                maxZoom: customZoom ?? 13
            });
        } else if (geoposition) {
            map.current.flyTo({
                                  center: [geoposition.longitude, geoposition.latitude],
                                  zoom: customZoom ?? 10
                              });
        }
    }, [mapLoaded, businesses, mapMarker, isDarkMode, geoposition, customZoom]);

    return (
        <div className={customClass ?? styles.MapContainer}>
            <div
                ref={mapContainer}
                className={customClass ?? styles.MapContainer}
                style={{ height: customHeight ?? 400, width: '100%' }}
            />
            {children}
        </div>
    );
});

export default MapComponent;
