import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Virtuoso} from 'react-virtuoso';
import {useNavVisibility} from '../NavVisibilityContext/NavVisibilityContext';
import ProductLoader from "../ProductList/ProductLoader/ProductLoader";
import styles from './ProductListVirtual.module.css';
import useApi from "../../utils/useApi";
import ProductItem from "../ProductItem/ProductItem";

const ProductListVirtual = ({
                                pinned,
                                businessId,
                                products,
                                category,
                                categoryName,
                                customStyle,
                                customClassName,
                                loadMore,
                                hasMore,
                                isLoading,
                                page,
                                noDeals,
                                showTour,
                                setShowTour,
                                selectedWeight,
                                selectedWeightUnit,
                                priceFrom,
                                priceTo
                            }) => {
    const { setIsNavVisible } = useNavVisibility();
    const { trackProductView } = useApi();
    const lastScrollTopRef = useRef(0);

    const handleScroll = useCallback(() => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        // Detect scroll direction using the ref
        if (scrollTop > lastScrollTopRef.current && scrollTop != 0) {
            // Scrolling down
            setIsNavVisible(false);
        } else {
            // Scrolling up
            setIsNavVisible(true);
        }

        // Update the ref with the new scroll position
        lastScrollTopRef.current = scrollTop <= 0 ? 0 : scrollTop;

    }, [setIsNavVisible]);

    // Attach scroll event listener
    useEffect(() => {
        setIsNavVisible(true);
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div style={{ ...customStyle }} className={`${customClassName ? customClassName : ''}`}>
            {noDeals && !isLoading && (
                <div className={styles.noDeals}>
                    <span>No deals available for <span style={{textTransform: 'lowercase'}}>{categoryName}</span> today.</span><br/>
                    {businessId && (
                        <span>Here are some other products from the menu.</span>
                    )}
                    {!businessId && (
                        <span>Here are some other products close by.</span>
                    )}
                </div>
            )}

            <Virtuoso
                id="Virtuoso"
                data={products}
                useWindowScroll
                rangeChanged={({ startIndex, endIndex }) => {
                    // Define a threshold for loading more items
                    const threshold = products.length - 20; // Load more when 20 items from the end

                    if (hasMore && !isLoading && endIndex >= threshold) {
                        loadMore();
                    }
                }}
                itemContent={(index, product) => (
                    <div style={{ margin: '0 auto', maxWidth: '96%' }}>
                        <ProductItem businessId={businessId} category={category} product={product} selectedWeightUnit={selectedWeightUnit} selectedWeight={selectedWeight} priceFrom={priceFrom} priceTo={priceTo}/>
                    </div>
                )}
                endReached={() => {
                    if (hasMore && !isLoading) {
                        loadMore();
                    }
                }}
                components={{
                    Footer: () => isLoading ? (
                        <div className={styles.loading}>
                            <div style={{ marginBottom: '16px' }}>
                                {page === 1 ? "Loading deals..." : "Loading more deals..."}
                            </div>
                            <ProductLoader
                                count={3}
                                customClassName={styles.productLoaderContainer}
                            />
                        </div>
                    ) : null,
                }}
                style={{
                    backgroundColor: 'transparent',
                }}
            />
        </div>
    );
};

export default ProductListVirtual;
