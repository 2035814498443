import React, { useEffect, useState, useCallback } from 'react';
import { Bell, Loader, X } from 'lucide-react';
import useApi from '../../utils/useApi';
import styles from './Notifications.module.css';
import DealBottomSheet from "../DealBottomSheet/DealBottomSheet";
import {useNavigate} from "react-router-dom";
import { useNavCount } from "../../contexts/NavCountProvider";

import {useNavVisibility} from "../NavVisibilityContext/NavVisibilityContext";

const SWIPE_THRESHOLD = 70; // The minimum distance (in px) to reveal the delete button

const NotificationItem = ({ notification, onDeleteSuccess, onMarkSeen }) => {
    const {refreshCounts} = useNavCount();
    const { trackProductView, deleteNotification, markNotificationSeen } = useApi();
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const [translateX, setTranslateX] = useState(0); // Tracks horizontal swipe
    const [startX, setStartX] = useState(0);         // Starting x-coordinate on touch
    const [startY, setStartY] = useState(0);
    const navigate = useNavigate();

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
        setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        const currentX = e.touches[0].clientX;
        const currentY = e.touches[0].clientY;

        const deltaX = currentX - startX;
        const deltaY = currentY - startY;

        // Decide whether the swipe is primarily horizontal or vertical
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
            // Primarily horizontal swipe → prevent vertical scroll & bubble
            e.preventDefault();
            e.stopPropagation();

            // Only move left if user swipes left
            if (deltaX < 0) {
                setTranslateX(deltaX);
            }
        } else {
            // Primarily vertical scroll → do not block
            // (No e.preventDefault, no e.stopPropagation)
        }
    };

    const handleTouchEnd = (e) => {
        // Decide if we keep the item swiped left or snap back
        if (Math.abs(translateX) > SWIPE_THRESHOLD) {
            setTranslateX(-SWIPE_THRESHOLD);
        } else {
            setTranslateX(0);
        }
    };



    const handleNotificationClick = async (e) => {
        // If we’ve already swiped, we might want to reset on second tap, or ignore the click
        if (Math.abs(translateX) >= SWIPE_THRESHOLD) {
            // Option A: Let a second tap restore to default
            setTranslateX(0);
            return;
            // Option B: Ignore the click altogether
            // e.preventDefault();
            // return;
        }

        //e.preventDefault();
        if (notification.product.product_deal && notification.product.product_deal.length > 0) {
            setIsBottomSheetOpen(true);
            try {
                await trackProductView(notification.product.id, notification.dispensary.id);
            } catch (error) {
                console.error("Failed to track product view:", error);
            }
        } else {
            navigate(`/business/${notification.dispensary.id}`);
        }

        // **Mark as seen** (API call)
        try {
            const response = await markNotificationSeen(notification.id);
            // Assuming the API returns something like { success: true } on success
            if (response.success && onMarkSeen) {
                // Tell the parent to update local state
                onMarkSeen(notification.id);
                refreshCounts();
            }
        } catch (error) {
            console.error("Failed to mark notification as seen:", error);
        }

    };

    const closeBottomSheet = () => {
        setIsBottomSheetOpen(false);
    };

    const handleDeleteNotification = async (e) => {
        // Prevent the event from propagating to the parent onClick
        e.stopPropagation();
        try {
            // You mentioned you’ll write the actual delete request;
            // here's a placeholder call to an assumed method:
            await deleteNotification(notification.id);

            // Optionally: inform parent so it can remove from state
            if (onDeleteSuccess) {
                onDeleteSuccess(notification.id);
            }
        } catch (error) {
            console.error('Failed to delete notification:', error);
        }
    };

    const getIcon = () => {
        return (
            <div
                className={styles.avatar}
                style={{
                    backgroundImage: `url(${notification.product.formatted_thumbnail_url})`
                }}
            />
        );
    };

    const getTimeAgo = (date) => {
        const hours = Math.abs(new Date() - new Date(date)) / 36e5;
        if (hours < 1) {
            return "Just Now";
        } else if (hours < 24) {
            return `${Math.round(hours)}h ago`;
        }
        return new Date(date).toLocaleDateString(undefined, {
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    return (
        <div
            className={styles.notificationItemWrapper}
            // The outer wrapper that allows overflow/positioning
        >
            <div className={styles.deleteX} onClick={handleDeleteNotification}>
                <X size={24}/>
            </div>
            <div
                className={styles.deleteButton}
                onClick={handleDeleteNotification}
            >
                <span>Delete</span>
            </div>
            {(notification.product.product_deal && notification.product.product_deal.length > 0) && (
                <DealBottomSheet
                    isOpen={isBottomSheetOpen}
                    onClose={closeBottomSheet}
                    productOrig={notification.product}
                    deal={notification.product.product_deal[0]}
                />
            )}
            <div
                className={`${styles.notificationSwipeContainer} ${!notification.is_seen ? styles.unseen:styles.seen}`}
                style={{ transform: `translateX(${translateX}px)` }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onClick={handleNotificationClick}
            >

                <div className={styles.avatarWrap}>
                    {getIcon()}
                </div>
                <div className={styles.notificationContent}>
                    <div className={styles.notificationText}>
                        <span className={styles.notificationTitle}>{notification.title}</span>{' '}
                        {notification.message}
                    </div>
                    <div className={styles.notificationMeta}>
                        {notification.type && (
                            <span className={styles.notificationTag}>
                                {notification.type.replace('_', ' ')}
                            </span>
                        )}
                        <span className={styles.notificationTime}>
                            {getTimeAgo(notification.created_at)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};


const Notifications = ({ handleShowHeader, handleShowSettingsHeader }) => {
    const {refreshCounts} = useNavCount();
    const { getNotifications, markAllNotificationsSeen } = useApi();
    const [notifications, setNotifications] = useState({});
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const { setIsNavVisible } = useNavVisibility();

    const handleDeleteSuccess = (deletedId) => {
        // Flatten the grouped notifications into an array
        const flattened = Object.entries(notifications).reduce(
            (acc, [date, notifs]) => [...acc, ...notifs],
            []
        );

        // Remove the one that was deleted
        const updatedList = flattened.filter((n) => n.id !== deletedId);

        // Regroup by date
        setNotifications(groupNotificationsByDate(updatedList));
    };

    const handleMarkAllSeen = async () => {
        try {
            const response = await markAllNotificationsSeen();
            // Assuming the API returns something like { success: true } on success
            if (response.success) {
                // Update local state so that all notifications become is_seen: true
                setNotifications(prevNotifications => {
                    // Flatten the grouped notifications into an array
                    const flattened = Object.entries(prevNotifications).reduce(
                        (acc, [date, notifs]) => [...acc, ...notifs],
                        []
                    );
                    // Mark them all as seen
                    const updatedList = flattened.map(notif => ({
                        ...notif,
                        is_seen: true,
                    }));
                    // Regroup notifications by date
                    return groupNotificationsByDate(updatedList);
                });

                // Update any nav or unread counters
                refreshCounts();
            }
        } catch (error) {
            console.error("Failed to mark all notifications as seen:", error);
        }
    };

    const handleMarkSeen = (notificationId) => {
        // Flatten grouped notifications into a single array
        const flattened = Object.entries(notifications).reduce(
            (acc, [date, notifs]) => [...acc, ...notifs],
            []
        );

        // Update the `is_seen` property on the matching notification
        const updatedList = flattened.map((notif) => {
            if (notif.id === notificationId) {
                return { ...notif, is_seen: true };
            }
            return notif;
        });

        // Regroup notifications by date (same function you already have)
        setNotifications(groupNotificationsByDate(updatedList));
    };

    const groupNotificationsByDate = (notificationsList) => {
        const grouped = notificationsList.reduce((acc, notification) => {
            const date = new Date(notification.created_at);
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);

            let dateKey;
            if (date.toDateString() === today.toDateString()) {
                dateKey = 'Today';
            } else if (date.toDateString() === yesterday.toDateString()) {
                dateKey = 'Yesterday';
            } else {
                dateKey = date.toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });
            }

            if (!acc[dateKey]) {
                acc[dateKey] = [];
            }
            acc[dateKey].push(notification);
            return acc;
        }, {});

        return grouped;
    };

    const loadNotifications = useCallback(async () => {
        if (isLoading) return;

        console.log(page);
        try {
            setIsLoading(true);
            setError(null);
            const response = await getNotifications(page);

            if (response.data) {
                setNotifications(prev => {
                    const newNotifications = [...Object.values(prev).flat(), ...response.data];
                    return groupNotificationsByDate(newNotifications);
                });
                setHasMore(response.next_page_url !== null);
            }
        } catch (err) {
            setError('Failed to load notifications. Please try again.');
        } finally {
            setIsLoading(false);
            setInitialLoadComplete(true);
        }
    }, [page, getNotifications]);

    // Initial load
    useEffect(() => {
        loadNotifications();
        setIsNavVisible(false);
    }, []); // Only run on mount

    // Handle header visibility
    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(true);
    }, [handleShowHeader, handleShowSettingsHeader]);

    // Load more when page changes
    useEffect(() => {
        if (initialLoadComplete && page > 1) {
            loadNotifications();
        }
    }, [page, loadNotifications, initialLoadComplete]);

    // Handle scroll
    const handleScroll = useCallback(() => {
        if (!hasMore || isLoading || !initialLoadComplete) return;

        const distanceFromBottom =
            document.documentElement.scrollHeight -
            (window.innerHeight + document.documentElement.scrollTop);

        if (distanceFromBottom <= 1000) {
            setPage(prev => prev + 1);
        }
    }, [hasMore, isLoading, initialLoadComplete]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.headerTop}>
                    <h2 className={styles.title}>Deal Alerts</h2>
                    <button className={styles.markRead} onClick={handleMarkAllSeen}>
                        Mark all as seen
                    </button>
                </div>
            </div>

            <div className={styles.notificationsContainer}>
                {error && <div className={styles.error}>{error}</div>}

                {Object.entries(notifications).map(([date, notifs]) => (
                    <div key={date}>
                        <div className={styles.dateHeader}>
                            {date}
                        </div>
                        <div className={styles.notificationsList}>
                            {notifs.map((notification) => (
                                <NotificationItem
                                    key={notification.id}
                                    notification={notification}
                                    onDeleteSuccess={handleDeleteSuccess}
                                    onMarkSeen={handleMarkSeen}
                                />
                            ))}
                        </div>
                    </div>
                ))}

                {isLoading && (
                    <div className={styles.loader}>
                        <Loader className={styles.loaderIcon} />
                    </div>
                )}

                {!isLoading && Object.keys(notifications).length === 0 && (
                    <div className={styles.emptyState}>
                        <Bell className={styles.emptyIcon} />
                        <p className={styles.emptyText}>No notifications yet</p>
                    </div>
                )}

                {!hasMore && Object.keys(notifications).length > 0 && (
                    <div className={styles.noMore}>No more notifications</div>
                )}
            </div>
        </div>
    );
};

export default Notifications;
