import { useState, useEffect } from 'react';

function useSafariNavBarState() {
    const [isExpanded, setIsExpanded] = useState(false);
    const [initialHeight, setInitialHeight] = useState(window.innerHeight);


    useEffect(() => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        let timeoutId;
        if (!isIOS || !isSafari) return;

        const handleResize = () => {
            // Clear previous timeout
            if (timeoutId) clearTimeout(timeoutId);

            // Set new timeout to avoid rapid firing
            timeoutId = setTimeout(() => {
                const heightDiff = initialHeight - window.innerHeight;
                setIsExpanded(heightDiff >= 45);
            }, 0);
        };

        window.addEventListener('resize', handleResize);

        // Also check on scroll as it can trigger nav bar changes
        window.addEventListener('scroll', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleResize);
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [initialHeight]);

    return isExpanded;
}

export default useSafariNavBarState;
