import React from 'react';

const LocationArrow = ({ className, size = 24, color = "currentColor" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        width={size}
        height={size}
        className={className}
    >
        <path
            fill={color}
            d="M30.531 1.47c-0.136-0.136-0.324-0.219-0.531-0.219-0.107 0-0.208 0.022-0.3 0.062l0.005-0.002-28.001 12c-0.269 0.118-0.454 0.382-0.454 0.689 0 0.377 0.278 0.688 0.639 0.742l0.004 0.001 13.443 1.921 1.921 13.442c0.048 0.333 0.31 0.593 0.64 0.638l0.004 0c0.030 0.004 0.064 0.006 0.099 0.006h0c0 0 0 0 0 0 0.308 0 0.572-0.185 0.687-0.45l0.002-0.005 12-27.999c0.039-0.087 0.061-0.189 0.061-0.296 0-0.207-0.084-0.394-0.219-0.53v0zM18.363 27.246l-1.621-11.353c-0.048-0.33-0.306-0.588-0.632-0.635l-0.004-0-11.352-1.622 23.818-10.208z"></path>
    </svg>
);

export default LocationArrow;
