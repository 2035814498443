import React, {useEffect, useState, useRef, useCallback} from 'react';
import styles from './ProductList.module.css';
import ProductLoader from "./ProductLoader/ProductLoader";
import {useNavVisibility} from '../NavVisibilityContext/NavVisibilityContext';
import ProductItem from "../ProductItem/ProductItem";


// Debounce function to limit how often the scroll handler fires
const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

const ProductList = ({ businessId, products, customStyle, loadMore, hasMore, isLoading, page, noDeals, showTour, setShowTour, selectedWeight, selectedWeightUnit, priceFrom, priceTo }) => {
    const listRef = useRef();
    const loadingRef = useRef(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const { setIsNavVisible } = useNavVisibility();

    const handleScroll = useCallback(() => {
       // console.log('In Handle Scroll Main Nav');
       // console.log('Handling Scroll Main Nav');

        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || window.innerHeight;
        const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 2500;

        // Detect scroll direction
        if (scrollTop > lastScrollTop) {
            // Scrolling down
            setIsNavVisible(false);
        } else {
            // Scrolling up
            setIsNavVisible(true);
        }

        // Update last scroll position
        setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);

        if (scrolledToBottom && page > 1 && !loadingRef.current) {
            loadingRef.current = true;
            loadMore().finally(() => {
                loadingRef.current = false;
            });
        }
    }, [hasMore, loadMore, lastScrollTop, setIsNavVisible]);

    const debouncedHandleScroll = useCallback(debounce(handleScroll, 200), [handleScroll]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <div className={styles.productList} style={customStyle} ref={listRef}>
            {(noDeals && !isLoading) && (
                <div className={styles.noDeals}>
                    <span>No deals available today.</span><br/>
                    {businessId && (
                        <span>Here are some other products from the menu</span>
                    )}
                    {!businessId && (
                        <span>Here are some other products close by</span>
                    )}
                </div>)}
            {products.map((product) => (
                <ProductItem businessId={businessId} key={`${product.id}-${product.brand_id}-${product.dispensary_id}`} product={product} showTour={showTour} setShowTour={setShowTour} selectedWeight={selectedWeight} selectedWeightUnit={selectedWeightUnit} priceFrom={priceFrom} priceTo={priceTo} />
            ))}
            <div className={isLoading ? styles.loading : styles.loadingHide}>
                {page === 1 && "Loading deals..."}
                {page > 1 && "Loading more deals..."}
            </div>
            <ProductLoader count={3} customClassName={isLoading ? styles.productLoader : styles.productLoaderHide}/>
        </div>
    );
};

export default ProductList;
