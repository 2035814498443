import React from 'react';

const NearYouIcon = ({ className, size = 24, color = "currentColor" }) => (
    <svg id="Layer_1"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 635.75 514.47"
         width={size}
         height={size}
         className={className}
    >
        <path
            fill={color}
            d="M430.6,514.47c-6.71-7.25-13.08-13.79-19.07-20.67-15.61-17.95-31.93-35.38-46.35-54.25-19.2-25.12-25.96-53.76-15.43-84.35,11.41-33.16,35.24-53.07,70.14-57.43,49.23-6.16,90.56,27.48,95.8,76.73,2.2,20.64-4.33,39.25-16.28,55.31-16.97,22.8-35.43,44.5-53.34,66.6-4.81,5.93-9.93,11.61-15.47,18.06ZM430.31,425.6c23.32.06,42.23-18.41,42.52-41.53.28-22.53-19.32-42.19-42.09-42.24-22.97-.04-42.02,19.01-42.06,42.05-.03,22.92,18.66,41.65,41.63,41.71Z"/>
        <path
            fill={color}
            d="M303.22,62.6c12.9-1.57,25.44.95,37.88,4.31,10.94,2.95,13.68,6.5,14.37,17.76,1.02,16.61,2.46,33.19,3.23,49.81.22,4.68-.43,9.79-2.17,14.1-5.16,12.8-10.57,25.55-16.85,37.83-4.19,8.2-4.9,16.9-6.16,25.53-4.94,33.78-9.57,67.6-14.21,101.42-.44,3.23-1.58,4.58-5.02,4.48-7.82-.22-15.66-.21-23.49,0-3.2.09-4.28-1.02-4.7-4.14-3.13-22.76-6.43-45.5-9.69-68.25-2.17-15.17-3.43-30.54-6.85-45.42-2.03-8.81-7.43-16.86-11.38-25.22-5.88-12.46-13.23-24.5-12.33-39.04,1.17-18.93,2.42-37.87,4.02-56.77.55-6.52,5.7-9.53,11.39-11.16,13.66-3.93,27.49-6.87,41.96-5.25Z"/>
        <path
            fill={color}
            d="M54.99,411.25c-7.85-8.58-15.61-16.58-22.79-25.06-8.06-9.51-16.34-18.96-23.19-29.33-12.84-19.42-11.68-44.84,1.76-63.45,14.65-20.29,41.09-27.67,65.63-18.32,21.73,8.28,36.33,32.11,33.72,55.86-1.55,14.1-9.06,25.65-17.72,36.34-11.43,14.09-23.28,27.85-34.97,41.74-.52.62-1.2,1.11-2.43,2.22ZM55.17,300.14c-14.97.05-26.89,12.07-26.86,27.1.03,14.97,12.09,26.91,27.13,26.83,14.93-.07,27.05-12.21,27.02-27.07-.03-14.65-12.48-26.92-27.28-26.87Z"/>
        <path
            fill={color}
            d="M121.81,346.6c67.15,23.09,136.88,25.69,206.73,24.81.97,11,1.92,21.68,2.94,33.22-77.71,1.36-154.78-3.09-230-26.38,7.02-10.91,13.61-21.17,20.34-31.64Z"/>
        <path
            fill={color}
            d="M532.52,365.4c-3.71-10-7.23-19.64-10.88-29.24-.76-2-1.91-3.9-3.1-5.69-2.58-3.88-4.7-7.43-3.96-12.77.8-5.76-3.22-10.31-7.48-14-10.38-8.97-23.06-13.2-35.94-16.75-26.95-7.41-54.59-10.43-82.41-11.81-13.63-.68-27.28-.85-40.92-1.28-1.3-.04-2.59-.23-4.84-.45,1.52-3.96,2.68-7.53,4.32-10.87.48-.98,2.33-1.82,3.56-1.81,50.57.11,100.93,2.41,150.01,16.11,16.58,4.63,32.61,10.8,46.17,21.91,8.04,6.59,14.31,14.41,16.01,25.1,1.39,8.69-.33,16.5-6.14,23.34-6.91,8.14-15.48,13.87-24.4,18.2Z"/>
        <path
            fill={color}
            d="M591.37,327.5c-13.4-15.42-28.5-28.41-38.11-45.73-9.26-16.68-8.8-33.8,2.79-49.37,11.34-15.23,27.11-20.45,45.57-16.65,28.54,5.88,43.46,39.21,27.82,63.82-8.43,13.26-19.22,25.03-29.02,37.42-2.67,3.37-5.6,6.53-9.04,10.52ZM613.11,259.54c-.05-11.96-9.73-21.63-21.63-21.61-11.87.03-21.68,9.79-21.71,21.6-.03,12.06,9.97,22.03,21.96,21.88,11.88-.15,21.44-9.93,21.38-21.88Z"/>
        <path
            fill={color}
            d="M261.31,274.76c-47.65,1.31-93.99,7.44-139.29,20.76-.46-.61-.94-1.09-1.24-1.66-2.53-4.81-5.03-9.64-8-15.36,12.66-2.99,24.85-6.23,37.19-8.72,32.58-6.55,65.54-9.36,98.75-9.55,8.3-.05,8.32-.14,10.79,7.84.62,2.01,1.1,4.07,1.8,6.69Z"/>
        <path
            fill={color}
            d="M329.6,27.03c.08,15.19-11.91,27.27-27.18,27.37-15.01.1-27.08-11.79-27.16-26.76-.08-15.57,11.45-27.46,26.78-27.65,15.16-.18,27.48,11.9,27.56,27.03Z"/>
        <path
            fill={color}
            d="M417.54,248.41c-6.44-7.53-12.75-14.17-18.19-21.46-6.62-8.87-6.26-21.14-.01-28.87,6.63-8.2,17.78-11.02,27.5-6.95,11.35,4.75,19.67,20.19,8.71,35.15-5.39,7.36-11.5,14.18-18.01,22.13ZM417.65,201.56c-6.25-.03-11.19,4.76-11.32,10.98-.13,6.21,5.16,11.47,11.47,11.38,6.06-.09,11.15-5.28,11.06-11.28-.09-6.22-4.96-11.04-11.21-11.07Z"/>
        <path
            fill={color}
            d="M192.75,248.91c-7.14-8.69-13.97-16.02-19.61-24.17-5.37-7.76-4.3-19.55,1.07-26.36,6.05-7.69,15.75-10.86,25.06-8.19,9.11,2.61,15.64,10.17,16.14,20.04.2,3.96-.47,8.73-2.58,11.9-5.94,8.94-12.83,17.25-20.09,26.78ZM204.03,212.55c-.07-6.1-5.02-10.94-11.24-10.97-6.38-.04-11.56,5.07-11.46,11.3.09,5.89,5.33,11.02,11.29,11.05,6.24.03,11.47-5.19,11.4-11.37Z"/>
    </svg>
);

export default NearYouIcon;
