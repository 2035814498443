import React, { useState, useEffect, useRef } from 'react';
import { Search, X, MapPin } from 'lucide-react';
import { Loader } from '@googlemaps/js-api-loader';
import styles from './LocationAutocompleteBottomSheet.module.css';
import constants from "../../utils/constants";

const LocationAutocompleteBottomSheet = ({
                                             isOpen,
                                             onClose,
                                             onLocationSelect,
                                             locationLoading
                                         }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [predictions, setPredictions] = useState([]);
    const inputRef = useRef(null);
    const placesServiceRef = useRef(null);
    const [googleLoaded, setGoogleLoaded] = useState(false);
    const loaderRef = useRef(null);

    const closeBottomSheet = () => {
        onClose();
    }

    // Load Google Maps API
    useEffect(() => {
        if (loaderRef.current) return;

        console.log('Starting Google Maps loader');
        loaderRef.current = new Loader({
                                           apiKey: constants.GOOGLE_MAPS_API_KEY,
                                           version: "weekly",
                                           libraries: ["places"]
                                       });

        const loadMaps = async () => {
            try {
                await loaderRef.current.load();
                console.log('Google Maps loaded successfully');
                setGoogleLoaded(true);
            } catch (error) {
                console.error('Error loading Google Places:', error);
            }
        };

        loadMaps();

        return () => {
            loaderRef.current = null;
        };
    }, []);

    // Initialize Places service
    useEffect(() => {
        if (!googleLoaded || !window.google?.maps?.places) return;

        // Create a dummy div for the PlacesService (required)
        const dummyElement = document.createElement('div');
        placesServiceRef.current = new window.google.maps.places.PlacesService(dummyElement);

        return () => {
            placesServiceRef.current = null;
        };
    }, [googleLoaded]);

    // Handle search
    const searchPlaces = (input) => {
        if (!input || input.length < 2 || !window.google?.maps?.places) return;

        const request = {
            input: input,
            types: ['(cities)'],
            componentRestrictions: { country: ['us', 'ca'] }
        };

        const displaySuggestions = (predictions, status) => {
            console.log('Predictions received:', predictions);
            if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                setPredictions(predictions);
            }
        };

        const service = new window.google.maps.places.AutocompleteService();
        service.getPlacePredictions(request, displaySuggestions);
    };

    // Debounce search
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (searchTerm) {
                searchPlaces(searchTerm);
            } else {
                setPredictions([]);
            }
        }, 300);

        return () => clearTimeout(timeoutId);
    }, [searchTerm]);

    const handleSelectPlace = (prediction) => {
        if (!placesServiceRef.current) return;

        const request = {
            placeId: prediction.place_id,
            fields: ['address_components', 'geometry']
        };

        placesServiceRef.current.getDetails(request, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
                const cityComponent = place.address_components.find(
                    comp => comp.types.includes('locality')
                );
                const stateComponent = place.address_components.find(
                    comp => comp.types.includes('administrative_area_level_1')
                );

                if (cityComponent && stateComponent) {
                    const locationData = {
                        city: cityComponent.long_name,
                        state: stateComponent.long_name,
                        state_abbr: stateComponent.short_name,
                        latitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng(),
                        display: `${cityComponent.long_name}, ${stateComponent.short_name}`
                    };

                    onLocationSelect(locationData);
                    setSearchTerm('');
                    setPredictions([]);
                    onClose();
                }
            }
        });
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
    };

    const handleClear = () => {
        setSearchTerm('');
        setPredictions([]);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    // Handle clicks on the bottom sheet content
    const handleContentClick = (e) => {
        // Prevent the click from bubbling up to the overlay
        e.stopPropagation();
    };

    return (
        <div className={`${styles.bottomSheetOverlay} ${isOpen ? styles.visible : ''}`} onClick={closeBottomSheet}>
            <div className={`${styles.bottomSheet} ${isOpen ? styles.open : ''}`} onClick={handleContentClick}>
                <div className={styles.bottomSheetContent}>
                    <h2 className={styles.title}>Change Location</h2>

                    <div className={styles.searchInputWrapper}>
                        <Search className={styles.searchIcon} />
                        <input
                            id='city-search-input'
                            ref={inputRef}
                            type="text"
                            value={searchTerm}
                            onChange={handleInputChange}
                            placeholder="Search for a city..."
                            className={styles.searchInput}
                            autoComplete="off"
                        />
                        {searchTerm && (
                            <button
                                className={styles.clearButton}
                                onClick={handleClear}
                                type="button"
                                aria-label="Clear search"
                            >
                                <X className="h-4 w-4" />
                            </button>
                        )}
                    </div>

                    {predictions.length > 0 && (
                        <div className={styles.predictionsContainer}>
                            {predictions.map((prediction) => (
                                <button
                                    key={prediction.place_id}
                                    className={styles.predictionItem}
                                    onClick={() => handleSelectPlace(prediction)}
                                    type="button"
                                >
                                    <div className={styles.predictionContent}>
                                        <MapPin className={styles.locationIcon} />
                                        <div className={styles.predictionText}>
                                            <span className={styles.mainText}>
                                                {prediction.structured_formatting?.main_text}
                                            </span>
                                            <span className={styles.secondaryText}>
                                                {prediction.structured_formatting?.secondary_text}
                                            </span>
                                        </div>
                                    </div>
                                </button>
                            ))}
                        </div>
                    )}

                    <button
                        onClick={onClose}
                        className={styles.cancelButton}
                        type="button"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LocationAutocompleteBottomSheet;
