import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Mail, Lock, ArrowLeft } from 'lucide-react';
import styles from './LoginBottomSheet.module.css';
import { useTheme } from "../../ThemeContext/ThemeContext";
import logo from "../../../assets/logo2.svg";
import logoLight from "../../../assets/logo.svg";
import authApi from "../../../utils/auth.api";

const LoginBottomSheet = ({ isOpen, onClose, onLogin, onPasskeyLogin }) => {
    const [closing, setClosing] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [currentView, setCurrentView] = useState('initial'); // initial, email, password, setup
    const [isLoading, setIsLoading] = useState(false);
    const [subTitle, setSubTitle] = useState('');
    const [error, setError] = useState('');
    const { isDarkMode } = useTheme();
    const logoSrc = isDarkMode ? logo : logoLight;

    useEffect(() => {
        console.log('Is Open', isOpen);
    }, [isOpen]);

    const checkAccountAndInitiateAuth = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const { exists, userId, hasPasskey } = await authApi.checkAccount(email);

            if (exists) {
                if (hasPasskey) {
                    const result = await authApi.authenticateWithPasskey(email, userId);
                    if (result?.token) {
                        localStorage.setItem('user', JSON.stringify(result));
                        onPasskeyLogin(result);
                        onClose();
                    }
                } else {
                    setCurrentView('password');
                }
            } else {
                setCurrentView('setup');
            }
        } catch (error) {
            console.error('Account check failed:', error);
            setError('Unable to check account. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePasswordLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await onLogin(email, password);
            console.log(response);
            if(response.success) {
                setClosing(true);

                setTimeout(() => {
                    setClosing(false);
                    setEmail('');
                    setPassword('');
                    setCurrentView('initial');
                    onClose();
                }, 300);

            }
        } catch (error) {
            console.error('Login failed:', error);
            setError('Invalid email or password.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreateAccount = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            // First check if WebAuthn is available
            if (!window.PublicKeyCredential) {
                throw new Error('WebAuthn is not supported in this browser');
            }

            // Explicitly check platform authenticator availability
            const available = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
            if (!available) {
                throw new Error('Your device does not support biometric/PIN authentication');
            }

            setSubTitle('Please follow your device\'s prompts to set up biometric login');

            // Add a small delay to ensure the UI updates before proceeding
            await new Promise(resolve => setTimeout(resolve, 100));

            const result = await authApi.createAccountWithPasskey(email);

            if (!result?.token) {
                throw new Error('Account creation failed: Invalid server response');
            }

            // Add a small delay before closing to ensure the passkey is saved
            await new Promise(resolve => setTimeout(resolve, 500));

            onPasskeyLogin(result);
            onClose();
        } catch (error) {
            console.error('Account creation failed:', error);

            if (error.name === 'NotAllowedError') {
                setError('Permission denied. Please try again and follow the prompts.');
            } else if (error.name === 'SecurityError') {
                setError('Security error. Please ensure you\'re using HTTPS.');
            } else {
                setError(error.message || 'Unable to create account. Please try again.');
            }
        } finally {
            setIsLoading(false);
            setSubTitle('We\'ll create an account for ' + email);
        }
    };

    const handleBack = () => {
        setError('');
        if (currentView === 'password') {
            setCurrentView('email');
            setPassword('');
        } else if (currentView === 'email') {
            setCurrentView('initial');
            setEmail('');
        } else if (currentView === 'setup') {
            setCurrentView('email');
        }
    };

    const handleClose = (e) => {
        e.preventDefault();
        setCurrentView('initial');
        setEmail('');
        setPassword('');
        setError('');
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div
            className={styles.overlay}
            onClick={(e) => {
                if (e.target.className === styles.overlay) {
                    handleClose(e);
                }
            }}
        >
            <div
                className={`${styles.bottomSheet} ${isOpen && !closing ? styles.bottomSheetOpen : ''} ${closing ? styles.bottomSheetClosing:''}  ${isDarkMode ? styles.dark : ''}`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={styles.handle}>
                    <div className={styles.handleBar}></div>
                </div>

                {error && (
                    <div className={styles.error}>
                        {error}
                    </div>
                )}

                {/* Initial View */}
                {currentView === 'initial' && (
                    <>
                        <div className={styles.logoWrapper}>
                            <img src={logoSrc} alt="Logo" className={styles.logo} />
                        </div>
                        <div className={styles.backgroundImage}></div>

                        <h2 className={styles.title}>
                            Sign in or create an account for free to shop weed deals near you.
                        </h2>
                        <h4 className={styles.subTitle}>
                            Receive deal alerts, save deals, access personal deal preferences, and more!
                        </h4>

                        <button
                            type="button"
                            className={styles.submitButton}
                            onClick={() => setCurrentView('email')}
                        >
                            <Mail size={24} /> Continue with email
                        </button>
                    </>
                )}

                {/* Email Input View */}
                {currentView === 'email' && (
                    <>
                        <div className={styles.header}>
                            <button onClick={handleBack} className={styles.backButton}>
                                <ArrowLeft size={24} />
                            </button>
                            <div className={`${styles.logoWrapper} ${currentView !== 'initial' ? styles.logoBackButton:''}`}>
                                <img src={logoSrc} alt="Logo" className={styles.logo} />
                            </div>
                        </div>

                        <h2 className={styles.title}>
                            What's your email?
                        </h2>
                        <h4 className={styles.subTitle}>
                            Enter your email to continue
                        </h4>

                        <form onSubmit={checkAccountAndInitiateAuth} className={styles.form}>
                            <div className={styles.inputGroup}>
                                <Mail className={styles.icon} size={20} />
                                <input
                                    className={styles.input}
                                    type="email"
                                    value={email}
                                    placeholder="Email Address"
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className={styles.backgroundWeedGuy}></div>

                            <button
                                type="submit"
                                className={styles.submitButton}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Please wait...' : 'Continue'}
                            </button>
                        </form>
                    </>
                )}

                {/* Password Input View */}
                {currentView === 'password' && (
                    <>
                        <div className={styles.header}>
                            <button onClick={handleBack} className={styles.backButton}>
                                <ArrowLeft size={24} />
                            </button>
                            <div className={`${styles.logoWrapper} ${currentView !== 'initial' ? styles.logoBackButton:''}`}>
                                <img src={logoSrc} alt="Logo" className={styles.logo} />
                            </div>
                        </div>

                        <h2 className={styles.title}>
                            Enter your password
                        </h2>
                        <h4 className={styles.subTitle}>
                            Please enter your password for {email}
                        </h4>

                        <form onSubmit={handlePasswordLogin} className={styles.form}>
                            <div className={styles.inputGroup}>
                                <Lock className={styles.icon} size={20} />
                                <input
                                    className={styles.input}
                                    type="password"
                                    value={password}
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div className={styles.backgroundWeedGuyDone}></div>

                            <button
                                type="submit"
                                className={styles.submitButton}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Please wait...' : 'Sign in'}
                            </button>
                        </form>
                    </>
                )}

                {/* Account Setup View */}
                {currentView === 'setup' && (
                    <>
                        <div className={styles.header}>
                            <button onClick={handleBack} className={styles.backButton}>
                                <ArrowLeft size={24} />
                            </button>
                            <div className={`${styles.logoWrapper} ${currentView !== 'initial' ? styles.logoBackButton:''}`}>
                                <img src={logoSrc} alt="Logo" className={styles.logo} />
                            </div>
                        </div>
                        <div className={styles.backgroundWeedGuyDone}></div>

                        <h2 className={styles.title}>
                            Let's get you setup.
                        </h2>
                        <h4 className={styles.subTitle}>
                            {subTitle ? (
                                subTitle
                            ):(
                                <>
                                    We'll create an account for {email}
                                </>
                            )}
                        </h4>

                        <button
                            type="button"
                            className={styles.submitButton}
                            onClick={handleCreateAccount}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                'Please wait...'
                            ) : (
                                 <>
                                     <Mail size={24} /> Create Account
                                 </>
                             )}
                        </button>

                        <p className={styles.termsText}>
                            By signing up, you accept our Terms of Use and Privacy Policy
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};

export default LoginBottomSheet;
