import React from 'react';
import {useTheme} from "../../ThemeContext/ThemeContext";

const AnimatedSearchIndicator = ({customClass}) =>{
    const { isDarkMode } = useTheme();  // Use the useTheme hook

    const renderPurpleIndicator = () => (
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#775496', stopOpacity: 1 }} />
            <stop offset="33.33%" style={{ stopColor: '#9370DB', stopOpacity: 1 }} />
            <stop offset="66.66%" style={{ stopColor: '#BA55D3', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#775496', stopOpacity: 1 }} />
        </linearGradient>
    );

    const renderGreenIndicator = () => (
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#98fb98', stopOpacity: 1 }} />
            <stop offset="33.33%" style={{ stopColor: '#30e122', stopOpacity: 1 }} />
            <stop offset="66.66%" style={{ stopColor: '#00c603', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#98fb98', stopOpacity: 1 }} />
        </linearGradient>
    );

    return (
        <svg className={customClass ? customClass : 'searchIndicator'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="24" height="24">
            <defs>
                {isDarkMode ? renderPurpleIndicator() : renderGreenIndicator()}
            </defs>

            <circle
                cx="50"
                cy="50"
                r="36"
                fill="none"
                stroke="url(#gradient)"
                strokeWidth="18"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 50 50"
                    to="360 50 50"
                    dur="2s"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    );
}

export default AnimatedSearchIndicator;
