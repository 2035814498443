import React, {useEffect, useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import useApi from '../../utils/useApi';
import styles from './UserSelectFavoriteProducts.module.css';
import logo from '../../assets/logo2.svg';
import {useTheme} from "../ThemeContext/ThemeContext";
import {useNavVisibility} from "../NavVisibilityContext/NavVisibilityContext";
import {ArrowLeft} from 'lucide-react';
import {ReactComponent as PrerollIcon} from "../../assets/preroll.svg";
import {ReactComponent as FlowerIcon} from "../../assets/flower.svg";
import {ReactComponent as CartridgeIcon} from "../../assets/cartridge.svg";
import {ReactComponent as TopicalIcon} from "../../assets/topical.svg";
import {ReactComponent as EdibleIcon} from "../../assets/edible.svg";
import {ReactComponent as AccessoryIcon} from "../../assets/accessory.svg";
import {ReactComponent as WaxIcon} from "../../assets/wax.svg";
import {ReactComponent as HybridIcon} from "../../assets/hybrid.svg";
import {ReactComponent as SativaIcon} from "../../assets/sativa.svg";
import {ReactComponent as IndicaIcon} from "../../assets/indica-white.svg";
import {ReactComponent as TinctureIcon} from "../../assets/tincture.svg";

const productCategories = [
    { id: 2, name: 'Flower', icon: FlowerIcon, type: 'category' },
    { id: 402, name: 'Pre-Rolls', icon: PrerollIcon, type: 'category' },
    { id: 193, name: 'Vapes', icon: CartridgeIcon, type: 'category' },
    { id: 371, name: 'Edibles', icon: EdibleIcon, type: 'category' },
    { id: 361, name: 'Concentrates', icon: WaxIcon, type: 'category' },
    { id: 424, name: 'Topicals', icon: TopicalIcon, type: 'category' },
    { id: 'tinctures', name: 'Tinctures', icon: TinctureIcon, type: 'category', customHeight: {height: '60px'} },
    { id: 1, name: 'Sativa', icon: SativaIcon, type: 'strainType', customHeight: {height: '60px'} },
    { id: 2, name: 'Indica', icon: IndicaIcon, type: 'strainType', customHeight: {height: '60px'}},
    { id: 3, name: 'Hybrid', icon: HybridIcon, type: 'strainType', customHeight: {height: '60px'}}
];

const UserSelectFavoriteProducts = ({ handleShowSettingsHeader, handleShowHeader, onComplete, user }) => {
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedStrainTypes, setSelectedStrainTypes] = useState([]);
    const { isDarkMode } = useTheme();
    const logoSrc = isDarkMode ? logo : logo;
    const { setIsNavVisible } = useNavVisibility();
    const navigate = useNavigate();
    const api = useApi();

    useEffect(() => {
        if(user){
            handleShowHeader(false);
            handleShowSettingsHeader(true);
        } else {
            handleShowHeader(false);
            handleShowSettingsHeader(false);
        }
        setIsNavVisible(false);

        const fetchProductSelections = async () => {
            try {
                const results = await api.getProductTypeFavorites();
                if (results.length > 0) {
                    const selectedCategoryIds = results
                        .filter(item => item.category_id)
                        .map(item => item.category_id);
                    const selectedStrainTypeIds = results
                        .filter(item => item.strain_type_id)
                        .map(item => item.strain_type_id);

                    setSelectedCategories(selectedCategoryIds);
                    setSelectedStrainTypes(selectedStrainTypeIds);
                }
            } catch (error) {
                console.error('Error fetching product selections:', error);
            }
        };
        fetchProductSelections();
    }, []);

    const handleProductSelect = (filter) => {
        let filterId = filter.id;

        if (filter.type === 'strainType') {
            setSelectedStrainTypes(prev =>
                                       prev.includes(filterId)
                                       ? prev.filter(id => id !== filterId)
                                       : [...prev, filterId]
            );
        } else {
            setSelectedCategories(prev =>
                                      prev.includes(filterId)
                                      ? prev.filter(id => id !== filterId)
                                      : [...prev, filterId]
            );
        }
    };

    const handleNext = async () => {
        try {
            await api.saveFavoriteCategories(selectedCategories);
            await api.saveFavoriteStrainTypes(selectedStrainTypes);
            navigate('/select-brands');
        }
        catch (error) {
            console.error('Error saving favorite products:', error);
        }
    };

    const handleSkip = () => {
        navigate('/select-brands');
    }

    const savePreferences = async () => {
        try {
            await api.saveFavoriteCategories(selectedCategories);
            await api.saveFavoriteStrainTypes(selectedStrainTypes);
            navigate('/user-settings');
        }
        catch (error) {
            console.error('Error saving favorite products:', error);
        }
    };

    const hasSelections = selectedCategories.length > 0 || selectedStrainTypes.length > 0;

    return (
        <div className={styles.container}>
            {!user && (
                <div className={styles.logoContainer}>
                    <img className={styles.logo} src={logoSrc} alt="logo"/>
                </div>
            )}
            <div className={styles.titleContainer}>
                {!user && (
                    <button
                        className={styles.backButton}
                        onClick={() => navigate('/select-dispensaries')}
                        aria-label="Go back to select dispensaries"
                    >
                        <ArrowLeft size={24}/>
                    </button>
                )}
                <h3 className={styles.title}>Select your favorite weed products</h3>
            </div>
            <p className={styles.subtitle}>Add to your deal preferences.</p>
            <div className={styles.productGrid}>
                {productCategories.map(category => (
                    <button
                        key={`${category.type}-${category.id}`}
                        className={`${styles.productButton} ${
                            (category.type === 'strainType' && selectedStrainTypes.includes(category.id)) ||
                            (category.type === 'category' && selectedCategories.includes(category.id))
                            ? styles.selected
                            : ''
                        }`}
                        onClick={() => handleProductSelect(category)}
                    >
                        <div className={styles.iconWrap} style={category.customHeight }>
                            {category.icon && (<category.icon className={styles.icon} aria-hidden="true"/>)}
                            {category.iconSimple && (<span className={styles.icon}>{category.iconSimple}</span>)}
                        </div>
                        <span>{category.name}</span>
                    </button>
                ))}
            </div>

            <div className={`${styles.buttonContainer} ${styles.visible}`}>
                {!user && (
                    <>
                        <button className={styles.skipButton} onClick={handleSkip}>Skip this step</button>
                        <button className={styles.startShoppingButton} onClick={handleNext}>
                            Next (2/3)
                        </button>
                    </>
                )}
                {user && (
                    <button className={styles.startShoppingButton} onClick={savePreferences}>
                        Save
                    </button>
                )}
            </div>
        </div>
    );
};

export default UserSelectFavoriteProducts;
