import React, {useEffect, useState} from 'react';
import { Home, Search, MessageCircle, MoreHorizontal } from 'lucide-react';
import { LuBellRing } from "react-icons/lu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VoiceGPTButton from '../VoiceGPT/VoiceGPTButton';
import {useNavCount} from "../../contexts/NavCountProvider";
import { useVoiceSearch } from '../VoiceGPT/VoiceSearchContext';
import { useNavVisibility } from '../NavVisibilityContext/NavVisibilityContext';
import styles from './MainNav.module.css';
import {BsPersonFillGear} from "react-icons/bs";
import useApi from "../../utils/useApi";
import {FaIdeal} from "react-icons/fa6";
import {CiBadgeDollar} from "react-icons/ci";
import {RiProfileFill} from "react-icons/ri";

const MainNav = ({ user, customClass, showTour, setShowTour }) => {
    const {getNotificationsCount} = useApi();
    const location = useLocation();
    const navigate = useNavigate();
    const isSuperAdmin = user && (user.role === 'superadmin');
    const { setTranscript, setShouldSearch } = useVoiceSearch();
    const { isNavVisible } = useNavVisibility();

    const {
        notificationsCount,
        refreshCounts
    } = useNavCount();

    useEffect(() => {
        initialize();
    }, []);

    const handleScrollTop = () => {
        console.log('Gonna scroll top');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const navItems = [
        { to: "/", icon: <CiBadgeDollar />, label: "Deals", onClick: handleScrollTop },
        { to: "/search-all", icon: <Search />, label: "Search" },
        { to: "/deal-alerts", icon: <LuBellRing />, label: "Deal Alerts", count: notificationsCount },
        { to: user?.role === 'user' ? "/user-settings" : "/dispensary-admin", icon: <RiProfileFill />, label: "Profile" },
    ];

    const handleSearchPerformed = (query) => {
        setTranscript(query);
        setShouldSearch(true);
        navigate(`/search-all?q=${encodeURIComponent(query)}`);
    };

    const initialize = async () => {
        refreshCounts();
    }

    return (
        <div id="MainNav" className={`${styles.MainNav} ${customClass ? customClass: (isNavVisible ? styles.visible : styles.hidden)} main-nav`} style={{maxWidth: isSuperAdmin ? '500px' : '400px'}}>
            {navItems.slice(0, Math.ceil(navItems.length / 2)).map((item, index) => (
                <Link key={index} to={item.to} className={item.label.toLowerCase()}>
                    <NavItem
                        onClick={item.onClick}
                        icon={item.icon}
                        label={item.label}
                        count={item.count}
                        active={item.to === '/' ? location.pathname === '/' : location.pathname.startsWith(item.to)}
                    />
                </Link>
            ))}
            {/* <VoiceGPTButton onSearchPerformed={handleSearchPerformed} count={notificationsCount} /> */}
            {navItems.slice(Math.ceil(navItems.length / 2)).map((item, index) => (
                <Link key={index + Math.ceil(navItems.length / 2)} to={item.to} className={item.label.toLowerCase()}>
                    <NavItem
                        icon={item.icon}
                        label={item.label}
                        count={item.count}
                        active={item.to === '/' ? location.pathname === '/' : location.pathname.startsWith(item.to)}
                    />
                </Link>
            ))}
        </div>
    );
};

const NavItem = ({ icon, label, active, onClick, count }) => {
    return (
        <div
            onClick={onClick}
            className={active ? styles.navItemActive:styles.navItem}>
            <span className={styles.iconWrap}>
                {React.cloneElement(icon, { size: 27 })}
                {count > 0 && (
                    <div className={styles.badge}>{count}</div>
                )}
            </span>
            <span style={{ fontSize: '11px', marginTop: '2px' }}>{label}</span>
            {active && (
                <div className={styles.navDot} style={{

                }} />
            )}
        </div>
    );
};

export default MainNav;
