import React from 'react';


const ProductImage = ({product, category, customClass, imgClassName, children}) => {

    if(!category){
        category = product?.category?.id ?? product.category_id;
    }

    const leaflyStock = "https://leafly-production.imgix.net/https%3A%2F%2Fpublic.leafly.com%2Fweb-dispensary%2Fno_image_disclaimer.svg?dpr=2&h=115&fit=fillmax&s=1af2f84dcb3e7d2293396557fb921cf5";
    const flowerStock = "https://product-assets.iheartjane.com/cdn-cgi/image/width=1200,fit=scale-down,format=auto,metadata=none/default-photos/flower/hybrid.png";

    let stockImage = flowerStock;

    const prerollStock = "https://product-assets.iheartjane.com/cdn-cgi/image/width=200,fit=scale-down,format=auto,metadata=none/photos/57/3c/573cf169-fe1f-4cb3-b9bc-d45285b02029.png";


    switch (category) {
        case 2:
            stockImage = flowerStock;
            break;
        case 402:
            stockImage = prerollStock;
            break;
        default:
            stockImage = flowerStock;
            break;
    }

    /*console.log({
                    'name': product.name,
                    'url': product.formatted_thumbnail_url,
                    'firstCase': product.formatted_thumbnail_url && product.formatted_thumbnail_url != leaflyStock,
                    'secondCase': !product.formatted_thumbnail_url || product.formatted_thumbnail_url == leaflyStock
                });*/

    return (
        <div className={customClass}>
            {product.formatted_thumbnail_url && product.formatted_thumbnail_url != leaflyStock && (
                <img src={product.formatted_thumbnail_url} alt={product.name} className={imgClassName ? imgClassName : ""}/>
            )}
            {(!product.formatted_thumbnail_url || product.formatted_thumbnail_url == leaflyStock) && (
                <img src={stockImage} alt={product.name} className={imgClassName ? imgClassName : ""}/>
            )}
            {children}
        </div>
    );
}


export default ProductImage;
