// src/componentes/AppRouter/AppRoutes.js

import React, {useState, useEffect, useCallback, useMemo, memo} from 'react';
import {Route, Routes, Navigate, Outlet, Link, useNavigate, useLocation} from 'react-router-dom';
import {FaMapLocationDot} from "react-icons/fa6";
import {SiMaplibre} from "react-icons/si";
import {BsPersonCircle} from "react-icons/bs";
import {PiMapPinAreaBold} from "react-icons/pi";
import {IoArrowBackCircleOutline} from "react-icons/io5";
import {useTheme} from "../ThemeContext/ThemeContext";  // Import useTheme hook

import styles from './AppRouter.module.css';
import logo from '../../assets/logo2.svg';
import logoLight from '../../assets/logo.svg';

import BusinessProfile from "../BusinessProfile/BusinessProfile";
import Results from "../Results/Results";
import IconSquare from "../IconSquare/IconSquare";
import MainNav from "../MainNav/MainNav";
import DealPostForm from "../DealPostForm/DealPostForm";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import authApi from '../../utils/auth.api';
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import LocationSettingsMenu from "../LocationSettingsMenu/LocationSettingsMenu";

// New components (you'll need to create these)
import DealManager from "../AdminSettings/DealManager/DealManager";
import LocationManager from "../AdminSettings/LocationManager/LocationManager";
import ActiveDeals from "../AdminSettings/ActiveDeals/ActiveDeals";
import InactiveDeals from "../AdminSettings/InactiveDeals/InactiveDeals";
import Locations from "../AdminSettings/Locations/Locations";
import LocationSettings from "../AdminSettings/LocationSettings/LocationSettings";
import UserAdminManager from "../AdminSettings/UserAdminManager/UserAdminManager";
import LocationDashboard from "../AdminSettings/LocationDashboard/LocationDashboard";
import SubscriptionSettings from "../AdminSettings/SubscriptionSettings/SubscriptionSettings";

// Super Admin Components
import SuperAdminDashboard from "../SuperAdminSettings/SuperAdminDashboard/SuperAdminDashboard";
import ManageDispensaries from "../SuperAdminSettings/ManageDispensaries/ManageDispensaries";
import ManageUsers from "../SuperAdminSettings/ManageUsers/ManageUsers";
import AssignUserToDispensary from "../SuperAdminSettings/AssignUserToDispensary/AssignUserToDispensary";
import SuperAdminSettingsMenu from "../SuperAdminSettings/SuperAdminSettingsMenu/SuperAdminSettingsMenu";
import UserSettingsMenu from "../UserSettingsMenu/UserSettingsMenu";
import LocationAdminMenu from "../LocationAdminMenu/LocationAdminMenu";
import SearchApi from "../Search/Search";
import Chat from "../Chat/Chat";
import {NavVisibilityProvider} from "../NavVisibilityContext/NavVisibilityContext";
import AnimatedSearchIndicator from "../Icons/AnimatedSearchIndicator/AnimatedSearchIndicator";
import IntroTour from "../IntroTour/IntroTour";
import UserSelectFavoriteProducts from "../UserSelectFavoriteProducts/UserSelectFavoriteProducts";
import UserNearbyDispensaries from "../UserNearbyDispensaries/UserNearbyDispensaries";
import useApi from "../../utils/useApi";
import UserSelectFavoriteBrands from "../UserSelectFavoriteBrands/UserSelectFavoriteBrands";
import {BrandProvider, useBrands} from '../BrandContext/BrandContext';
import DispensaryAdminMenu from "../DispensaryAdminMenu/DispensaryAdminMenu";
import BackgroundMedia from "../Auth/BackgroundMedia/BackgroundMedia";
import LocationHistoryListbox from "../LocationHistoryListbox/LocationHistoryListbox";
import LocationAutocompleteBottomSheet from "../LocationAutocompleteBottomSheet/LocationAutocompleteBottomSheet";
import Notifications from "../Notifications/Notifications";
import ProductPreview from "../ProductPreview/ProductPreview";
import {createCryptoInterceptor} from "../../utils/crypto-interceptor";
import {NavCountProvider} from "../../contexts/NavCountProvider";
import {useGeolocation} from "../../contexts/GeolocationProvider";
import LoginBottomSheet from "../Auth/LoginBottomSheet/LoginBottomSheet";
import eventBus from "../../utils/eventBus";  // Add this import

const AppRoutesContent = (props) => {
    const [isLoginSheetOpen, setIsLoginSheetOpen] = useState(false);
    const {geoposition, changeGeoPosition, changeLocation} = useGeolocation();
    const [showHeader, setShowHeader] = useState(true);
    const [showSettingsHeader, setShowSettingsHeader] = useState(false);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [route, setRoute] = useState('/');
    const [isLoading, setIsLoading] = useState(true);
    const [showBottomSheet, setShowBottomSheet] = useState(false);
    const [locationInput, setLocationInput] = useState('');
    const { isDarkMode } = useTheme();  // Use the useTheme hook
    const logoSrc = isDarkMode ? logo : logoLight;  // Choose logo based on theme
    const backgroundMediaColor = isDarkMode ? "rgba(0,0,0,0.72)":"rgba(255,255,255,0.90)";
    const navigate = useNavigate();
    const api = useApi();
    const location = useLocation();
    const [showTour, setShowTour] = useState(false);
    const { isNavVisible, setIsNavVisible } = NavVisibilityProvider;
    const { loadBrands } = useBrands();  // Get loadBrands from context

    // Initialize auth state on mount
    useEffect(() => {
        initializeAuth();
    }, []);

    const initializeAuth = async () => {
        try {
            setIsLoading(true);
            const currentUser = await authApi.getCurrentUser();

            console.log(currentUser);
            if (currentUser) {
                setUser(currentUser);
                // Remove the automatic navigation to home
                if (props.geoposition) {
                    loadBrands(api, props.geoposition);
                }

                console.log(location);
                console.log(location.pathname);
                navigate(location.pathname+location.search);
            }
        } catch (error) {
            console.error('Auth initialization error:', error);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Listen for the "unauthorized" event
        eventBus.on('unauthorized', handleUnauthorized);

        // Cleanup
        return () => {
            eventBus.off('unauthorized', handleUnauthorized);
        };
    }, []);

    const handleUnauthorized = () => {
        setIsLoginSheetOpen(true);
    }

    const tourSteps = [
        {
            element: '.near_you',
            intro: 'Find deals nearest to your location.',
            position: 'bottom'
        },
        {
            element: '.best_deal',
            intro: 'Find the best deals sorted by price within the search radius.',
            position: 'bottom'
        },
        {
            element: '.tour-product-item',
            intro: 'This is a deals feed. Click to open and get deal. You can upvote or downvote products here. You can also share, and save to your deal favorites for later.',
            position: 'bottom'
        },
        {
            element: '.for_you',
            intro: 'After you like or upvote a product, find those deals here.',
            position: 'bottom'
        },
        {
            element: '.main-nav',
            intro: 'This is your navigation bar. Use this to navigate throughout the app.',
            position: 'top'
        },
        {
            element: '.home',
            intro: 'This is the main deals feed, find all deals nearest you here.',
            position: 'top',
            customHighlight: 'menuButtonCustomHighlight',
        },
        {
            element: '.search',
            intro: 'Search all products, deals, and dispensaries here.',
            position: 'top',
            customHighlight: 'menuButtonCustomHighlight',

        },
        {
            element: '.notifications',
            intro: 'Find new deals based on your search preferences, and from the dispensaries and brands you follow.',
            position: 'top',
            customHighlight: 'menuButtonCustomHighlightNotify',

        },
        {
            element: '.more',
            intro: 'Access all related settings for your profile here',
            position: 'top',
            customHighlight: 'menuButtonCustomHighlight',

        },
        {
            element: '.navbar-filter',
            intro: 'Filter your results using these options. Click any button for more options and filters.',
            position: 'bottom'
        },

    ];

    const handleShowHeader = useCallback((show) => {
        console.log("Handling show header: " + show);
        setShowHeader(show);
    }, []);

    const handleShowSettingsHeader = (newState) => {
        setShowSettingsHeader(newState);
    }

    const handlePasskeyLogin = (user) => {
        setUser(user);
        navigate('/');
    }

    const handleLogin = async (email, password, shouldNavigate, route) => {
        try {
            setRoute(route);

            console.log('Handling login');
            const userData = await authApi.login(email, password);
            setUser(userData);

            if (shouldNavigate) {
                console.log('Navigating Home')
                navigate('/');

            }

            return userData;
        }
        catch (error) {
            console.error('Login failed:', error);
            setError('Login failed. Please check your credentials.');

            return error;
        }
    };

    const handleRegister = async (firstName, username, email, password) => {
        try {
            let response = await authApi.register(firstName, username, email, password);
            console.log(response);

            if (response.success) {
                const shouldNavigate = false;
                const userData = await handleLogin(email, password, shouldNavigate, '/select-dispensaries');
                console.log("Here is the user data", userData);

                if (userData) {
                    console.log("Checking onboarding status");
                    const onboardingStatus = await api.checkOnboardingStatus();
                    console.log(onboardingStatus);

                    // Start loading brands in the background using the context
                    if (props.geoposition) {
                        // This will now cache the brands in the context
                        loadBrands(api, props.geoposition);
                    }

                    switch (onboardingStatus.status) {
                        case 'new':
                            navigate('/select-dispensaries');
                            break;
                        case 'dispensaries_selected':
                            navigate('/select-products');
                            break;
                        case 'products_selected':
                            navigate('/');
                            setShowTour(true);
                            break;
                        default:
                            console.error('Unknown onboarding status:', onboardingStatus.status);
                            navigate('/');
                    }
                }
            } else {
                console.error('Registration failed:', response.message);
                setError(response.message);
            }
        }
        catch (error) {
            console.error('Registration error:', error);
            setError('An unexpected error occurred. Please try again.');
            console.log(error?.response?.data?.message);
            if(error?.response?.data?.message) {
                setError(error?.response?.data?.message);
            }
        }
    };

    const handleLogout = () => {
        authApi.logout();
        setUser(null);
        navigate('/login');
    };

    const handleCompleteFavoriteBrands = useCallback(() => {
        setShowTour(true);
        loadBrands(api, props.geoposition);

    }, []);

    const handleLocationInputChange = useCallback((e) => {
        setLocationInput(e.target.value);
    }, []);

    const handleLocationChange = useCallback(async (e) => {
        e.preventDefault();
        console.log("Submitting location change:", locationInput);

        try {
            await changeLocation(locationInput);
            console.log("Location changed successfully");
        }
        catch (error) {
            console.error("Error changing location:", error);
        }
        finally {
            setLocationInput('');
            setShowBottomSheet(false);
        }
    }, [locationInput, changeLocation]);

    const toggleBottomSheet = useCallback(() => {
        setShowBottomSheet(prev => !prev);
    }, []);

    useEffect(() => {
        console.log("Current geoposition:", props.geoposition);
    }, [props.geoposition]);

    const renderHeader = useCallback(() => {
        return (
            <div>
                <div className={styles.header}>
                    <div className={styles.upperHeader}>
                        <div className={styles.logoWrap}>
                            <Link to={"/"}>
                                <img src={logoSrc} alt="Logo" className={styles.logo}/>
                            </Link>
                        </div>
                        {props.geoposition && props.geoposition.city && (
                            <>
                                <LocationHistoryListbox
                                    geoposition={props.geoposition}
                                    locationLoading={props.locationLoading}
                                    onLocationSelect={(location) => {
                                        changeGeoPosition(false, location);
                                    }}
                                    onAddNewLocation={() => setShowBottomSheet(true)}
                                />
                            </>

                        )}
                    </div>

                    <div className={styles.lowerHeader}>
                        {props?.dateObject?.currentDate}
                    </div>

                </div>
            </div>
        );
    }, [user, props.geoposition, logoSrc, props.locationLoading]);

    const renderSettingsHeader = () => {
        const getPageName = () => {
            const path = location.pathname;
            const pathParts = path.split('/');
            const lastPart = pathParts[pathParts.length - 1];
            return lastPart.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        };

        return (
            <div>
                <div className={styles.settingsHeader}>
                    <div className={styles.upperSettingsHeader}>
                        <div className={styles.backLink} style={{ width: "33%" }}>
                            <button onClick={() => navigate(-1)} className={styles.backButton}>
                                <IoArrowBackCircleOutline/>
                            </button>

                        </div>
                        <div className={styles.pageName} style={{ width: "51%", textAlign: 'center' }}>
                            {getPageName()}
                        </div>
                        <div className={styles.logoWrap} style={{ width: "33%" }}>
                            <Link to={"/"}>

                                <img src={logoSrc} alt="Logo" className={styles.logo} style={{ alignSelf: "end" }}/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // New component to protect public routes from authenticated users
    const PublicRoute = memo(({ user }) => {
        if (user) {
            return <Navigate to={route} replace />;
        }
        return <Outlet />;
    });

    const closeBottomSheet = () => {
        setShowBottomSheet(false)
    }

    const renderBottomSheet = useMemo(() => (
        <LocationAutocompleteBottomSheet
            isOpen={showBottomSheet}
            onClose={() => closeBottomSheet(event)}
            onLocationSelect={async (location) => {
                try {
                    await changeLocation(`${location.city}, ${location.state_abbr}`);
                    setShowBottomSheet(false);
                } catch (error) {
                    console.error("Error changing location:", error);
                }
            }}
            locationLoading={props.locationLoading}
        />
    ), [showBottomSheet, changeLocation, props.locationLoading]);

    const memoizedRoutes = useMemo(() => (
        <Routes>
            <Route element={<PublicRoute user={user}/>}>
                <Route path="/login" element={<Login onLogin={handleLogin} handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition}/>}/>
                <Route path="/register" element={<Register onRegister={handleRegister} handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition} error={error}/>}/>
            </Route>

            <Route path="/product/:id" element={<ProductPreview handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
            <Route path="/:dispensarySlug/product/:productSlug/:id" element={<ProductPreview handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>


            <Route path="/search-all" element={<SearchApi handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition}/>}/>
            {props.geoposition && (
                <Route exact path="/" element={<Results handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} changeGeoPosition={changeGeoPosition} geoposition={props.geoposition} showTour={showTour} setShowTour={setShowTour}/>}/>
            )}
            {props.geoposition && (
                <Route path="/search" element={<Results handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} changeGeoPosition={changeGeoPosition} geoposition={props.geoposition} showTour={showTour} setShowTour={setShowTour}/>}/>
            )}
            <Route path="/business/:id" element={
                <BusinessProfile
                    handleShowHeader={handleShowHeader}
                    handleShowSettingsHeader={handleShowSettingsHeader}
                    showTour={showTour}
                    setShowTour={setShowTour}
                />
            }/>

            <Route element={<ProtectedRoute user={user}/>}>
                <Route path="/dispensary-preferences" element={<UserNearbyDispensaries handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition} user={user}/>}/>
                <Route path="/product-preferences" element={<UserSelectFavoriteProducts handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition} user={user}/>}/>
                <Route path="/brand-preferences" element={<UserSelectFavoriteBrands handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition} user={user}/>}/>
                <Route path="/deal-alerts" element={<Notifications handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/select-dispensaries" element={<UserNearbyDispensaries handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition}/>}/>
                <Route path="/select-products" element={<UserSelectFavoriteProducts handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition}/>}/>
                <Route path="/select-brands" element={<UserSelectFavoriteBrands handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} geoposition={props.geoposition} onComplete={handleCompleteFavoriteBrands}/>}/>

                {/* Location Settings Routes */}

                <Route path="/location-settings/add-deal" element={<DealPostForm handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/deals/:id/edit-deal" element={<DealPostForm handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/deals/:business_id/add-deal" element={<DealPostForm handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>

                <Route path="/dispensary-admin" element={<DispensaryAdminMenu user={user} handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} handleLogout={handleLogout} setShowTour={setShowTour}/>}/>
                <Route path="/account-admin" element={<LocationAdminMenu user={user} handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} handleLogout={handleLogout}/>}/>
                <Route path="/location-settings/:id/settings" element={<LocationSettingsMenu handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} handleLogout={handleLogout}/>}/>
                <Route path="/location-settings/:id/deal-manager" element={<DealManager handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/dispensary-manager" element={<LocationManager handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/active-deals" element={<ActiveDeals handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/inactive-deals" element={<InactiveDeals handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/list-locations" element={<Locations handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/:id/edit-settings" element={<LocationSettings handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/user-admin" element={<UserAdminManager handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/dashboard" element={<LocationDashboard handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>
                <Route path="/location-settings/subscription" element={<SubscriptionSettings handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>}/>

                <Route path="/user-settings" element={<UserSettingsMenu user={user} includeHello={true}
                                                                        includeLogout={true}
                                                                        handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} handleLogout={handleLogout} setShowTour={setShowTour} />}/>
            </Route>

            <Route element={<ProtectedRoute user={user} requiredRole={['admin', 'superadmin']}/>}>
                <Route path="/location-settings/:id/admins" element={<AssignUserToDispensary handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/superadmin/manage-dispensary-admins" element={<AssignUserToDispensary handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/superadmin" element={<SuperAdminSettingsMenu handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/superadmin/manage-dispensaries" element={<ManageDispensaries handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/superadmin/manage-users" element={<ManageUsers handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} user={user}/>}/>
                <Route path="/settings" element={<UserSettingsMenu user={user} includeHello={true}
                                                                        includeLogout={false}
                                                                        handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader} handleLogout={handleLogout} setShowTour={setShowTour} />}/>
            </Route>

            <Route path="*" element={<Navigate to="/" replace/>} />
        </Routes>

    ), [user, handleLogin, handleRegister, props.geoposition]);

    return (
        <div>
            <NavVisibilityProvider>
                <LoginBottomSheet
                    isOpen={isLoginSheetOpen}
                    onClose={() => {
                        console.log('Closing login sheet');
                        setIsLoginSheetOpen(false);
                    }}
                    onPasskeyLogin={handlePasskeyLogin}
                    onLogin={handleLogin}
                />
                <BackgroundMedia geoposition={props.geoposition} customStyle={{ backgroundColor: backgroundMediaColor }}/>

                {(showTour && user && props.geoposition) && (
                    <IntroTour
                        steps={tourSteps}
                        onComplete={() => {
                            setShowTour(false); // Ensure nav is visible when tour completes
                        }}
                        delay={1000}
                    />
                )}

                {showHeader && renderHeader()}
                {showSettingsHeader && renderSettingsHeader()}
                {renderBottomSheet}

                {showSettingsHeader ? (
                    <div className={styles.settingsContent}>
                        {memoizedRoutes}
                    </div>
                ) : memoizedRoutes}

                {(showTour) && (<div className={`${styles.Navigation} ${styles.visible} main-nav-tour`}>
                    <MainNav
                        customClass={styles.mainNavTour}
                        user={user}
                        onLogout={handleLogout}
                        showTour={showTour}
                        setShowTour={setShowTour}
                    />
                </div>)}

                {(!showTour) && (<div className={`${styles.Navigation} ${isNavVisible} main-nav-normal`}>
                    <MainNav
                        user={user}
                        onLogout={handleLogout}
                        showTour={showTour}
                        setShowTour={setShowTour}
                    />
                </div>)}


            </NavVisibilityProvider>
        </div>
    );
};

const AppRoutes = (props) => {
    return (
        <NavCountProvider user={props.user}>
            <BrandProvider>
                <AppRoutesContent {...props} />
            </BrandProvider>
        </NavCountProvider>

    )
}

export default React.memo(AppRoutes);
