import { useState, useEffect, useCallback } from 'react';
import useApi from '../utils/useApi';

const useNotifications = () => {
    const [permission, setPermission] = useState('default');
    const [subscription, setSubscription] = useState(null);
    const [registration, setRegistration] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [error, setError] = useState(null);

    const api = useApi();

    // Move helper function outside component or memoize if needed
    const urlBase64ToUint8Array = useCallback((base64String) => {
        if (!base64String) {
            throw new Error('VAPID public key is not defined');
        }
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }, []);

    const checkServiceWorkerSupport = useCallback(() => {
        if (!('serviceWorker' in navigator)) {
            setError('Service Workers are not supported');
            return false;
        }
        if (!('PushManager' in window)) {
            setError('Push notifications are not supported');
            return false;
        }
        return true;
    }, []);

    const checkSubscription = useCallback(async (reg) => {
        if (!reg || !reg.pushManager) {
            console.error('Push manager not available');
            return;
        }

        try {
            const subscription = await reg.pushManager.getSubscription();
            console.log('Current push subscription:', subscription);
            setSubscription(subscription);
            if (Notification.permission === 'granted') {
                setPermission('granted');
            }
        } catch (error) {
            console.error('Error checking subscription:', error);
        }
    }, []);

    const registerServiceWorker = useCallback(async () => {
        try {
            // First, unregister any existing service workers
            const registrations = await navigator.serviceWorker.getRegistrations();
            for (let reg of registrations) {
                await reg.unregister();
            }

            const swPath = '/service-worker.js';
            console.log('Registering new service worker at:', swPath);

            const newRegistration = await navigator.serviceWorker.register(swPath, {
                scope: '/',
                type: 'module'
            });

            console.log('Service Worker registered successfully:', newRegistration);
            await navigator.serviceWorker.ready;
            console.log('Service Worker is ready');

            setRegistration(newRegistration);
            await checkSubscription(newRegistration);

            return newRegistration;
        } catch (err) {
            console.error('Service Worker registration failed:', err);
            throw err;
        }
    }, []);

    useEffect(() => {
        let mounted = true;

        const initialize = async () => {
            if (!checkServiceWorkerSupport()) return;

            try {
                if (mounted) {
                    await registerServiceWorker();
                    setIsInitialized(true);
                }
            } catch (error) {
                if (mounted) {
                    console.error('Failed to initialize notifications:', error);
                    setError(error.message);
                }
            }
        };

        initialize();

        return () => {
            mounted = false;
            if (registration) {
                setRegistration(null);
                setIsInitialized(false);
            }
        };
    }, []);

    const subscribeUser = useCallback(async () => {
        try {
            let currentRegistration = registration;

            if (!currentRegistration?.pushManager) {
                currentRegistration = await registerServiceWorker();
            }

            console.log('Requesting notification permission...');
            const permissionResult = await Notification.requestPermission();
            setPermission(permissionResult);

            if (permissionResult === 'granted') {
                if (!process.env.REACT_APP_VAPID_PUBLIC_KEY) {
                    throw new Error('VAPID public key is not configured');
                }

                const subscribeOptions = {
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY)
                };

                console.log('Subscribing to push notifications...');
                const pushSubscription = await currentRegistration.pushManager.subscribe(subscribeOptions);
                console.log('Successfully subscribed to push:', pushSubscription);

                setSubscription(pushSubscription);

                await api.subscribeToPushNotifications(pushSubscription);
                return true;
            }
            return false;
        } catch (err) {
            console.error('Failed to subscribe user:', err);
            setError(err.message);
            return false;
        }
    }, []);

    const unsubscribeUser = useCallback(async () => {
        if (subscription) {
            try {
                await subscription.unsubscribe();
                await api.unsubscribeFromPushNotifications(subscription);
                setSubscription(null);
            } catch (error) {
                console.error('Error unsubscribing:', error);
            }
        }
    }, [subscription, api]);

    return {
        permission,
        subscription,
        subscribeUser,
        unsubscribeUser,
        isInitialized,
        error,
        registration
    };
};

export default useNotifications;
