import { useState, useEffect } from 'react';

const useIsPhone = () => {
    const [isPhone, setIsPhone] = useState(false);
    const [isIos, setIsIos] = useState(false);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    useEffect(() => {
        // Function to check if device is a phone
        const checkIfPhone = () => {
            // Check screen width (typical phone width is less than 768px)
            const isMobileWidth = window.innerWidth < 768;

            // Check user agent for mobile devices
            const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );

            // Additional check for touch capability
            const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

            // Consider it a phone if it's mobile width AND (mobile user agent OR touch capable)
            setIsPhone(isMobileWidth && (isMobileDevice || isTouchDevice));
            setIsIos(isIOS && isSafari);

        };

        // Check initially
        checkIfPhone();

        // Add resize listener
        window.addEventListener('resize', checkIfPhone);

        // Cleanup
        return () => window.removeEventListener('resize', checkIfPhone);
    }, []);

    return [isPhone, isIos, isSafari];
};

export default useIsPhone;

// Usage example:
/*
import useIsPhone from './useIsPhone';

const MyComponent = () => {
  const isPhone = useIsPhone();

  return (
    <div>
      {isPhone ? (
        <p>You're on a phone!</p>
      ) : (
        <p>You're not on a phone</p>
      )}
    </div>
  );
};
*/
