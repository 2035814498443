// LocationSettingsMenu.js
import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useNavVisibility} from "../NavVisibilityContext/NavVisibilityContext";

import styles from '../UserSettingsMenu/UserSettingsMenu.module.css';
import {TbSettings, TbTargetArrow} from "react-icons/tb";
import {MdAccountBalance, MdManageAccounts} from "react-icons/md";
import {SiChartdotjs, SiSoundcharts} from "react-icons/si";
import {PiBugBeetleLight, PiCompassBold, PiContactlessPayment, PiStarLight} from "react-icons/pi";
import {CiLogout} from "react-icons/ci";
import {SiHomeassistantcommunitystore} from "react-icons/si";

import {useTheme} from "../ThemeContext/ThemeContext";
import ThemeToggle from "../ThemeToggle/ThemeToggle";
import {RiUserSettingsLine} from "react-icons/ri";
import {HiChevronRight, HiOutlineQuestionMarkCircle} from "react-icons/hi2";
import {RxExclamationTriangle} from "react-icons/rx";
import {GiOpenBook} from "react-icons/gi";

const DispensaryAdminMenu = ({ handleShowHeader, handleShowSettingsHeader, handleLogout, user, setShowTour }) => {
        const { isDarkMode, toggleTheme } = useTheme();
        const navigate = useNavigate();
        const { setIsNavVisible } = useNavVisibility();

        useEffect(() => {
            handleShowHeader(false);
            handleShowSettingsHeader(true);
            setIsNavVisible(false);
        }, [handleShowHeader, handleShowSettingsHeader]);

        const handleThemeToggle = () => {
            toggleTheme();
            localStorage.setItem('theme', isDarkMode ? 'light' : 'dark');
        };

        const handleTutorialClick = (e) => {
            e.preventDefault();
            setShowTour(true);
            navigate('/');
        };

        const menuItems = {
            mainSection: [
                { link: "/get-help", icon: HiOutlineQuestionMarkCircle, text: "Get Help", includeArrow: true },
                /*{ link: "/review", icon: PiStarLight, text: "Review On App Store & Google Play", includeArrow: true },*/
                { link: "/report-bug", icon: PiBugBeetleLight, text: "Report App Bug", includeArrow: true },
                { link: "/report-deal-issue", icon: RxExclamationTriangle, text: "Report Deal Issue", includeArrow: true },
                {
                    link: "#",
                    icon: GiOpenBook,
                    text: "Tutorial",
                    includeArrow: true,
                    onClick: handleTutorialClick
                },
            ],
        }

        const renderMenuItem = ({ link, icon: Icon, text, includeArrow, customClass, onClick }) => (
            <li key={text} className={`${styles.menuItem} ${customClass ? styles[customClass] : ''}`}>
                <Link
                    to={link}
                    className={styles.menuLink}
                    onClick={onClick}
                >
                    <div className={styles.iconAndName}>
                        {Icon && <Icon className={styles.menuIcon}/>}
                        {text}
                    </div>
                    {includeArrow && <span className={styles.arrow}><HiChevronRight/></span>}
                </Link>
            </li>
        );

        return (
            <>
                <nav className={styles.userSettingsMenu}>
                    <ul className={styles.menuList}>
                        <div className={styles.userSectionHelloWrap}>
                            <div className={styles.userSectionHello}>
                                <h2 className={styles.sectionHeader}>Hello, {user.name}</h2>
                            </div>
                        </div>
                        <div className={styles.section}>
                            <li className={styles.menuItem}>
                                <Link to="/dashboard" className={styles.menuLink}>
                                    <div className={styles.iconAndName}>
                                        <SiSoundcharts/>
                                        Dashboard
                                    </div>
                                    <span className={styles.arrow}>›</span>
                                </Link>
                            </li>
                            <li className={styles.menuItem}>
                                <Link to="/account-admin" className={styles.menuLink}>
                                    <div className={styles.iconAndName}>
                                        <MdAccountBalance/>
                                        Account Admin
                                    </div>
                                    <span className={styles.arrow}>›</span>
                                </Link>
                            </li>
                            <li className={styles.menuItem}>
                                <Link to="/location-settings/dispensary-manager" className={styles.menuLink}>
                                    <div className={styles.iconAndName}>
                                        <SiHomeassistantcommunitystore/>
                                        Dispensary Manager
                                    </div>
                                    <span className={styles.arrow}>›</span>
                                </Link>
                            </li>
                            <li className={styles.menuItem}>
                                <Link to="/settings" className={styles.menuLink}>
                                    <div className={styles.iconAndName}>
                                        <RiUserSettingsLine/>
                                        User Settings
                                    </div>
                                    <span className={styles.arrow}>›</span>
                                </Link>
                            </li>
                            <li className={styles.menuItem}>
                                <Link to="/marketing-toolkit" className={styles.menuLink}>
                                    <div className={styles.iconAndName}>
                                        <TbTargetArrow/>
                                        Marketing Toolkit
                                    </div>
                                    <span className={styles.arrow}>›</span>
                                </Link>
                            </li>

                        </div>
                        <div className={styles.section}>
                            {menuItems.mainSection.map(renderMenuItem)}
                        </div>
                        <div className={styles.toggleContainer}>
                            <ThemeToggle isDarkMode={isDarkMode} toggleTheme={toggleTheme}/>
                        </div>


                        <div className={styles.section}>
                            <div className={styles.logoutWrap}>
                                <Link className={`${styles.menuLink} ${styles.logout}`}>
                                    <div className={`${styles.iconAndName} ${styles.logoutText}`} onClick={handleLogout}>
                                        <CiLogout className={`${styles.menuIcon} ${styles.logoutIcon}`}/>
                                        Logout
                                    </div>
                                </Link>
                            </div>
                        </div>


                    </ul>
                </nav>
            </>
        )
    }
;

export default DispensaryAdminMenu;

