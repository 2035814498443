import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '../../utils/useApi';
import styles from './UserNearbyDispensaries.module.css';
import logo from '../../assets/logo2.svg';
import { useTheme } from "../ThemeContext/ThemeContext";
import { useNavVisibility } from "../NavVisibilityContext/NavVisibilityContext";

const UserNearbyDispensaries = ({handleShowSettingsHeader, handleShowHeader, geoposition, user}) => {
    const [dispensaries, setDispensaries] = useState([]);
    const [selectedDispensaries, setSelectedDispensaries] = useState([]);
    const { isDarkMode } = useTheme();
    const logoSrc = isDarkMode ? logo : logo;
    const { setIsNavVisible } = useNavVisibility();
    const navigate = useNavigate();
    const api = useApi();

    useEffect(() => {
        if(user){
            handleShowHeader(false);
            handleShowSettingsHeader(true);
        } else {
            handleShowHeader(false);
            handleShowSettingsHeader(false);
        }

        setIsNavVisible(false);

        const fetchDispensaries = async () => {
            try {
                let query = {
                    geo: geoposition,
                    sortBy: 'near_by',
                    page: 1,
                    distance: 10,
                }

                const results = await api.selectFavoriteBusinesses(query);
                if (results.data.length > 0) {
                    setDispensaries(results.data);
                    const initialSelected = results.data
                                                   .filter(dispensary => dispensary.selected)
                                                   .map(dispensary => dispensary.id);
                    setSelectedDispensaries(initialSelected);
                }
            } catch (error) {
                console.error('Error fetching nearby dispensaries:', error);
            }
        };
        fetchDispensaries();
    }, []);

    const handleDispensarySelect = (dispensaryId) => {
        setSelectedDispensaries(prev =>
                                    prev.includes(dispensaryId)
                                    ? prev.filter(id => id !== dispensaryId)
                                    : [...prev, dispensaryId]
        );
    };

    const handleNext = async () => {
        try {
            await api.saveFavoriteDispensaries(selectedDispensaries);
            navigate('/select-products');
        } catch (error) {
            console.error('Error saving selected dispensaries:', error);
        }
    };

    const handleSkip = () => {
        navigate('/select-products');
    }

    const savePreferences = async () => {
        try {
            await api.saveFavoriteDispensaries(selectedDispensaries);
            navigate('/user-settings');
        } catch (error) {
            console.error('Error saving selected dispensaries:', error);
        }
    };

    return (
        <div className={styles.container}>
            {!user && (
                <div className={styles.logoContainer}>
                    <img className={styles.logo} src={logoSrc} alt="logo"/>
                </div>
            )}
            <h3 className={styles.title}>
                We found <span className={styles.brandColor}>{dispensaries.length} dispensaries </span> near you!
            </h3>
            <p className={styles.subtitle}>
                Select your most-visited dispensaries to add to your Favorites.
            </p>
            <div className={styles.dispensaryGrid}>
                {dispensaries.map((dispensary) => (
                    <button
                        key={dispensary.id}
                        className={`${styles.dispensaryButton} ${
                            selectedDispensaries.includes(dispensary.id) ? styles.selected : ''
                        }`}
                        onClick={() => handleDispensarySelect(dispensary.id)}
                    >
                        <div
                            className={styles.logoImage}
                            style={{ backgroundImage: `url(${dispensary.logoImage})` }}
                        >
                            <img src={dispensary.logoImage} alt={dispensary.name} />
                        </div>
                        <span>{dispensary.name}</span>
                    </button>
                ))}
            </div>

            <div className={`${styles.buttonContainer} ${
                styles.visible}`}>
                {!user && (
                    <>
                        <button className={styles.skipButton} onClick={handleSkip}>Skip this Step</button>
                        <button className={styles.nextButton} onClick={handleNext}>
                            Next (1/3)
                        </button>
                    </>
                )}
                {user && (
                    <button className={styles.nextButton} onClick={savePreferences}>
                        Save
                    </button>
                )}
            </div>
        </div>
    );
};

export default UserNearbyDispensaries;
