import {useState, useCallback, useRef} from 'react';
import constants from './constants';
import authApi from './auth.api';
import {createCryptoInterceptor} from "./crypto-interceptor";
import eventBus from "./eventBus";

const useApi = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const abortControllerRef = useRef(null);

    const baseUrl = constants.API_URL;

    // API Helpers
    // API Helpers
    const handleError = useCallback((error) => {
        if (error.name === 'AbortError') {
            console.log('Request was cancelled');
        } else {
            console.error(error);
            setError(error.message);
        }
    }, []);

    const handleUnauthorized = useCallback(() => {
        // Clear any existing auth tokens
        localStorage.removeItem('user');
        sessionStorage.removeItem('user');

        eventBus.emit('unauthorized');
    }, []);

    const fetchData = useCallback(async (url, options) => {
        setIsLoading(true);
        setError(null);

        // Cancel any ongoing requests
        if (abortControllerRef.current) {
            //abortControllerRef.current.abort();
        }

        // Create a new AbortController for this request
        abortControllerRef.current = new AbortController();
        options.signal = abortControllerRef.current.signal;

        try {
            const response = await fetch(url, options);

            // Handle 401 Unauthorized
            if (response.status === 401) {
                handleUnauthorized();
                throw new Error('Unauthorized - Please login');
            }

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.text();
            let parsedData;

            try {
                parsedData = JSON.parse(data);
            } catch {
                parsedData = data;
            }

            return createCryptoInterceptor(() => parsedData)(url, options);
        }
        catch (error) {
            handleError(error);
            throw error;
        }
        finally {
            setIsLoading(false);
        }
    }, [handleError, handleUnauthorized]);

    const getHeaders = useCallback(() => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return {
            ...authApi.authHeader(),
            'Accept': 'application/json',
            'X-User-Timezone': timezone  // Add timezone to every request
        };
    }, []);

    const get = useCallback((endpoint) => {
        const url = `${baseUrl}${endpoint}`;
        return fetchData(url, {
            method: 'GET',
            headers: getHeaders()
        });
    }, [baseUrl, fetchData, getHeaders]);

    const post = useCallback((endpoint, data) => {
        const url = `${baseUrl}${endpoint}`;
        let body;
        let postHeaders = getHeaders();

        if (data instanceof FormData) {
            body = data;
        } else {
            body = JSON.stringify(data);
            postHeaders['Content-Type'] = 'application/json';
        }

        return fetchData(url, {
            method: 'POST',
            headers: postHeaders,
            body
        });
    }, [baseUrl, fetchData, getHeaders]);

    const put = useCallback((endpoint, data) => {
        const url = `${baseUrl}${endpoint}`;
        let body;
        let putHeaders = getHeaders();

        if (data instanceof FormData) {
            body = data;
            body.append('_method', 'PUT');
            // Remove Content-Type header if it exists
            delete putHeaders['Content-Type'];
        } else {
            body = JSON.stringify(data);
            putHeaders['Content-Type'] = 'application/json';
        }

        return fetchData(url, {
            method: 'POST', // We use POST and rely on _method for Laravel
            headers: putHeaders,
            body
        });
    }, [baseUrl, fetchData, getHeaders]);

    const deleteMethod = useCallback((endpoint) => {
        const url = `${baseUrl}${endpoint}`;
        return fetchData(url, {
            method: 'DELETE',
            headers: getHeaders()
        });
    }, [baseUrl, fetchData, getHeaders]);

    // Routes
    const submitDeal = useCallback((data) => {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (key === 'image' && value instanceof File) {
                formData.append('image', value, value.name);
            } else if (key === 'starts' || key === 'ends') {
                formData.append(key, value.toISOString());
            } else if (key === 'product_ids' && Array.isArray(value)) {
                // Append each product_id separately
                value.forEach((productId, index) => {
                    formData.append(`product_ids[${index}]`, productId);
                });
            } else {
                formData.append(key, value);
            }
        });

        return post('deals', formData);
    }, [post]);

    const updateDeal = useCallback((id, data) => {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (key === 'image' && value instanceof File) {
                formData.append('image', value, value.name);
            } else if (key === 'startsAt' || key === 'endsAt') {
                formData.append(key, value.toISOString());
            } else if (key === 'product_ids' && Array.isArray(value)) {
                // Append each product_id separately
                value.forEach((productId, index) => {
                    formData.append(`product_ids[${index}]`, productId);
                });
            } else {
                formData.append(key, value);
            }
        });

        return put(`deals/${id}`, formData);
    }, [put]);

    const deleteDeal = useCallback((id) => deleteMethod(`deals/${id}`), [deleteMethod]);
    const deleteDealProduct = useCallback((id, productId) => deleteMethod(`deals/${id}/product/${productId}`), [deleteMethod]);

    const deleteNotification = useCallback((id) => deleteMethod(`notifications/${id}`), [deleteMethod]);

    const getBusiness = useCallback((id) => get(`business/${id}`), [get]);
    const getBusinessMenu = useCallback((id, page = 1) => {
        return get(`business/${id}/menu?page=${page}`);
    }, [get]);

    const getBusinessFilterCounts = useCallback((query) => {

        const priceRanges = [
            { from: 0, to: 20 },
            { from: 20, to: 40 },
            { from: 40, to: 60 },
            { from: 60, to: 80 },
            { from: 80, to: Infinity }
        ];

        const weights = [0.5, 1, 2, 3.5, 7, 14, 28];


        Object.keys(query).forEach(key => {
            if (query[key] === null) {
                delete query[key];
            }
        });


        const params = new URLSearchParams(query);
        params.append('price_ranges', JSON.stringify(priceRanges));
        params.append('weights', JSON.stringify(weights));

        return get(`get-business-filter-counts?${params}`);
    }, [get]);

    const getBusinessMenuCategory = useCallback((businessId, categoryId, page = 1) => {
        return get(`business/${businessId}/menu/${categoryId}?page=${page}`);
    }, [get]);

    const getBusinessDeals = useCallback((id) => get(`business/${id}/deals`), [get]);

    const likeProduct = useCallback((productId, isLiked) => {
        const url = `products/${productId}/like`;
        return post(url, { liked: isLiked });
    }, [post]);

    const voteProduct = useCallback((productId, vote) => {
        const url = `products/${productId}/vote`;
        return post(url, { vote: vote });
    }, [post]);

    const search = useCallback((term, city, sortBy) => {
        const params = new URLSearchParams({ city, term, sort_by: sortBy });
        return get(`search?${params}`);
    }, [get]);

    const searchApi = useCallback((geo, term, category) => {
        console.log(geo);
        let params = new URLSearchParams({ lat: geo.latitude, lon: geo.longitude, term });

        if (category) {
            params = new URLSearchParams({ lat: geo.latitude, lon: geo.longitude, term, category });
        }
        return get(`search-api?${params}`);
    }, [get]);

    const searchGeo = useCallback((term, geo, sortBy) => {
        const params = new URLSearchParams({
                                               lat: geo.latitude,
                                               lon: geo.longitude,
                                               term,
                                               sort_by: sortBy
                                           });
        return get(`search-geo?${params}`);
    }, [get]);

    const searchGeoProducts = useCallback((term, geo, category, sortBy, page, distance, priceFrom, priceTo, weight, cbdFrom, cbdTo, thcFrom, thcTo, strainType) => {
        const params = new URLSearchParams({
                                               lat: geo.latitude,
                                               lon: geo.longitude,
                                               term,
                                               category: category,
                                               sort_by: sortBy,
                                               page: page,
                                               distance: distance ?? 2,
                                           });

        if (priceFrom && priceFrom > -1) params.append('price_from', priceFrom);
        if (priceTo && priceTo > -1) params.append('price_to', priceTo);
        if (weight) params.append('weight', weight);
        if (cbdFrom) params.append('cbd_from', cbdFrom);
        if (cbdTo) params.append('cbd_to', cbdTo);
        if (thcFrom) params.append('thc_from', thcFrom);
        if (thcTo) params.append('thc_to', thcTo);
        if (strainType) params.append('strain_type', strainType);

        return get(`search-geo-products?${params}`);
    }, [get]);

    const getBusinessProducts = useCallback((query) => {

        Object.keys(query).forEach(key => {
            if (query[key] === null || query[key] === undefined || query[key] === '') {
                delete query[key];
            }
        });

        const params = new URLSearchParams(query);

        return get(`business-products?${params}`);
    }, [get]);

    const getUserRecentProducts = useCallback((query) => {
        query.locations = true;
        query.lat = query.geo.latitude ?? null;
        query.lon = query.geo.longitude ?? null;
        query.geo = null;

        Object.keys(query).forEach(key => {
            if (query[key] === null) {
                delete query[key];
            }
        });

        const params = new URLSearchParams(query);

        return get(`user-recent-products?${params}`);
    }, [get]);

    const searchGeoBusinesses = useCallback((query) => {
        console.log(query);
        console.log('Distance: ' + query.distance);
        console.log('Category: ' + query.category);
        query.locations = true;
        query.lat = query.geoposition.latitude ?? null;
        query.lon = query.geoposition.longitude ?? null;
        query.geo = null;

        Object.keys(query).forEach(key => {
            if (query[key] === null) {
                delete query[key];
            }
        });

        const params = new URLSearchParams(query);

        return get(`search-geo-locations?${params}`);
    }, [get]);

    const selectFavoriteBusinesses = useCallback((query) => {
        console.log('Distance: ' + query.distance);
        console.log('Category: ' + query.category);
        query.locations = true;
        query.lat = query.geo.latitude ?? null;
        query.lon = query.geo.longitude ?? null;
        query.geo = null;

        Object.keys(query).forEach(key => {
            if (query[key] === null) {
                delete query[key];
            }
        });

        const params = new URLSearchParams(query);

        return get(`select-favorite-dispensaries?${params}`);
    }, [get]);

    const selectFavoriteBrands = useCallback((query) => {
        console.log('Distance: ' + query.distance);
        console.log('Category: ' + query.category);
        query.locations = true;
        query.lat = query.geo.latitude ?? null;
        query.lon = query.geo.longitude ?? null;
        query.geo = null;

        Object.keys(query).forEach(key => {
            if (query[key] === null) {
                delete query[key];
            }
        });

        const params = new URLSearchParams(query);

        return get(`brands/popular?${params}`);
    }, [get]);

    const getFilterCounts = useCallback((geo, term, sortBy, category, distance, strainType, weight, priceFrom, priceTo) => {
        const params = new URLSearchParams({
                                               lat: geo.latitude,
                                               lon: geo.longitude,
                                               term,
                                               category,
                                               filterCounts: true,
                                               distance: distance ?? 2,
                                               sort_by: sortBy,
                                           });

        const priceRanges = [
            { from: 0, to: 20 },
            { from: 20, to: 40 },
            { from: 40, to: 60 },
            { from: 60, to: 80 },
            { from: 80, to: Infinity }
        ];

        const weights = [0.5, 1, 2, 3.5, 7, 14, 28];

        params.append('price_ranges', JSON.stringify(priceRanges));
        params.append('weights', JSON.stringify(weights));

        if (strainType) {
            params.append('strain_type', strainType);
        }

        //if (weight) params.append('weight', weight);

        //if(priceFrom > -1) params.append('price_from', priceFrom);
        //if(priceTo) params.append('price_to', priceTo);

        return get(`get-filter-counts?${params}`);
    }, [get]);

    const searchProducts = useCallback((dispensaryId, term, productIds = []) => {
        const params = new URLSearchParams({ dispensaryId, term });

        // Add product_ids to the query parameters
        if (productIds.length > 0) {
            productIds.forEach((id, index) => {
                params.append(`product_ids[${index}]`, id);
            });
        }

        return get(`search-products?${params}`);
    }, [get]);

    const getDeal = useCallback((id) => get(`deals/${id}`), [get]);

    const getGeoposition = useCallback(() => get('geoposition'), [get]);
    const getCategories = useCallback(() => get('categories'), [get]);
    const getProduct = useCallback((productId) => get(`product/${productId}`), [get]);
    const getLocationDeals = useCallback((businessId) => get(`location-deals/${businessId}`), [get]);

    // User Manage Preferences
    const checkOnboardingStatus = useCallback(async () => {
        return get('user/onboarding-status');
    }, [get]);

    const getNearbyDispensaries = useCallback(() => {
        return get('nearby-dispensaries');
    }, [get]);

    const getProductTypeFavorites = useCallback(() => {
        return get('product-type-favorites');
    }, [get]);

    const getNotifications = useCallback((page) => {
        return get('notifications?page=' + page);
    }, [get]);

    const getNotificationsCount = useCallback(() => {
        return get('notifications/unseen/count');
    }, [get]);

    const markNotificationSeen = useCallback((id) => {
        return post(`notifications/${id}/mark-seen`);
    }, [post]);

    const markAllNotificationsSeen = useCallback((id) => {
        return post(`notifications/mark-all-seen`);
    }, [post]);

    const saveFavoriteDispensaries = useCallback((dispensaryIds) => {
        return post('user/save-dispensaries', { dispensary_ids: dispensaryIds });
    }, [post]);

    const saveFavoriteBrands = useCallback((brandIds) => {
        return post('user/save-brands', { brand_ids: brandIds });
    }, [post]);

    const saveFavoriteCategories = useCallback((categoryIds) => {
        return post('user/save-categories', { category_ids: categoryIds });
    }, [post]);

    const saveFavoriteStrainTypes = useCallback((strainTypeIds) => {
        return post('user/save-strain-types', { strain_type_ids: strainTypeIds });
    }, [post]);

    const updateRecentLocation = useCallback((geoposition) => {
        return post('recent-location', { latitude: geoposition.latitude, longitude: geoposition.longitude });
    }, [post]);

    // Manage Users and Dispensaries
    const searchUsers = useCallback((query) => {
        return get(`users/search?term=${encodeURIComponent(query)}`);
    }, [get]);

    const searchDispensaries = useCallback((query, mine, id) => {
        if (id) {
            return get(`search-locations?id=${encodeURIComponent(id)}`);
        } else if (query) {
            return get(`search-locations?term=${encodeURIComponent(query)}`);
        } else if (mine) {
            return get(`search-locations?mine=${encodeURIComponent(mine)}`);
        }
        return get(`search-locations`);
    }, [get]);
    const getUsers = useCallback(() => get('users'), [get]);
    const getDispensaries = useCallback(() => get('dispensaries'), [get]);
    const getDispensaryUsers = useCallback((dispensaryId) => {
        return get(`dispensaries/${dispensaryId}/users`);
    }, [get]);
    const getRoles = useCallback(() => get('roles'), [get]);

    const assignUserToDispensary = useCallback((userId, dispensaryId, roleId) => {
        return post('dispensaries/users', { user_id: userId, dispensary_id: dispensaryId, role_id: roleId });
    }, [post]);

    // Analytics Tracking
    const trackProductView = useCallback((productId, dispensaryId) => {
        const url = `product-view/${productId}/${dispensaryId}`;
        return post(url, { product_id: productId, dispensary_id: dispensaryId });
    }, [post]);

    // Push Notifications

    // Add these new notification-related functions
    const subscribeToPushNotifications = useCallback(async (subscription) => {
        return post('push-subscription', {
            endpoint: subscription.endpoint,
            keys: {
                p256dh: btoa(String.fromCharCode.apply(null,
                                                       new Uint8Array(subscription.getKey('p256dh')))),
                auth: btoa(String.fromCharCode.apply(null,
                                                     new Uint8Array(subscription.getKey('auth'))))
            },
            content_encoding: (PushManager.supportedContentEncodings || ['aesgcm'])[0]
        });
    }, [post]);

    const unsubscribeFromPushNotifications = useCallback(async (subscription) => {
        return deleteMethod('push-subscription', {
            endpoint: subscription.endpoint
        });
    }, [deleteMethod]);

    const updatePushNotificationSettings = useCallback(async (settings) => {
        return put('push-notification-settings', settings);
    }, [put]);

    const getPushNotificationSettings = useCallback(async () => {
        return get('push-notification-settings');
    }, [get]);

    return {
        isLoading,
        error,
        assignUserToDispensary,
        checkOnboardingStatus,
        deleteDeal,
        deleteDealProduct,
        deleteNotification,
        getBusiness,
        getBusinessMenu,
        getBusinessMenuCategory,
        getBusinessDeals,
        getBusinessProducts,
        getBusinessFilterCounts,
        getCategories,
        getNearbyDispensaries,
        getProduct,
        getDeal,
        getDispensaries,
        getDispensaryUsers,
        getGeoposition,
        getLocationDeals,
        getProductTypeFavorites,
        getNotifications,
        getNotificationsCount,
        getUsers,
        getRoles,
        likeProduct,
        markNotificationSeen,
        markAllNotificationsSeen,
        search,
        searchApi,
        searchDispensaries,
        searchGeo,
        getFilterCounts,
        saveFavoriteBrands,
        saveFavoriteCategories,
        saveFavoriteStrainTypes,
        saveFavoriteDispensaries,
        searchGeoProducts,
        searchGeoBusinesses,
        searchProducts,
        searchUsers,
        selectFavoriteBusinesses,
        selectFavoriteBrands,
        submitDeal,
        subscribeToPushNotifications,
        unsubscribeFromPushNotifications,
        updatePushNotificationSettings,
        getPushNotificationSettings,
        trackProductView,
        updateDeal,
        updateRecentLocation,
        voteProduct
    };
};

export default useApi;
