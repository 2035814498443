import React, { createContext, useState, useContext } from 'react';

const BrandContext = createContext();

export const BrandProvider = ({ children }) => {
    const [brands, setBrands] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const loadBrands = async (api, geoposition) => {
        if (!geoposition) {
            console.error('No geoposition provided for brand loading');
            return;
        }

        try {
            setIsLoading(true);
            setError(null);
            const query = {
                geo: geoposition,
                sortBy: 'near_by',
                page: 1,
                distance: 10,
            };

            const results = await api.selectFavoriteBrands(query);
            if (results?.data?.length > 0) {
                // Transform the data to include selected status
                const transformedBrands = results.data.map(brand => ({
                    ...brand,
                    selected: Boolean(brand.selected) // Ensure it's a boolean
                }));
                setBrands(transformedBrands);
            }
        } catch (err) {
            console.error('Error loading brands:', err);
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const updateBrandSelection = (brandId, isSelected) => {
        setBrands(prevBrands =>
                      prevBrands.map(brand =>
                                         brand.id === brandId
                                         ? { ...brand, selected: isSelected }
                                         : brand
                      )
        );
    };

    const getSelectedBrandIds = () => {
        return brands
            .filter(brand => brand.selected)
            .map(brand => brand.id);
    };

    const value = {
        brands,
        isLoading,
        error,
        loadBrands,
        updateBrandSelection,
        getSelectedBrandIds
    };

    return (
        <BrandContext.Provider value={value}>
            {children}
        </BrandContext.Provider>
    );
};

export const useBrands = () => {
    const context = useContext(BrandContext);
    if (!context) {
        throw new Error('useBrands must be used within a BrandProvider');
    }
    return context;
};
