import React, { createContext, useContext, useState, useEffect } from 'react';
import useApi from '../utils/useApi';

const NavCountContext = createContext();

export const NavCountProvider = ({ children, user }) => {
    const { getNotificationsCount } = useApi();
    const [notificationsCount, setNotificationsCount] = useState(0);

    // Reset counts when user logs out
    useEffect(() => {
        if (!user) {
            setNotificationsCount(0);
        }
    }, [user]);

    const refreshCounts = async () => {
        if (!user) return null;

        try {
            const response = await getNotificationsCount();
            setNotificationsCount(response?.count);
            return response;
        } catch (error) {
            console.error('Error refreshing counts:', error);
            return null;
        }
    };

    return (
        <NavCountContext.Provider value={{
            notificationsCount,
            refreshCounts,
            isAuthenticated: !!user
        }}>
            {children}
        </NavCountContext.Provider>
    );
};

export const useNavCount = () => {
    const context = useContext(NavCountContext);
    if (!context) {
        throw new Error('useNavCount must be used within a NavCountProvider');
    }
    return context;
};
