import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import {IoArrowUpCircleOutline} from "react-icons/io5";
import {
    FaFacebook,
    FaTwitter,
    FaLinkedin,
    FaWhatsapp,
    FaCopy,
    FaCommentDots
} from "react-icons/fa";
import styles from "./ShareBottomSheet.module.css";
import {FaFacebookMessenger, FaX, FaXTwitter, FaMessage} from "react-icons/fa6";
import {X} from "lucide-react";

const ShareBottomSheet = ({ product, open, onOpenChange }) => {
    const shareUrl = `${window.location.origin}?product_id=${product.id}`;
    const firstPricing = product.pricing[0];
    const facebookAppId = 1781750725973189;

    // Helper function to format product price
    const formatPrice = (pricing) => {
        if (!pricing || !pricing.length) return '';

        const price = pricing[0];
        if (price.discount_price > 0) {
            const discountPercentage = Math.round((1 - price.discount_price / price.price) * 100);
            return {
                original: price.price.toFixed(2),
                discounted: price.discount_price.toFixed(2),
                unit: price.unit,
                hasDiscount: true,
                saleText: `${product.name} by ${product.brand_name ? product.brand_name : product.dispensary.name} is on sale today for ${discountPercentage}% off at ${product.dispensary.name}! `,

            };
        }
        return {
            original: price.price.toFixed(2),
            unit: price.unit,
            hasDiscount: false,
            saleText: `${product.name} by ${product.brand_name ? product.brand_name : product.dispensary.name} is on sale today at ${product.dispensary.name}! `,
        };
    };
    const price = formatPrice(product.pricing);

    let priceText = ''
    if(price.hasDiscount){
        priceText = `Was $${price.original}, now $${price.discounted} per ${price.unit}`
    } else {
        priceText = `$${price.original} per ${price.unit}`
    }

    const saleText = price.saleText + priceText;

    const shareData = {
        title: `${product.name} by ${product.brand_name ? product.brand_name : product.dispensary.name}`,
        text: saleText,
        url: shareUrl,
    };

    const handleShare = async (e, platform) => {
        e.preventDefault();
        e.stopPropagation();

        // Create the preview URL
        const previewUrl = `${window.location.origin}/product/${product.id}`;
        const encodedUrl = encodeURIComponent(previewUrl);
        const encodedText = encodeURIComponent(shareData.text);

        switch (platform) {
            case 'facebook':
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(previewUrl)}`, '_blank', 'noopener,noreferrer');
                break;
            case 'facebookMessenger':
                // Try mobile messenger first
                if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                    console.warn('isMobile');
                    window.location.href = `fb-messenger://share/?link=${encodedUrl}`;
                } else {
                    // Fallback to web messenger
                    window.open(`https://www.facebook.com/dialog/send?link=${encodedUrl}&app_id=${facebookAppId}&redirect_uri=${encodedUrl}`, '_blank', 'width=600,height=400,noopener,noreferrer');
                }
                break;
            case 'twitter':
                window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareData.text)}&url=${encodeURIComponent(previewUrl)}`, '_blank', 'noopener,noreferrer');
                break;
            case 'linkedin':
                window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(previewUrl)}`, '_blank', 'noopener,noreferrer');
                break;
            case 'whatsapp':
                window.open(`https://wa.me/?text=${encodeURIComponent(shareData.text + ' ' + previewUrl)}`, '_blank', 'noopener,noreferrer');
                break;
            case 'sms':
                const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
                const smsText = encodeURIComponent(`${shareData.text} ${previewUrl}`);

                if (isMobile) {
                    // Check if iOS device
                    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                        window.location.href = `sms:&body=${smsText}`;
                    } else {
                        // Android and others
                        window.location.href = `sms:?body=${smsText}`;
                    }
                } else {
                    // Fallback for desktop - copy to clipboard
                    try {
                        await navigator.clipboard.writeText(`${shareData.text} ${previewUrl}`);
                        alert('Message copied to clipboard! You can now paste it in your SMS app.');
                    } catch (err) {
                        alert('Failed to copy message');
                    }
                }
                break;
            case 'copy':
                try {
                    await navigator.clipboard.writeText(previewUrl);
                    alert('Link copied to clipboard!');
                }
                catch (err) {
                    alert('Failed to copy link');
                }
                break;
        }
    };

    const handleContentClick = (e) => {
        e.stopPropagation();
    };

    return (
        <Dialog.Root open={open} onOpenChange={onOpenChange}>
            <Dialog.Portal>
                <Dialog.Overlay className={styles.overlay} onClick={(e) => e.stopPropagation()}/>
                <Dialog.Content className={styles.content} onClick={handleContentClick}>
                    <Dialog.Title className={styles.title}>
                        Share this deal
                    </Dialog.Title>

                    <div className={styles.buttonsContainer}>
                        <div className={styles.buttonWrap}>
                            <button
                                onClick={(e) => handleShare(e, 'copy')}
                                className={styles.shareNetworkButton}
                            >
                                <FaCopy className={styles.icon}/>
                            </button>
                            <span>Copy link</span>
                        </div>
                        <div className={styles.buttonWrap}>
                            <button
                                onClick={(e) => handleShare(e, 'sms')}
                                className={styles.shareNetworkButton}
                            >
                                <FaMessage className={styles.icon}/>
                            </button>
                            <span>SMS</span>
                        </div>
                        <div className={styles.buttonWrap}>
                            <button
                                onClick={(e) => handleShare(e, 'facebook')}
                                className={styles.shareNetworkButton}
                            >
                                <FaFacebook className={styles.icon}/>
                            </button>
                            <span>Facebook</span>
                        </div>

                        <div className={styles.buttonWrap}>
                            <button
                                onClick={(e) => handleShare(e, 'facebookMessenger')}
                                className={styles.shareNetworkButton}
                            >
                                <FaFacebookMessenger className={styles.icon}/>
                            </button>
                            <span>Messenger</span>
                        </div>

                        <div className={styles.buttonWrap}>
                            <button
                                onClick={(e) => handleShare(e, 'twitter')}
                                className={styles.shareNetworkButton}
                            >
                                <FaXTwitter className={styles.icon}/>
                            </button>
                            <span>X</span>
                        </div>
                        <div className={styles.buttonWrap}>
                            <button
                                onClick={(e) => handleShare(e, 'whatsapp')}
                                className={styles.shareNetworkButton}
                            >
                                <FaWhatsapp className={styles.icon}/>
                            </button>
                            <span>WhatsApp</span>
                        </div>
                        <div className={styles.buttonWrap}>
                            <button
                                onClick={(e) => handleShare(e, 'linkedin')}
                                className={styles.shareNetworkButton}
                            >
                                <FaLinkedin className={styles.icon}/>
                            </button>
                            <span>LinkedIn</span>
                        </div>


                    </div>

                    <div className={styles.productInfo}>
                        <p className={styles.productName}>{product.name} by {product.brand_name ? product.brand_name : product.dispensary.name}</p>
                        <p className={styles.productPrice}>
                            {saleText}
                        </p>
                    </div>

                    <Dialog.Close className={styles.closeButton} onClick={(e) => e.stopPropagation()}>
                            <X size={24}/>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export default ShareBottomSheet;
