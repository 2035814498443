import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./App.module.css";
import { ThemeProvider, useTheme  } from "../ThemeContext/ThemeContext";
import useNotifications from "../../hooks/useNotifications";
import AppRouter from "../AppRouter/AppRouter";
import LocationLoadingScreen from "../LocationLoadingScreen/LocationLoadingScreen";
import {GeolocationProvider, useGeolocation} from "../../contexts/GeolocationProvider";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}



const AppContent = () => {
    const [pushSubscribed, setPushSubscribed] = useState(false);
    const [user, setUser] = useState(null);
    const { permission, subscribeUser } = useNotifications();
    const { geoposition, dateObject, isLoading, error, locationLoading } = useGeolocation();

    useEffect(() => {
        // Run detection when DOM is loaded
        detectBrowserAndOS();
    }, []);

    useEffect(() => {
        if (user && permission === 'default' && !pushSubscribed) {
            const askForNotifications = async () => {
                const result = await subscribeUser();
                if (result) {
                    setPushSubscribed(true);
                    console.log('Successfully subscribed to push notifications');
                }
            };
            askForNotifications();
        }
    }, [user, permission, subscribeUser]);

    const detectBrowserAndOS = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        let browserName = '';
        let osName = '';

        // Detect Browser
        if (userAgent.includes('edge')) browserName = 'edge';
        else if (userAgent.includes('firefox')) browserName = 'firefox';
        else if (userAgent.includes('opr') || userAgent.includes('opera')) browserName = 'opera';
        else if (userAgent.includes('chrome')) browserName = 'chrome';
        else if (userAgent.includes('safari')) browserName = 'safari';
        else browserName = 'unknown-browser';

        // Detect OS
        if (userAgent.includes('win')) osName = 'windows';
        else if (userAgent.includes('mac')) osName = 'macos';
        else if (userAgent.includes('linux')) osName = 'linux';
        else if (userAgent.includes('android')) osName = 'android';
        else if (userAgent.includes('ios')) osName = 'ios';
        else osName = 'unknown-os';

        document.body.classList.add(browserName, osName);
    };

    useEffect(() => {
        // Hide splash screen
        const splashScreen = document.getElementById('splash-screen');
        if (splashScreen) {
            splashScreen.classList.add('hidden');
            setTimeout(() => {
                splashScreen.style.display = 'none';
            }, 1000);
        }
    }, []);

    if (isLoading) {
        return <LocationLoadingScreen />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div id="MainApp" className={styles.App}>
            {geoposition && !isLoading ? (
                <AppRouter
                    user={user}
                    geoposition={geoposition}
                    dateObject={dateObject}
                    locationLoading={locationLoading}
                />
            ) : (
                 <LocationLoadingScreen />
             )}
        </div>
    );
};

const App = () => {
    return (

            <ThemeProvider>
                <GeolocationProvider>
                    <AppContent />
                </GeolocationProvider>
            </ThemeProvider>

    );
};

export default App;
