import React, {useState, Fragment, useRef, useEffect, useCallback} from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import {isMobile} from 'react-device-detect';
import {TrendingUp, Search, Filter, X} from 'lucide-react';
import styles from './NavbarFilter.module.css';
import MenuIcon from "../Icons/MenuIcon/MenuIcon";
// Import SVG icons (unchanged)
import filters from "../Filters/Filters";

// Custom debounce hook
const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};


const priceRanges = [
    { from: 0, to: 20, label: 'Under $20' },
    { from: 20, to: 40, label: '$20 - $40' },
    { from: 40, to: 60, label: '$40 - $60' },
    { from: 60, to: 80, label: '$60 - $80' },
    { from: 80, to: Infinity, label: '$80 & above' },
];

const weights = [
    { value: 0.5, label: '1/2g', unit: '1/2g' },
    { value: 1, label: '1g', unit: '1g' },
    { value: 2, label: '2g', unit: '2g' },
    { value: 3.5, label: '3.5g', unit: '1/8oz' },
    { value: 7, label: '7g', unit: '1/4oz' },
    { value: 14, label: '14g', unit: '1/2oz' },
    { value: 28, label: '28g', unit: '1oz' },
];

const strainTypes = [
    { id: 1, label: 'sativa', customClass: 'lineage-sativa' },
    { id: 2, label: 'indica', customClass: 'lineage-indica' },
    { id: 3, label: 'hybrid', customClass: 'lineage-hybrid' }
];

const NavbarFilter = ({
                          businessId,
                          onTermChange,
                          onCategoryChange,
                          onPriceChange,
                          onWeightChange,
                          onCBDChange,
                          onTHCChange,
                          onStrainTypeChange,
                          onDistanceChange,
                          filterCounts,
                          pinned,
                          onFullFilterVisible,
                          menuType
                      }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const isFilterDisabled = (count) => count == 0;
    const [term, setTerm] = useState('');
    const debouncedTerm = useDebounce(term, 600);
    const [selectedFilter, setSelectedFilter] = useState(filters[1]);
    const [cbdMinMax, setCbdMinMax] = useState({ min: 0, max: 75 });
    const [thcMinMax, setThcMinMax] = useState({ min: 9.96, max: 95 });
    const [localCBDRange, setLocalCBDRange] = useState([0, 75]);
    const [localTHCRange, setLocalTHCRange] = useState([9.96, 95]);
    const [selectedStrainType, setSelectedStrainType] = useState(null);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [isFullFilterVisible, setIsFullFilterVisible] = useState(false);
    const [distance, setDistance] = useState((!businessId ? 3 : null)); // Default to 50 miles
    const [activeFilters, setActiveFilters] = useState({
                                                           price: null,
                                                           weight: null,
                                                           strainType: null,
                                                           distance: distance,
                                                       });

    const searchInputRef = useRef(null);

    useEffect(() => {
        if (filterCounts && filterCounts.cbd_range) {
            setCbdMinMax({
                             min: 0,
                             max: 100
                         });
            setLocalCBDRange([
                                 0,
                                 100
                             ]);
        }
        if (filterCounts && filterCounts.thc_range) {
            setThcMinMax({
                             min: 0,
                             max: 100
                         });
            setLocalTHCRange([
                                 0,
                                 100
                             ]);
        }
    }, [filterCounts]);

    useEffect(() => {
        onTermChange(debouncedTerm);
    }, [debouncedTerm, onTermChange]);

    const handleStrainTypeClick = (strainType) => {
        setSelectedStrainType(strainType);
        setActiveFilters(prev => ({ ...prev, strainType: { id: strainType.id, label: strainType.label } }));
        onStrainTypeChange(strainType.id);
        toggleFullFilter();
    };

    const removeStrainTypeFilter = () => {
        setSelectedStrainType(null);
        setActiveFilters(prev => ({ ...prev, strainType: null }));
        onStrainTypeChange(null);
    };

    const resetFilters = () => {

    }

    const handleFilterChange = (filter, event) => {
        setSelectedFilter(filter);
        setActiveFilters(prev => ({ ...prev, weight: null, strainType: null }));
        setMenuOpen(false); // Close the menu
        onCategoryChange(filter);
        onStrainTypeChange(null);
        onWeightChange(null);
        removeStrainTypeFilter();
        resetStrengthFilters();
    };

    const handleSearchChange = (ev) => {
        console.log('Handling search change');
        setTerm(ev.target.value);

        // Only scroll when actually searching
        if (ev.target.value.length > 0) {
            setTimeout(() => {
                const element = document.getElementById('ScrollPosition');
                if (element) {
                    const rect = element.getBoundingClientRect();
                    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                    const elementPosition = rect.top + scrollTop;

                    window.scrollTo({
                                        top: elementPosition - 9,
                                        behavior: 'smooth'
                                    });
                }
            }, 200);
        }
    };

    const handlePriceClick = (from, to, label) => {
        setActiveFilters(prev => ({ ...prev, price: { from, to, label } }));
        onPriceChange(from, to);
        toggleFullFilter();
    };

    const handleWeightClick = (weight, label, unit) => {
        setActiveFilters(prev => ({
            ...prev,
            weight: {
                value: weight,
                label: label,
                unit: unit
            }
        }));
        onWeightChange(weight, unit);
        toggleFullFilter();
    };

    const removeFilter = (filterType) => {
        setActiveFilters(prev => ({ ...prev, [filterType]: null }));
        if (filterType === 'price') {
            onPriceChange(null, null);
        } else if (filterType === 'weight') {
            onWeightChange(null);
        } else if (filterType === 'distance') {
            setDistance(3);
            onDistanceChange(3);
        }
    };

    const toggleSearch = () => {
        setIsSearchVisible(!isSearchVisible);
        if (!isSearchVisible) {
            setTimeout(() => searchInputRef.current?.focus(), 300);
        }
    };

    const handleSearchSubmit = (ev) => {
        ev.preventDefault();
        onTermChange(term); // Immediately trigger search on form submit
    };

    const handleSearchBlur = () => {
        setIsSearchVisible(false);
    };

    const toggleFullFilter = useCallback(() => {
        const newFilterState = !isFullFilterVisible;
        console.log('newFilterState: ' + newFilterState);
        setIsFullFilterVisible(newFilterState);
        if (newFilterState) {
            onFullFilterVisible(false); // Call with inverse since true = hide header, false = show header
        } else {
            onFullFilterVisible(true);
        }
    }, [isFullFilterVisible, onFullFilterVisible]);

    const handleLocalCBDChange = (newRange) => {
        const sortedRange = [
            Math.max(cbdMinMax.min, Math.min(...newRange)),
            Math.min(cbdMinMax.max, Math.max(...newRange))
        ];
        setLocalCBDRange(sortedRange);
    };

    const handleLocalTHCChange = (newRange) => {
        const sortedRange = [
            Math.max(thcMinMax.min, Math.min(...newRange)),
            Math.min(thcMinMax.max, Math.max(...newRange))
        ];
        setLocalTHCRange(sortedRange);
    };

    const resetStrengthFilters = () => {
        const sortedRange = [
            0,
            100
        ];

        setLocalTHCRange(sortedRange);
        setLocalTHCRange(sortedRange);

        onCBDChange(null, null);
        onTHCChange(null, null);
    }

    const handleDistanceChange = (newDistance) => {
        if(newDistance < 0.5){
            newDistance = 0.5;
        }
        setDistance(newDistance);
        setActiveFilters(prev => ({ ...prev, distance: newDistance }));
    };

    const applyAndCloseFilters = () => {
        if (localCBDRange[0] !== 0 && localCBDRange[1] !== 100) {
            onCBDChange(localCBDRange[0], localCBDRange[1]);

        }
        if (localTHCRange[0] !== 0 && localTHCRange[1] !== 100) {
            onTHCChange(localTHCRange[0], localTHCRange[1]);
        }
        onDistanceChange(distance);
        toggleFullFilter();
    };

    const handleNavPosition = () => {
        if (isMobile) {
            let navBarPosition = document.getElementById('navFilterWrapper').offsetParent.offsetTop;
            if (window.scrollY >= 0 && window.scrollY < navBarPosition) {
                console.log({ navBarPosition });
                if (businessId) {
                    navBarPosition += 45;
                }
                window.scrollTo(0, navBarPosition);
            }
        }

    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const showWeightFilters = selectedFilter.id === 2 || selectedFilter.id === 193 || selectedFilter.id === 402; // Flower or Vapes
    const showStrengthFilters1 = selectedFilter.id === 2 || selectedFilter.id === 193 || selectedFilter.id === 361 || selectedFilter.id === 402;

    const showStrengthFilters = false;

    const handleTouchMove = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div id="navFilterWrapper" className={`${styles.navbarFilterWrapper} ${isFullFilterVisible ? styles.filtersOpened : ''}`}>
            <nav className={`${styles.navbar} ${pinned ? styles.navbarPinned : 'navbar-filter'} ${menuType === 'deals' ? styles.greenNavbar : ''}`}>
                <div className={styles.listboxContainer}>
                    <NavigationMenu.Root value={menuOpen} onValueChange={setMenuOpen}>
                        <NavigationMenu.List className="flex">
                            <NavigationMenu.Item className="relative">
                                <div className={styles.selectedWrap}>
                                    <NavigationMenu.Trigger className={`${styles.listboxButton} ${menuType === 'deals' ? styles.greenListboxButton : ''}`} onClick={handleNavPosition}>
                                        <MenuIcon className={`${styles.menuIcon} ${menuType === 'deals' ? styles.greenMenuIcon : ''}`}/>
                                    </NavigationMenu.Trigger>

                                    <NavigationMenu.Trigger asChild onClick={handleNavPosition}>
                                        <button className={`${styles.selectedFilter} ${menuType === 'deals' ? styles.greenSelectedFilter : ''}`}>
                                            <selectedFilter.Icon className={`${styles.selectedIcon} ${menuType === 'deals' ? styles.greenMenuIcon : ''}`}/>
                                            <span>{selectedFilter.name} {menuType ? <span className={`capitalize`}>{menuType}</span>:''}</span>
                                        </button>
                                    </NavigationMenu.Trigger>
                                </div>

                                <NavigationMenu.Content className={`${styles.listboxOptions} ${menuType === 'deals' ? styles.greenListboxOptions : ''}`}>
                                    {filters.map((filter) => (
                                        <button
                                            key={filter.name}
                                            className={`${styles.listboxOption} ${selectedFilter.name === filter.name ? styles.activeOption : ''}
                                            ${menuType === 'deals' ? styles.greenListboxOption : ''}
                                            ${menuType === 'deals' && selectedFilter.name === filter.name ? styles.greenActiveOption : ''}

                                            `
                                        }
                                            onClick={(event) => handleFilterChange(filter, event)}
                                        >
                                            <filter.Icon className={`${ styles.listboxIcon } menu-filter-icon ${selectedFilter.name === filter.name ? styles.activeIcon : ''}`} aria-hidden="true"/>
                                            <span className={`${styles.optionText} ${
                                                selectedFilter.name === filter.name ? styles.selectedOption : ''
                                            }`}>
                                {filter.name}
                            </span>
                                        </button>
                                    ))}
                                </NavigationMenu.Content>
                            </NavigationMenu.Item>
                        </NavigationMenu.List>

                        <NavigationMenu.Viewport/>
                    </NavigationMenu.Root>
                </div>

                <form onSubmit={handleSearchSubmit} className={`${styles.searchContainer} ${isSearchVisible ? styles.visible : ''}`}>
                    <input
                        ref={searchInputRef}
                        type="text"
                        placeholder="Search by brand, strain, dispensary..."
                        className={styles.searchInput}
                        onBlur={handleSearchBlur}
                        onChange={handleSearchChange}
                        value={term}
                    />
                    <button type="submit" style={{ display: 'none' }}></button>
                </form>

                <button className={`${styles.iconButtonSearch} ${menuType === 'deals' ? styles.greenIcon:''} ${isSearchVisible ? styles.iconButtonSearchVisible : ''}`} onClick={toggleSearch}>
                    <Search className={styles.icon}/>
                </button>

                <button className={`${styles.iconButton} ${menuType === 'deals' ? styles.greenIcon:''}`} onClick={toggleFullFilter}>
                    <Filter className={styles.icon}/>
                </button>
            </nav>

            <div className={styles.activeFiltersContainer}>
                {term.length > 0 && (
                    <div className={`${styles.activeFilter} ${pinned ? styles['activeFilterPinned'] : ''}`}>
                        <span onClick={toggleFullFilter}>{term}</span>
                        <button onClick={() => setTerm('')}><X size={16}/></button>
                    </div>
                )}
                {activeFilters.strainType && (
                    <div className={`${styles.activeFilter} ${styles[activeFilters.strainType.label]} ${pinned ? styles[activeFilters.strainType.label + 'Pinned'] : ''}`}>
                        <span onClick={toggleFullFilter}>{capitalizeFirstLetter(activeFilters.strainType.label)}</span>
                        <button onClick={removeStrainTypeFilter}><X size={16}/></button>
                    </div>
                )}
                {activeFilters.price && (
                    <div className={`${styles.activeFilter} ${pinned ? styles['activeFilterPinned'] : ''}`}>
                        <span onClick={toggleFullFilter}>{activeFilters.price.label}</span>
                        <button onClick={() => removeFilter('price')}><X size={16}/></button>
                    </div>
                )}
                {activeFilters.weight && (
                    <div className={`${styles.activeFilter} ${pinned ? styles['activeFilterPinned'] : ''}`}>
                        <span onClick={toggleFullFilter}>{activeFilters.weight.unit}</span>
                        <button onClick={() => removeFilter('weight')}><X size={16}/></button>
                    </div>
                )}
                {activeFilters.distance && (
                    <div className={`${styles.activeFilter} ${pinned ? styles['activeFilterPinned'] : ''}`}>
                        <span onClick={toggleFullFilter}>Within {activeFilters.distance} miles</span>
                        <button onClick={() => removeFilter('distance')}><X size={16}/></button>
                    </div>
                )}
            </div>

            <div className={`${styles.fullFilterContainer} ${isFullFilterVisible ? styles.fullFilterVisible : ''} ${menuType === 'deals' ? styles.greenFullFilter : ''}`} onTouchMove={handleTouchMove} onScroll={handleTouchMove}>
                <div className={`${styles.fullFilterWrap}`}>
                    <div className={`${styles.filterContent}`}>
                        <div className={styles.filterHeader}>
                            <h2>Filters</h2>
                            <button className={styles.closeButton} onClick={toggleFullFilter}>
                                <X className={styles.closeIcon}/>
                            </button>
                        </div>
                        <div className={`${styles.activeFiltersContainer} ${styles.activeFiltersLeft}`}>
                            {term.length > 0 && (
                                <div className={`${styles.activeFilter} ${pinned ? styles['activeFilterPinned'] : ''} ${menuType !== 'deals' ? styles.activeFilterNeutral : ''}`}>
                                    <span onClick={toggleFullFilter}>{term}</span>
                                    <button onClick={() => setTerm('')}><X size={16}/></button>
                                </div>
                            )}
                            {activeFilters.strainType && (
                                <div className={`${styles.activeFilter} ${styles[activeFilters.strainType.label]} ${pinned ? styles[activeFilters.strainType.label + 'Pinned'] : ''} ${menuType !== 'deals' ? styles.activeFilterNeutral : ''}`}>
                                    <span onClick={toggleFullFilter}>{capitalizeFirstLetter(activeFilters.strainType.label)}</span>
                                    <button onClick={removeStrainTypeFilter}><X size={16}/></button>
                                </div>
                            )}
                            {activeFilters.price && (
                                <div className={`${styles.activeFilter} ${pinned ? styles['activeFilterPinned'] : ''} ${menuType !== 'deals' ? styles.activeFilterNeutral : ''}`}>
                                    <span onClick={toggleFullFilter}>{activeFilters.price.label}</span>
                                    <button onClick={() => removeFilter('price')}><X size={16}/></button>
                                </div>
                            )}
                            {activeFilters.weight && (
                                <div className={`${styles.activeFilter} ${pinned ? styles['activeFilterPinned'] : ''} ${menuType !== 'deals' ? styles.activeFilterNeutral : ''}`}>
                                    <span onClick={toggleFullFilter}>{activeFilters.weight.unit}</span>
                                    <button onClick={() => removeFilter('weight')}><X size={16}/></button>
                                </div>
                            )}
                            {activeFilters.distance && (
                                <div className={`${styles.activeFilter} ${pinned ? styles['activeFilterPinned'] : ''} ${menuType !== 'deals' ? styles.activeFilterNeutral : ''}`}>
                                    <span onClick={toggleFullFilter}>Within {activeFilters.distance} miles</span>
                                    <button onClick={() => removeFilter('distance')}><X size={16}/></button>
                                </div>
                            )}
                        </div>
                        {distance > 0 && (<div className={styles.filterSection}>
                            <h3>Distance</h3>
                            <span className={styles.rangeValues}>
                            Within {distance} miles
                        </span>
                            <div className={styles.rangeSlider}>
                                <div
                                    className={styles.rangeProgress}
                                    style={{
                                        width: `${(distance / 25) * 100}%`
                                    }}
                                ></div>
                                <input
                                    type="range"
                                    min={0.5}
                                    max={25}
                                    step={0.5}
                                    value={distance}
                                    onChange={(e) => handleDistanceChange(parseInt(e.target.value))}
                                    className={styles.rangeInput}
                                />
                            </div>
                        </div>)}

                        <div className={styles.filterSection}>
                            <h3>Price</h3>
                            <div className={styles.filterOptions}>
                                {priceRanges.map((range, index) => {
                                    const count = filterCounts.prices && filterCounts.prices[index] || 0;
                                    return (
                                        <button
                                            key={range.label}
                                            onClick={() => !isFilterDisabled(count) && handlePriceClick(range.from, range.to, range.label)}
                                            data-from={range.from}
                                            data-to={range.to}
                                            className={`${activeFilters.price && activeFilters.price.from === range.from ? styles.activeFilterButton : ''}
                                        ${menuType === 'deals' && activeFilters.price && activeFilters.price.from === range.from ? styles.greenActiveFilterButton : ''}
                                        ${isFilterDisabled(count) ? styles.disabledFilterButton : ''}`}
                                            disabled={isFilterDisabled(count) || (activeFilters.price && activeFilters.price.from === range.from)}
                                        >
                                            {range.label} ({count})
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                        {showWeightFilters && (
                            <div className={styles.filterSection}>
                                <h3>Weight</h3>
                                <div className={styles.filterOptions}>
                                    {weights.map((weight, index) => {
                                        const count = filterCounts.weights && filterCounts.weights[index] || 0;
                                        return (
                                            <button
                                                key={weight.label}
                                                onClick={() => !isFilterDisabled(count) &&
                                                               handleWeightClick(weight.value, weight.label, weight.unit)}
                                                data-weight={weight.value}
                                                data-unit={weight.unit}
                                                className={`${activeFilters.weight &&
                                                              activeFilters.weight.value === weight.value ?
                                                              styles.activeFilterButton : ''}
                                                          ${menuType === 'deals' && activeFilters.weight &&
                                                            activeFilters.weight.value === weight.value ? styles.greenActiveFilterButton : ''}
                ${isFilterDisabled(count) ? styles.disabledFilterButton : ''}`}
                                                disabled={isFilterDisabled(count) ||
                                                          (activeFilters.weight && activeFilters.weight.value === weight.value)}
                                            >
                                                {weight.unit} ({count})
                                            </button>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        <div className={styles.filterSection}>
                            <h3>Strain Type</h3>
                            <div className={styles.filterOptions}>
                                {strainTypes.map((strainType) => {
                                    const count = filterCounts.strainTypes && filterCounts.strainTypes[strainType.id] || 0;
                                    return (
                                        <button
                                            key={strainType.id}
                                            onClick={() => !isFilterDisabled(count) && handleStrainTypeClick(strainType)}
                                            className={`${selectedStrainType && selectedStrainType.id === strainType.id ? styles.activeFilterButton : ''} ${isFilterDisabled(count) ? styles.disabledFilterButton : ''} ${strainType.customClass}
                                        ${menuType === 'deals' && selectedStrainType && selectedStrainType.id === strainType.id ? styles.greenActiveFilterButton : ''}
                                        `}
                                            disabled={isFilterDisabled(count) || (selectedStrainType && selectedStrainType.id === strainType.id)}
                                        >
                                            {capitalizeFirstLetter(strainType.label)} ({count})
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                        {showStrengthFilters && (
                            <>
                                <div className={styles.filterSection}>
                                    <h3>THC</h3>
                                    <span className={styles.rangeValues}>
                            {localTHCRange[0].toFixed(2)}% - {localTHCRange[1].toFixed(2)}%
                        </span>
                                    <div className={styles.rangeSlider}>
                                        <div
                                            className={styles.rangeProgress}
                                            style={{
                                                left: `${((localTHCRange[0] - thcMinMax.min) / (thcMinMax.max - thcMinMax.min)) * 100}%`,
                                                width: `${((localTHCRange[1] - localTHCRange[0]) / (thcMinMax.max - thcMinMax.min)) * 100}%`
                                            }}
                                        ></div>
                                        <input
                                            type="range"
                                            min={thcMinMax.min}
                                            max={thcMinMax.max}
                                            step="0.01"
                                            value={localTHCRange[0]}
                                            onChange={(e) => handleLocalTHCChange([parseFloat(e.target.value), localTHCRange[1]])}
                                            className={styles.rangeInput}
                                        />
                                        <input
                                            type="range"
                                            min={thcMinMax.min}
                                            max={thcMinMax.max}
                                            step="0.01"
                                            value={localTHCRange[1]}
                                            onChange={(e) => handleLocalTHCChange([localTHCRange[0], parseFloat(e.target.value)])}
                                            className={styles.rangeInput}
                                        />
                                    </div>
                                </div>

                                <div className={styles.filterSection}>
                                    <h3>CBD</h3>
                                    <span className={styles.rangeValues}>
                            {localCBDRange[0].toFixed(2)}% - {localCBDRange[1].toFixed(2)}%
                        </span>
                                    <div className={styles.rangeSlider}>
                                        <div
                                            className={styles.rangeProgress}
                                            style={{
                                                left: `${((localCBDRange[0] - cbdMinMax.min) / (cbdMinMax.max - cbdMinMax.min)) * 100}%`,
                                                width: `${((localCBDRange[1] - localCBDRange[0]) / (cbdMinMax.max - cbdMinMax.min)) * 100}%`
                                            }}
                                        ></div>
                                        <input
                                            type="range"
                                            min={cbdMinMax.min}
                                            max={cbdMinMax.max}
                                            step="0.01"
                                            value={localCBDRange[0]}
                                            onChange={(e) => handleLocalCBDChange([parseFloat(e.target.value), localCBDRange[1]])}
                                            className={styles.rangeInput}
                                        />
                                        <input
                                            type="range"
                                            min={cbdMinMax.min}
                                            max={cbdMinMax.max}
                                            step="0.01"
                                            value={localCBDRange[1]}
                                            onChange={(e) => handleLocalCBDChange([localCBDRange[0], parseFloat(e.target.value)])}
                                            className={styles.rangeInput}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>



                </div>
                <div className={`${styles.applyButtonContainer} ${menuType === 'deals' ? styles.greenApplyButtonContainer : ''}`}>
                    <button className={`${styles.applyButton} ${menuType === 'deals' ? styles.greenApplyButton : ''}`} onClick={applyAndCloseFilters}>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NavbarFilter;
