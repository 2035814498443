import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';


import styles from "./BusinessProfile.module.css";
import useApi from "../../utils/useApi";
import ProfileIconSquare from "../ProfileIconSquare/ProfileIconSquare";
import MapComponent from "../MapComponent/MapboxMapComponent";
import StarRating from "../StarRating/StarRating";
import MenuView from "../MenuView/MenuView";

// ICONS
import { PiMapPin } from "react-icons/pi";
import { PiGlobe } from "react-icons/pi";
import { TbClockHour4 } from "react-icons/tb";
import {IoArrowBackCircleOutline, IoBookmark} from "react-icons/io5";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import {IoIosInformationCircleOutline, IoMdHeartEmpty} from "react-icons/io";
import { TfiLocationPin } from "react-icons/tfi";
import BusinessResults from "./BusinessResults";
import BusinessBottomSheet from "../BusinessBottomSheet/BusinessBottomSheet";
import {HiDevicePhoneMobile} from "react-icons/hi2";
import LocationLoadingScreen from "../LocationLoadingScreen/LocationLoadingScreen";
import {FaBookmark} from "react-icons/fa";

const MemoizedMapComponent = React.memo(MapComponent);

const BusinessProfile = ({ handleShowHeader, handleShowSettingsHeader, businessId, showTour, setShowTour }) => {
    const { id } = useParams();
    const [business, setBusiness] = useState(null);
    const [businessMenu, setBusinessMenu] = useState(null);
    const [businessDeals, setBusinessDeals] = useState(null);
    const [currentView, setCurrentView] = useState('deals');
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const [showHours, setShowHours] = useState(false);
    const [loading, setLoading] = useState({
                                               business: true,
                                               deals: true,
                                               menu: true
                                           });
    const { getBusiness, getBusinessMenu, getBusinessDeals } = useApi();

    const navigate = useNavigate();

    const isObjectEmpty = (content) => {
        if (!content) return false;
        if (typeof content !== 'object') return false;
        if (Array.isArray(content)) return content.length <= 0;
        console.log(Object.keys(content).length);
        return Object.keys(content).length <= 0;
    };

    useEffect(() => {
        window.scrollTo(0,0);
        handleShowHeader(false);
        handleShowSettingsHeader(false);
    }, []);

    useEffect(() => {
        if(showHours){
            setIsDetailsOpen(true);
        }
    }, [showHours]);


    useEffect(() => {
        const fetchBusiness = async () => {
            try {
                const result = await getBusiness(id);
                setBusiness(result);
            } catch (error) {
                console.error('Error fetching business:', error);
            } finally {
                setLoading(prev => ({ ...prev, business: false }));
            }
        };

        const fetchBusinessDeals = async () => {
            try {
                const result = await getBusinessDeals(id);
                setBusinessDeals(result);
            } catch (error) {
                console.error('Error fetching business deals:', error);
            } finally {
                setLoading(prev => ({ ...prev, deals: false }));
            }
        };

        const fetchBusinessMenu = async () => {
            try {
                const result = await getBusinessMenu(id);
                setBusinessMenu(result);
            } catch (error) {
                console.error('Error fetching business menu:', error);
            } finally {
                setLoading(prev => ({ ...prev, menu: false }));
            }
        };

        fetchBusiness();
        fetchBusinessDeals();
        fetchBusinessMenu();
    }, [id, getBusiness, getBusinessDeals, getBusinessMenu]);

    const handleShowHours = () => {
        setShowHours(true);
    }

    const toggleView = () => {
        setCurrentView(currentView === 'deals' ? 'menu' : 'deals');
    };

    const setMenuView = () => {
        setCurrentView('menu');
    }

    const setDealView = () => {
        setCurrentView('deals');
    }

    const handleStoreDetailsClick = (e) => {
        e.preventDefault();
        setIsDetailsOpen(true);
    };

    const handleDetailsClose = (e) => {
        setIsDetailsOpen(false);
        setShowHours(false);
    }

    const getFormattedDate = () => {
        const today = new Date();
        const options = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        };
        const formattedDate = today.toLocaleDateString('en-US', options);
        return formattedDate;
    };

    if (loading.business) return <LocationLoadingScreen businessLoading={true} />;

    const headerIcons = [
        { label: "Call", Icon: HiDevicePhoneMobile, link: `tel:${business.phone_number}` },
        //{ label: "Message", Icon: TbMessageDots, link: `sms:${business.phone_number}` },
        { label: "Directions", Icon: PiMapPin, link: `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(business.name + ' ' + business.address)}`, target: "_blank" },
        //{ label: "Website", Icon: PiGlobe, link: business?.website },
        { label: "Hours", Icon: TbClockHour4, link: '#hours', onClick: () => handleShowHours() },
        { label: "Info", Icon: IoIosInformationCircleOutline, link: '#info', onClick: () => setIsDetailsOpen(true) },
    ];

    const icons = headerIcons.map(({ label, Icon, link, target, onClick }, index) => (
        <a target={target ? target : '_self'} href={link} key={index} className={styles.iconLink} onClick={onClick}>
            <ProfileIconSquare label={label}>
                <Icon />
            </ProfileIconSquare>
        </a>
    ));

    return (
        <div className={styles.profileContainer}>
            <div className={styles.profileHeader}>
                <div className={styles.businessInfo}>
                    <MemoizedMapComponent customClass={styles.map} businesses={[business]}>
                        <div className={styles.profileNavHeader}>
                            <div className={styles.leftSideLinks}>
                                <Link onClick={() => navigate(-1)}>
                                    <IoArrowBackCircleOutline/>
                                </Link>
                            </div>
                            <div className={styles.rightSideLinks}>
                                <Link onClick={() => navigate(-1)}>
                                    <FaBookmark/>
                                </Link>
                                <Link to={"/"}>
                                    <IoMdHeartEmpty/>
                                </Link>
                            </div>
                        </div>
                    </MemoizedMapComponent>
                    <div className={styles.logoImage}>
                        <img src={business.avatar_image.small_url} alt={`${business.name} logo`}/>
                    </div>
                    <h1 className={styles.h1}>{business.name}</h1>
                    <div className={styles.hoursLocationInfo}>
                        <div className={styles.cityInfo}>
                            <TfiLocationPin/>
                            {business.city}, {business.state}
                        </div>
                        <div className={business.open_now ? styles.open : styles.closed}>
                            <TbClockHour4/>
                            {business.open_now ? 'Open Now' : 'Closed Now'}
                        </div>
                    </div>
                    <div className={styles.ratingWrap}>
                        <StarRating rating={business.rating} maxRating={5} size={15}/>
                        {business.rating}/5 ({business.reviews_count})
                    </div>
                </div>
                <div className={styles.iconsWrap}>
                    {icons}
                </div>
                <div className={styles.locationDetails}>
                    <a onClick={setDealView} className={styles.viewDeals}>
                        Deals
                    </a>
                    <a onClick={setMenuView} className={styles.viewMenu}>
                        Menu
                    </a>

                </div>
                <BusinessBottomSheet
                    business={business}
                    isOpen={isDetailsOpen}
                    showHours={showHours}
                    onClose={() => handleDetailsClose()}
                />
            </div>
            <div className={styles.AppExtraDivLargeScreens}>

            </div>
            <div className={styles.results}>
                <div className={styles.menuHeader}>
                    <div className={styles.today}>
                        {getFormattedDate()}
                    </div>
                </div>
                {currentView === 'deals' && (
                    <>
                        <BusinessResults businessId={id}
                                         handleShowHeader={handleShowHeader}
                                         handleShowSettingsHeader={handleShowSettingsHeader}
                                         showTour={showTour}
                                         setShowTour={setShowTour}
                                         menuType={currentView}
                        />
                    </>

                )}
                {(currentView === 'menu') && (
                    <>
                        <BusinessResults businessId={id}
                                         handleShowHeader={handleShowHeader}
                                         handleShowSettingsHeader={handleShowSettingsHeader}
                                         showTour={showTour}
                                         setShowTour={setShowTour}
                                         menuType={currentView}
                        />
                    </>

                )}
            </div>


        </div>
    );
};

export default BusinessProfile;
