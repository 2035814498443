import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import styles from './MapComponent.module.css';
import restaurantIcon from './icons/mapicon.svg';
import cafeIcon from './icons/mapicon.svg';
import barIcon from './icons/mapicon.svg';
import FlowerMapMarker from "../../assets/map-markers/flower-marker.svg";
import './map.css';
import constants from "../../utils/constants";
import { useTheme } from '../ThemeContext/ThemeContext';


const icons = {
    restaurant: restaurantIcon,
    cafe: cafeIcon,
    bar: barIcon,
};

// Custom dark mode style for Google Maps
const darkModeStyle = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
    },
    {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
    },
    {
        featureType: "poi",
        stylers: [{ visibility: "off" }]
    },

];

const createStarRating = (rating, maxRating = 5, size = 24, fillColor = '#98fb98', backgroundColor = '#E5E7EB') => {
    const createStar = (fillPercentage) => `
        <svg width="${size}" height="${size}" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="starGradient-${fillPercentage}">
                    <stop offset="${fillPercentage}%" stop-color="${fillColor}" />
                    <stop offset="${fillPercentage}%" stop-color="${backgroundColor}" />
                </linearGradient>
            </defs>
            <path
                d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                fill="url(#starGradient-${fillPercentage})"
            />
        </svg>
    `;

    const stars = Array.from({ length: maxRating }, (_, index) => {
        const fillPercentage = Math.max(0, Math.min(100, (rating - index) * 100));
        return createStar(fillPercentage);
    }).join('');

    return `
        <div style="display: inline-flex;">
            ${stars}
        </div>
    `;
};

const GmapMapComponent = ({ businesses, mapMarker, children, customClass, geoposition }) => {
    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [userLocationMarker, setUserLocationMarker] = useState(null);
    const currentInfoWindow = useRef(null);
    const { isDarkMode } = useTheme();

    // Keep existing loader effect...
    useEffect(() => {
        const loader = new Loader({
                                      apiKey: constants.GOOGLE_MAPS_API_KEY,
                                      version: "weekly",
                                      libraries: ["places"]
                                  });

        loader.load().then(() => {
            const google = window.google;
            const mapInstance = new google.maps.Map(mapRef.current, {
                center: { lat: 0, lng: 0 },
                zoom: 2,
                styles: isDarkMode ? darkModeStyle : [],
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
            });

            setMap(mapInstance);
        });
    }, [isDarkMode]);

    // Enhanced user location marker effect
    useEffect(() => {
        if (map && geoposition) {
            const google = window.google;

            // Remove existing user location marker if it exists
            if (userLocationMarker) {
                if (typeof userLocationMarker.setMap === 'function') {
                    userLocationMarker.setMap(null);
                }
                if (userLocationMarker.accuracyCircle) {
                    userLocationMarker.accuracyCircle.setMap(null);
                }
                if (userLocationMarker.pulsingDot) {
                    userLocationMarker.pulsingDot.setMap(null);
                }
            }

            const userLocation = {
                lat: geoposition.latitude,
                lng: geoposition.longitude
            };

            // Create main marker with enhanced visibility
            const marker = new google.maps.Marker({
                                                      position: userLocation,
                                                      map: map,
                                                      title: 'Your Location',
                                                      icon: {
                                                          path: `M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z`,
                                                          fillColor: '#98fb98',
                                                          fillOpacity: 1,
                                                          strokeColor: '#333333',
                                                          strokeWeight: 2,
                                                          scale: 2,
                                                          anchor: new google.maps.Point(12, 22),
                                                      },
                                                      zIndex: 1000,
                                                  });

            // Create pulsing effect
            const pulsingDot = new google.maps.Circle({
                                                          map: map,
                                                          center: userLocation,
                                                          strokeColor: '#FF4444',
                                                          strokeOpacity: 0.8,
                                                          strokeWeight: 2,
                                                          fillColor: '#FF4444',
                                                          fillOpacity: 0.35,
                                                          radius: 50,
                                                      });

            // Animate the pulsing effect
            let dotRadius = 0;
            const animate = () => {
                dotRadius = (dotRadius + 0.5) % 20;
                pulsingDot.setRadius(dotRadius);
                window.requestAnimationFrame(animate);
            };
            animate();

            // Add accuracy circle if accuracy is provided
            let accuracyCircle = null;
            console.log(geoposition);
            if (!geoposition.accuracy) {
                accuracyCircle = new google.maps.Circle({
                                                            map: map,
                                                            center: userLocation,
                                                            radius: 100,
                                                            fillColor: '#FF4444',
                                                            fillOpacity: 0.1,
                                                            strokeColor: '#FF4444',
                                                            strokeOpacity: 0.3,
                                                            strokeWeight: 5,
                                                        });
            }

            // Add info window
            const infoWindow = new google.maps.InfoWindow({
                                                              content: `
                    <div style="color: ${isDarkMode ? '#ffffff' : '#000000'};
                         padding: 8px;
                         border-radius: 4px;
                         border: none !important;
                         text-align: center;">
                        <strong>📍 Your Location</strong>
                    </div>
                `
                                                          });

            marker.addListener('click', () => {
                if (currentInfoWindow.current) {
                    currentInfoWindow.current.close();
                }
                infoWindow.open(map, marker);
                currentInfoWindow.current = infoWindow;
            });

            // Add click listener to close InfoWindow when clicking on the map
            map.addListener('click', () => {
                if (currentInfoWindow.current) {
                    currentInfoWindow.current.close();
                }
            });

            // Store all elements for cleanup
            setUserLocationMarker({
                                      marker,
                                      accuracyCircle,
                                      pulsingDot,
                                      setMap: function(map) {
                                          this.marker.setMap(map);
                                          this.accuracyCircle?.setMap(map);
                                          this.pulsingDot?.setMap(map);
                                      }
                                  });
        }
    }, [map, geoposition, isDarkMode]);

    // Keep existing business grouping function
    const groupBusinessesByLocation = (businesses) => {
        const groupedBusinesses = {};
        businesses.forEach((business) => {
            const key = `${business.lat ?? business.latitude},${business.lon ?? business.longitude}`;
            if (!groupedBusinesses[key]) {
                groupedBusinesses[key] = [];
            }
            groupedBusinesses[key].push(business);
        });
        return Object.values(groupedBusinesses);
    };

    // Keep existing businesses effect
    useEffect(() => {
        if (map && businesses.length > 0) {
            const google = window.google;
            const bounds = new google.maps.LatLngBounds();
            const newMarkers = [];

            const groupedBusinesses = groupBusinessesByLocation(businesses);

            groupedBusinesses.forEach((group) => {
                const position = new google.maps.LatLng(
                    group[0].lat ?? group[0].latitude,
                    group[0].lon ?? group[0].longitude
                );

                const marker = new google.maps.Marker({
                                                          position: position,
                                                          map: map,
                                                          title: group.length === 1 ? group[0].name : `${group.length} businesses`,
                                                          icon: mapMarker
                                                                ? {
                                                                  url: mapMarker,
                                                                  scaledSize: new google.maps.Size(35, 41),
                                                                  anchor: new google.maps.Point(12, 41),
                                                              }
                                                                : {
                                                                  url: FlowerMapMarker,
                                                                  scaledSize: new google.maps.Size(35, 41),
                                                                  anchor: new google.maps.Point(12, 41),
                                                              },
                                                      });

                const infoWindowContent = group.map(business => `
                    <div style="color: ${isDarkMode ? '#ffffff' : '#000000'};  padding: 6px; border-radius: 5px; margin-bottom: 5px; height: auto">
                        <div class="map-box-wrap">
                            <a class="map-box-wrap" href="/business/${business.id}">
                                <div class="map-logo-wrap">
                                    <img src="${business.logoImage}" />
                                </div>
                                <div class="map-info-wrap">
                                    <h3 style="margin: 0 0 5px; color: ${isDarkMode ? '#ffffff' : '#000000'};">${business.name}</h3>
                                    <p style="margin: 0 0 5px; color: ${isDarkMode ? '#8e8e93' : '#666666'};">${business.address1}</p>
                                    <div style="display: flex; align-items: center">
                                        ${createStarRating(business.starRating, 5, 15)} <span style="margin-left: 6px">${business.starRating}/5</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                `).join('');

                const infoWindow = new google.maps.InfoWindow({
                                                                  content: infoWindowContent,
                                                              });

                marker.addListener("click", () => {
                    if (currentInfoWindow.current) {
                        currentInfoWindow.current.close();
                    }
                    infoWindow.open(map, marker);
                    currentInfoWindow.current = infoWindow;
                });

                bounds.extend(position);
                newMarkers.push(marker);
            });

            // Add user location to bounds if available
            if (geoposition) {
                bounds.extend(new google.maps.LatLng(geoposition.latitude, geoposition.longitude));
            }

            setMarkers(newMarkers);

            // Update zoom logic to handle different scenarios
            if (groupedBusinesses.length === 0 && geoposition) {
                // Only user location exists
                map.setCenter(new google.maps.LatLng(geoposition.latitude, geoposition.longitude));
                map.setZoom(15); // Closer zoom for user location only
            } else if (groupedBusinesses.length === 1) {
                // Single business plus potential user location
                map.fitBounds(bounds);
                google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
                    // Ensure minimum zoom level isn't too far out
                    if (map.getZoom() > 16) map.setZoom(16);
                    if (map.getZoom() < 8) map.setZoom(8);
                });
            } else {
                // Multiple locations
                map.fitBounds(bounds);
                google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
                    // Prevent excessive zoom out
                    if (map.getZoom() < 11) map.setZoom(11);
                });
            }

            // Add padding to bounds to ensure markers aren't at the very edge
            const padBounds = () => {
                const ne = bounds.getNorthEast();
                const sw = bounds.getSouthWest();
                const latPad = (ne.lat() - sw.lat()) * 0.1; // 10% padding
                const lngPad = (ne.lng() - sw.lng()) * 0.1;
                bounds.extend(new google.maps.LatLng(ne.lat() + latPad, ne.lng() + lngPad));
                bounds.extend(new google.maps.LatLng(sw.lat() - latPad, sw.lng() - lngPad));
            };
            padBounds();
        }
    }, [map, businesses, isDarkMode, geoposition]);

    // Enhanced cleanup effect
    useEffect(() => {
        return () => {
            markers.forEach(marker => marker.setMap(null));
            if (userLocationMarker) {
                userLocationMarker.setMap(null);
            }
            if (currentInfoWindow.current) {
                currentInfoWindow.current.close();
            }
        };
    }, [markers, userLocationMarker]);

    return (
        <div className={customClass ?? styles.MapContainer}>
            <div
                ref={mapRef}
                className={customClass ?? styles.MapContainer}
                style={{ height: '400px', width: '100%' }}
            >
            </div>
            {children}
        </div>
    );
};

export default GmapMapComponent;
