import React from 'react';
import { MapPin } from 'lucide-react';
import styles from './LocationLoadingScreen.module.css';

const LoadingScreen = ({businessLoading}) => {
    return (
        <div className={styles.container}>
            <div className={styles.iconWrapper}>
                <MapPin size={48} color="white" />
            </div>
            {businessLoading && <div className={styles.text}>Loading dispensary...</div>}
            {!businessLoading && (
                <div className={styles.text}>
                    Locating you...
                </div>
            )}

            <div className={styles.spinner}></div>
        </div>
    );
};

export default LoadingScreen;
